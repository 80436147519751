/* global MediaStream */
import React from 'react';
import log from 'loglevel';
import defs from '@/assets/new-ui/defs.svg';

export default class WebcamView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.listeners = [];
  }

  async componentDidMount() {
    this.videoObj = this.videoRef.current;

    this.stream = await window.sdk.videoconf().mediaDevices().getStream();
    log.debug('this.stream', this.stream);
    this.updateVideo();

    this.listeners.push(
      window.sdk.event().on('mediaDeviceUpdated', (data) => {
        if (data.kind === 'videoinput' || data.kind === 'video') {
          this.removeOldTrack();
          this.stream = data.stream;
          this.updateVideo();
        }
      })
    );
    this.listeners.push(
      window.sdk.event().on('resfreshWebcamView', async () => {
        this.stream = await window.sdk.videoconf().mediaDevices().getStream();
        if (this.cameraOff && this.stream && this.stream.getVideoTracks().length > 0) {
          this.cameraOff = false;
          this.forceUpdate();
        }
        log.debug('this.stream', this.stream);
        this.updateVideo();
      })
    );
    this.listeners.push(
      window.sdk.event().on('toggleWebcam', (data) => {
        this.cameraOff = data;
        this.forceUpdate();
      })
    );
  }

  updateVideo() {
    if (!this.stream || this.stream.getVideoTracks().length === 0) {
      this.cameraOff = true;
      this.forceUpdate();
      return;
    }
    this.videoObj.srcObject = new MediaStream([this.stream.getVideoTracks()[0]]);
  }

  removeOldTrack() {
    if (!this.videoRef.current.srcObject) return;

    const tracks = this.videoRef.current.srcObject.getVideoTracks();
    for (let j in tracks) {
      tracks[j].stop();
    }
  }

  componentWillUnmount() {
    for (const i in this.listeners) {
      this.listeners[i]();
    }
  }

  render() {
    return (
      <>
        <video
          className={`${this.props.className ? this.props.className : ''}`}
          playsInline
          autoPlay={true}
          ref={this.videoRef}
          muted></video>
        {this.cameraOff && (
          <span className="absolute left-0 top-0 flex h-full w-full items-center justify-center bg-accent">
            <svg
              width="126"
              height="96"
              viewBox="0 0 126 96"
              className="h-auto w-19 fill-brand"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false">
              <use href={`${defs}#watermark`} />
            </svg>
          </span>
        )}
      </>
    );
  }
}
