export const fixBody = () => {
  document.body.style.top = `-${window.scrollY}px`;
  document.body.classList.add('fixed', 'w-full');
};

export const unfixBody = () => {
  const scrollY: string = document.body.style.top;
  document.body.style.top = '';
  document.documentElement.setAttribute('style', 'scroll-behavior: unset !important');
  document.body.classList.remove('fixed', 'w-full');
  window.scrollTo(0, parseInt(scrollY) * -1);
  document.documentElement.style.scrollBehavior = '';
};

export const isBodyFixed = (): boolean => {
  return document.body.classList.contains('fixed');
};
