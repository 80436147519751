export default class Sound {
  constructor(sdk) {
    this.sdk = sdk;
    this.audio = null;

    const soundContext = require.context('../../assets/sounds', false, /\.mp3$/);
    this.sounds = soundContext.keys().reduce((sounds, key) => {
      const soundName = key.replace('./', '').replace('.mp3', '');
      sounds[soundName] = soundContext(key);
      return sounds;
    }, {});
  }

  play(file, options = {}) {
    if (this.audio) {
      this.stop();
    }

    this.audio = new Audio(this.sounds[file]);

    this.audio.loop = options.loop ?? false;
    this.audio.volume = options.volume ?? 1;

    this.audio.play();
  }

  stop() {
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
      this.audio = null;
    }
  }
}
