import React from 'react';
import { Translation } from '@/components/Utilities/Translation';

const AlertBrowser = (props: {
  kind: 'device' | any;
  exitPractiVizio: () => void;
  skipNavigatorCheck: () => void;
  skipBrowserNotAllowed: () => void;
}) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="centered-row text-center">
        {props.kind === 'device' ? (
          <>
            <p>
              <Translation keyName="app.phone_tablet">
                Désolé, mais l'application Practicio ne peut pas être utilisée avec un téléphone ou
                une tablette.
              </Translation>
            </p>
            <p>
              <Translation keyName="app.computer">
                Pour lancer notre application, merci d'utiliser un ordinateur.
              </Translation>
            </p>
          </>
        ) : (
          <>
            <p>
              <Translation keyName="app.not_compatible1">Désolé mais il semble que</Translation>{' '}
              <b>
                <Translation keyName="app.not_compatible2">votre navigateur internet</Translation>
              </b>{' '}
              <Translation keyName="app.not_compatible3">
                ne soit pas compatible avec Practicio ou qu'il ne soit pas à jour.
              </Translation>{' '}
              <Translation keyName="app.to_launchPour">
                Pour lancer notre application, merci d'utiliser l'un des navigateurs suivants et de
                le mettre à jour en cliquant sur le lien correspondant :
              </Translation>
            </p>
            <ul className="mx-auto mt-6 w-fit list-disc text-left">
              <li>
                <a
                  href="https://www.google.com/intl/fr/chrome/gsem/download/"
                  target="_blank"
                  rel="noreferrer">
                  Google Chrome
                </a>
              </li>
              <li>
                <a
                  href="https://www.microsoft.com/fr-fr/edge/download"
                  target="_blank"
                  rel="noreferrer">
                  Edge
                </a>
              </li>
            </ul>
          </>
        )}

        <input
          className="hidden"
          type="text"
          value=""
          name="skip-browserModal"
          onChange={props.skipBrowserNotAllowed}
        />

        <div className="mt-6 flex items-center justify-center space-x-6">
          <div className={'debug debug_graph_controls'}>
            <button
              id="skipNavigatorCheckButton"
              className="cta cta--accent"
              onClick={props.skipNavigatorCheck}>
              <Translation keyName="general.ignore">Ignorer</Translation>
            </button>
          </div>

          <button className="cta cta--accent" onClick={props.exitPractiVizio}>
            <Translation keyName="app.quit_practicio">Quitter Practicio</Translation>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AlertBrowser;
