import React from 'react';
import log from 'loglevel';
import WebcamView from '@/components/MediaDevices/WebcamView';
import { Translation } from '@/components/Utilities/Translation';
import ParticipantVideoSlot from './ParticipantVideoSlot';

export default class LocalUser extends ParticipantVideoSlot {
  type = 'human';

  constructor(props) {
    log.debug('LocalUser:constructor', props.human.getID());

    super(props, props.human);
  }

  async componentDidMount() {
    window.sdk.event().on('showForbiddenInteractionWarning', () => {
      this.setState({
        showForbiddenInteractionWarning: true
      });
      setTimeout(() => {
        this.setState({
          showForbiddenInteractionWarning: false
        });
      }, 10000);
    });
    window.sdk.event().on('hideForbiddenInteractionWarning', () => {
      this.setState({
        showForbiddenInteractionWarning: false
      });
    });
  }
  close = () => {
    this.setState({
      showForbiddenInteractionWarning: false
    });
  };
  renderVideo() {
    return (
      <div className="wrapper relative overflow-hidden rounded-2.5xl bg-black p-[28.169%_50%]">
        <div
          id="forbiddenInteractionsPopup"
          className={
            'forbidden-interaction-warning absolute left-0 top-0 size-full overflow-hidden text-center ' +
            (this.state.showForbiddenInteractionWarning ? ' show' : '')
          }>
          <button
            className="absolute right-4 top-4"
            id="forbiddenInteractionsPopupCloseButton"
            onClick={this.close}>
            X
          </button>
          <p className="text-sm">
            <Translation keyName="localUser.intervene">
              Vous ne pouvez pas intervenir durant cette phase.
            </Translation>
          </p>
          <p className="text-sm">
            <Translation keyName="localUser.observation">
              Il s’agit d’une phase d’observation.
            </Translation>
          </p>
          <p className="text-sm">
            <Translation keyName="localUser.discover">
              Prenez le temps de découvrir le scénario et les personnages.
            </Translation>
          </p>
        </div>
        <WebcamView className="absolute left-0 top-0 size-full" />
      </div>
    );
  }
}
