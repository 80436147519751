import React from 'react';
import log from 'loglevel';
import Form from 'react-bootstrap/Form';
import { Translation } from '../Utilities/Translation';
import DbMeter from './DbMeter';

export default class DeviceSelector extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: window.sdk.videoconf().mediaDevices().getCurrentDeviceId(this.props.kind)
    };
  }

  componentDidMount() {
    if (!this.state.selected && this.props.devices && this.props.devices.length > 0) {
      this.setState({ selected: this.props.devices[0].deviceId });
      window.sdk.videoconf().mediaDevices().update(this.props.kind, this.props.devices[0].deviceId);
    } else if (this.state.selected) {
      window.sdk.videoconf().mediaDevices().update(this.props.kind, this.state.selected);
    }
  }

  updateDevice = (e) => {
    let deviceId = e.target.value;
    if (deviceId === this.state.selected) return;
    this.setState({
      selected: deviceId
    });
    if (
      this.props.kind === 'audioinput' ||
      window.sdk.videoconf().isWebcamHide() ||
      deviceId !== 'hide'
    ) {
      window.sdk.videoconf().mediaDevices().update(this.props.kind, deviceId);
    }

    if (
      this.props.kind === 'videoinput' &&
      (deviceId === 'hide' || window.sdk.videoconf().isWebcamHide())
    ) {
      window.sdk.videoconf().toggleWebcamHide();
    }
  };

  render() {
    log.debug('state', this.state.selected);
    return (
      <Form.Group className="flex items-center space-x-4">
        <Form.Label className="mb-0 flex flex-col justify-center">
          {this.props.icon ? <i className={this.props.icon + ' text-3xl'} /> : this.props.label}
        </Form.Label>
        <Form.Select onChange={this.updateDevice} value={this.state.selected}>
          {this.props.devices && this.props.devices.length > 0 && (
            <>
              {this.props.devices.map((device, i) => (
                <option key={'option-' + i} value={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </>
          )}
          {this.props.kind === 'videoinput' && (
            <option key={'option-no'} value={'hide'}>
              <Translation keyName="general.no_cam">Pas de camera</Translation>
            </option>
          )}
        </Form.Select>

        {this.props.kind === 'audioinput' && this.props.dbmeter !== false && <DbMeter />}
      </Form.Group>
    );
  }
}
