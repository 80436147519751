import log from 'loglevel';
export default class ForbiddenInteractionWarning {
  constructor(sdk) {
    this.sdk = sdk;
    this.started = false;
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseClick = this.handleMouseClick.bind(this);
    this.setDimensions = this.setDimensions.bind(this);
    this.resumeDetection = this.resumeDetection.bind(this);
    this.stopDetection = this.stopDetection.bind(this);
    this.analyseVolume = this.analyseVolume.bind(this);

    this.pause = this.pause.bind(this);
    this.cancelCountdown = false;
    this.distanceTraveled = 0;
    this.nbShow = 0;
    this.rect = {};
  }

  init(detectionZone, params = {}) {
    this.detectionZone = detectionZone;
    this.started = true;
    this.distanceMax = params.distanceMax || 150;
    this.timeMax = params.timeMax || 500;
    this.calibrationPhaseDuration = params.calibrationPhaseDuration || 3000;
    this.detectionWindowDuration = params.detectionWindowDuration || 3000;
    //this.sound = params.detectionWindowDuration  || 3000;
    this.soundVariationAllowed =
      typeof params.soundVariationAllowed === 'undefined' ? 0.05 : params.soundVariationAllowed;
    this.timeResumeAfterWarning = params.timeResumeAfterWarning || 30000;
    this.allowedClickZones = params.allowedClickZones ? params.allowedClickZones : [];
    this.startX = null;
    this.startY = null;
    this.startTime = null;
    this.cancelCountdown = true;
    this.calibrationEndTime = performance.now() + this.calibrationPhaseDuration;
    this.calibrationSoundsSum = 0;
    this.calibrationSoundsCount = 0;
    this.calibratedSoundLevelAverage = 0;
    this.calibratedSoundLevelTreshold = 0;
    this.soundValues = [];
    this.setDebugInfo('forbiddenInteractionWarning.microphoneState', 'initialized');

    setTimeout(this.resumeDetection, this.calibrationPhaseDuration);
    this.setDimensions();

    document.addEventListener('mousemove', this.handleMouseMove);
    document.addEventListener('click', this.handleMouseClick);
    document.addEventListener('resize', this.setDimensions);
    this.sdk.event().on('volume', this.analyseVolume);
  }

  setDebugInfo(iID, iValue) {
    window.sdk.event().emit('addDebugInfo', { iID, iValue });
  }

  analyseVolume(volume) {
    let currentTime = performance.now();
    let slidingWindowStartTime = null;
    //alert('analyseVolume');
    // Calibration phase
    if (this.calibratedSoundLevelAverage === 0) {
      if (currentTime < this.calibrationEndTime) {
        this.setDebugInfo('forbiddenInteractionWarning.microphoneState', 'calibrating');
        log.debug(
          'ForbiddenInteractionWarning: Calibration running 1... calibrationEndTime: ' +
            this.calibrationEndTime +
            ', currentTime: ' +
            currentTime
        );
        log.debug(
          'ForbiddenInteractionWarning: Calibration running... Time left: ' +
            (this.calibrationEndTime - currentTime) +
            ', Adding sound level: ' +
            volume
        );
        this.calibrationSoundsSum += volume;
        this.calibrationSoundsCount++;
      } else {
        this.calibratedSoundLevelAverage = this.calibrationSoundsSum / this.calibrationSoundsCount;
        this.calibratedSoundLevelTreshold =
          this.calibratedSoundLevelAverage + this.soundVariationAllowed;
        this.setDebugInfo('forbiddenInteractionWarning.microphoneState', 'calibrated');
        this.setDebugInfo(
          'forbiddenInteractionWarning.microphoneTreshold',
          this.calibratedSoundLevelTreshold
        );
        log.debug(
          'ForbiddenInteractionWarning: End of calibration. The calibrated sound level treshold is set to ' +
            this.calibratedSoundLevelTreshold
        );
      }
    }
    // Detection phase
    else {
      if (this.cancelCountdown) {
        slidingWindowStartTime = null;
        return;
      }
      if (!slidingWindowStartTime) {
        slidingWindowStartTime = currentTime + this.detectionWindowDuration;
        this.soundValues = [];
      }
      this.soundValues.push(volume);
      log.debug('ForbiddenInteractionWarning: Detection running... Adding sound level: ' + volume);
      if (currentTime > slidingWindowStartTime) {
        this.soundValues.shift();
      }

      // Calculate average sound level
      let soundLevel =
        this.soundValues.reduce((sum, sound) => sum + sound, 0) / this.soundValues.length;
      log.debug(
        'ForbiddenInteractionWarning: Detection running... Checking average: ' +
          soundLevel +
          ' > ' +
          this.calibratedSoundLevelTreshold
      );
      this.setDebugInfo('forbiddenInteractionWarning.microphoneSoundLevel', soundLevel);

      // Check if sound level is above the treshold
      if (soundLevel > this.calibratedSoundLevelTreshold) {
        log.debug(
          'ForbiddenInteractionWarning: Important sound variation detected, poping warning'
        );
        this.setDebugInfo('forbiddenInteractionWarning.microphoneState', 'triggered');
        this.showForbiddenInteractionWarning();
        slidingWindowStartTime = null;
      } else {
        this.setDebugInfo('forbiddenInteractionWarning.microphoneState', 'measuring');
      }
    }
  }
  setDimensions() {
    this.rect = this.detectionZone.getBoundingClientRect();
  }

  handleMouseMove(event) {
    log.debug('DEBUG CA BOUGE. PAUSE CHECK ?', this.cancelCountdown);
    if (this.cancelCountdown) return;

    const clientX = event.clientX;
    const clientY = event.clientY;

    if (!this.startX) {
      this.startX = clientX;
      this.startY = clientY;
      this.startTime = Date.now();
      this.distanceTraveled = 0;
      return;
    }

    const elapsedTime = Date.now() - this.startTime;
    log.debug('DEBUG elapsedTime', elapsedTime);

    if (elapsedTime > this.timeMax) {
      log.debug('DEBUG elapsedTime expired -> cancel');
      this.startX = clientX;
      this.startY = clientY;
      this.startTime = Date.now();
      this.distanceTraveled = 0;
      return;
    }

    const deltaX = Math.abs(clientX - this.startX);
    const deltaY = Math.abs(clientY - this.startY);
    const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
    this.distanceTraveled += distance;
    this.startX = clientX;
    this.startY = clientY;
    log.debug('DEBUG distance', this.distanceTraveled);

    if (this.distanceTraveled < this.distanceMax) {
      log.debug('DEBUG distance not enough -> cancel');
      this.startX = clientX;
      this.startY = clientY;
      this.startTime = Date.now();
      return;
    }

    let moveAllowed = false;

    if (this.allowedClickZones && this.allowedClickZones.length > 0) {
      for (let i = 0; i < this.allowedClickZones.length; i++) {
        const zone = document.querySelector(this.allowedClickZones[i]);
        log.debug('DEBUG ON CHECK LA ZONE', this.allowedClickZones[i]);
        if (!zone) continue;
        log.debug('DEBUG ELLE EXISTE MAMENE');
        const rect = zone.getBoundingClientRect();
        const left = rect.left;
        const top = rect.top;
        const width = rect.width;
        const height = rect.height;
        log.debug('W : ' + left + ' <' + clientX + ' < ' + parseInt(left + width));
        log.debug('H : ' + top + ' <' + clientY + ' < ' + parseInt(top + height));
        if (
          clientX >= left &&
          clientX <= parseInt(left + width) &&
          clientY >= top &&
          clientY <= parseInt(top + height)
        ) {
          log.debug('DEBUG  move on zone allowed');
          moveAllowed = true;
          this.distanceTraveled = 0;
          break;
        }
      }
    }

    if (!moveAllowed) {
      this.showForbiddenInteractionWarning();
      this.startX = null;
      this.startY = null;
      this.startTime = null;
    }
  }

  handleMouseClick(event) {
    if (this.cancelCountdown) return;
    const clientX = event.clientX;
    const clientY = event.clientY;
    let isClickAllowed = false;

    const left = this.rect.left;
    const top = this.rect.top;
    const width = this.rect.width;
    const height = this.rect.height;
    if (
      clientX < left ||
      clientX > parseInt(left + width) ||
      clientY < top ||
      clientY > parseInt(top + height)
    ) {
      this.startX = null;
      this.startY = null;
      this.startTime = null;
      isClickAllowed = true;
      window.sdk.event().emit('hideForbiddenInteractionWarning');
      return;
    }
    if (this.allowedClickZones && this.allowedClickZones.length > 0) {
      for (let i = 0; i < this.allowedClickZones.length; i++) {
        const zone = document.querySelector(this.allowedClickZones[i]);
        if (!zone) continue;

        const rect = zone.getBoundingClientRect();
        const left = rect.left;
        const top = rect.top;
        const width = rect.width;
        const height = rect.height;

        if (
          clientX >= left &&
          clientX <= left + width &&
          clientY >= top &&
          clientY <= top + height
        ) {
          this.startX = null;
          this.startY = null;
          this.startTime = null;
          isClickAllowed = true;
          break;
        }
      }
    }
    if (!isClickAllowed) {
      this.showForbiddenInteractionWarning();
      this.startX = null;
      this.startY = null;
      this.startTime = null;
    }
  }

  showForbiddenInteractionWarning() {
    if (this.nbShow < 300) {
      this.nbShow++;
      window.sdk.event().emit('showForbiddenInteractionWarning');
      this.startX = null;
      this.startY = null;
      this.startTime = null;
      this.cancelCountdown = true;
      setTimeout(this.resumeDetection, this.timeResumeAfterWarning);
    } else {
      this.stopDetection();
    }
  }
  stopDetection() {
    this.cancelCountdown = true;
  }
  resumeDetection() {
    this.cancelCountdown = false;
  }
  pause(bool) {
    if (this.started) {
      if (bool === true) {
        setTimeout(this.stopDetection, 200);
        window.sdk.event().emit('hideForbiddenInteractionWarning');
      } else setTimeout(this.resumeDetection, 200);
    }
  }

  destroy() {
    if (this.started) {
      document.removeEventListener('mousemove', this.handleMouseMove);
      document.removeEventListener('click', this.handleMouseClick);
      document.removeEventListener('resize', this.setDimensions);
      this.sdk.event().removeListener('volume', this.analyseVolume);
    }
  }
}
