import Accordion from '@UIKit/Accordion/Accordion';
import Card from '@UIKit/Card/Card';
import React from 'react';
import defs from '@/assets/new-ui/defs.svg';
import TopBar from '@/components/TopBar/TopBar';

const Brieffing = () => {
  return (
    <>
      <TopBar className="sticky left-0 top-0 z-50 w-full" />
      <div className="relative z-1 mt-8 lg:fixed lg:left-0 lg:top-12 lg:mt-0 lg:flex lg:h-[calc(100vh-theme(spacing.12))] lg:w-1/3 lg:flex-col">
        <Card
          theme="BRAND"
          className="mx-4 rounded-b-none sm:mx-6 lg:mx-0 lg:flex lg:flex-1 lg:flex-col lg:rounded-l-none">
          <div className="text-center lg:flex lg:flex-1 lg:flex-col">
            <svg
              width="32"
              height="54"
              viewBox="0 0 32 54"
              className="mx-auto mt-auto fill-current"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false">
              <use href={`${defs}#micro`} />
            </svg>
            <p className="mt-6 label">Lorsque vous êtes prêt.e&hellip;</p>
            <h1 className="mb-auto mt-6 title-sm">
              <span className="title">
                Prononcer “<strong className="text-accent">C’est&nbsp;parti</strong>”
              </span>
              <br />
              pour lancer la discussion avec&nbsp;Sophie
            </h1>
          </div>
        </Card>
        <Card
          theme="LIGHT_BRAND"
          className="mx-4 rounded-t-none text-white sm:mx-6 lg:mx-0 lg:rounded-l-none [&]:p-0">
          <Accordion id="help">
            <Accordion.CTALabel className="py-6 pl-6 pr-[calc(18px+theme(spacing.10))] after:right-6 lg:pl-12 lg:pr-[calc(18px+theme(spacing.16))] lg:after:right-12 [&_+_[role='region']]:px-6 lg:[&_+_[role='region']]:px-12">
              <span className="mr-2 inline-flex size-5 items-center justify-center rounded-full bg-current">
                <svg
                  width="4"
                  height="10"
                  viewBox="0 0 4 10"
                  className="fill-brand"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false">
                  <use href={`${defs}#info`} />
                </svg>
              </span>
              <span className="text-sm font-semibold">Besoin d’aide&nbsp;?</span>
            </Accordion.CTALabel>

            <ol className="-mr-6 list-decimal text-sm">
              <li>
                <span className="flex flex-wrap items-center justify-between">
                  <span>
                    Reconfigurez votre micro&nbsp;dans
                    <br />
                    les&nbsp;paramètres.
                  </span>
                  <button className="cta cta--without-hover-effect ml-auto flex items-center">
                    <svg
                      width="19"
                      height="20"
                      viewBox="0 0 19 20"
                      className="mr-2 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      focusable="false">
                      <use href={`${defs}#settings`} />
                    </svg>
                    Paramètres
                  </button>
                </span>
              </li>
              <li>
                <span className="flex flex-wrap items-center justify-between">
                  Contactez l’assistance{' '}
                  <button className="cta cta--without-hover-effect ml-auto flex items-center">
                    <span className="mr-2 flex size-5 items-center justify-center rounded-full bg-current">
                      <svg
                        width="4"
                        height="10"
                        viewBox="0 0 4 10"
                        className="fill-brand"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false">
                        <use href={`${defs}#info`} />
                      </svg>
                    </span>
                    Aide
                  </button>
                </span>
              </li>
            </ol>
          </Accordion>
        </Card>
      </div>

      <section className="px-4 py-6 bg-blurred-sophie max-lg:pb-8 sm:px-6 lg:relative lg:ml-auto lg:flex lg:min-h-[calc(100vh-theme(spacing.12))] lg:w-2/3 lg:items-center">
        <div className="lg:mx-auto lg:w-full lg:max-w-half-row">
          <Card className="lg:p-12" areCustomStyles={false}>
            <Card.Border>
              <Card.Title label={<>Bienvenu.e, pour commencer&hellip;</>}>
                Rappel du contexte
              </Card.Title>
              <p>
                Vous êtes manager d’une équipe de deux collaborateurs&nbsp;: Sophie et&nbsp;Daniel.
              </p>
              <ul>
                <li>Sophie est un membre fiable de votre&nbsp;équipe.</li>
                <li>Depuis 2 semaines, elle accumule des retards&nbsp;inhabituels.</li>
                <li>
                  Ces retarde vous ont alerté et vous avez planifié un rendez-vous avec
                  son&nbsp;accord.
                </li>
              </ul>
            </Card.Border>
          </Card>

          <Card className="mt-6 lg:p-12" areCustomStyles={false}>
            <Card.Border color="accent">
              <Card.Title color="accent" label={<>Votre mission, si vous l’acceptez&hellip;</>}>
                Votre objectif
              </Card.Title>
              <ul>
                <li>Accueillez Sophie dans la&nbsp;réunion</li>
                <li>Contextualisez et expliquez l’objet de la&nbsp;réunion</li>
                <li>Rassurez Sophie sur ses potentielles&nbsp;inquiétudes</li>
              </ul>
            </Card.Border>
          </Card>

          <Card className="mt-6 lg:p-12" areCustomStyles={false}>
            <Card.Border color="advice">
              <Card.Title color="advice">Soutenir l’autonomie</Card.Title>
              <div className="flex space-x-6">
                <p>
                  Il s'agit d'accompagner votre collaborateur en explicitant des enjeux qui ne
                  pourraient pas être évident au premier abord. Ces enjeux peuvent être spécifiques
                  d'un métier ou porter plus largement sur la vie en entreprise.
                </p>

                <button className="cta cta--accent w-[420px] self-center">En savoir plus</button>
              </div>
            </Card.Border>
          </Card>
        </div>
      </section>
    </>
  );
};

export default Brieffing;
