import React, { useContext } from 'react';
import defs from '@/assets/new-ui/defs.svg';
import { StepContext, StepModel } from './Stepper';
import { DetailedFeedbackModel } from '@/models/DetailedFeedbackModel';

const StepNavButtons = ({
  steps,
  callback,
  setSelectedSteps
}: {
  steps: DetailedFeedbackModel[];
  callback?: () => void;
  setSelectedSteps: React.Dispatch<React.SetStateAction<Set<string>>>;
}) => {
  const { activeStepId, setActiveStepId } = useContext(StepContext);
  const getActiveStepIndex = () => steps.findIndex((step: StepModel) => step.id === activeStepId);
  const evaluatedSteps: StepModel[] = steps.filter((step: StepModel) => step.evaluation);

  const navToStep = (direction: 1 | -1) => (event: React.MouseEvent) => {
    event.preventDefault();
    if (callback) {
      callback();
    }
    const activeStepIndex: number = getActiveStepIndex();

    if (
      (direction === -1 && !activeStepIndex) ||
      (direction === 1 && activeStepIndex === evaluatedSteps.length - 1)
    ) {
      return;
    }

    const newStep = steps[activeStepIndex + direction];
    if (newStep.evaluation) {
      setActiveStepId(newStep.id);
      setSelectedSteps((prev) => {
        const newSet = new Set(prev);
        newSet.add(newStep.id);
        return newSet;
      });
    }
  };

  return (
    <>
      <button
        className="relative h-12 px-6 transition-opacity after:absolute after:right-[-0.5px] after:top-1/2 after:block after:h-8 after:w-px after:-translate-y-1/2 after:bg-brand disabled:opacity-40"
        onClick={navToStep(-1)}
        disabled={activeStepId === steps[0].id}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          className="fill-brand"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false">
          <use href={`${defs}#arrow-left`} />
        </svg>
      </button>
      <button
        className="h-12 px-6 transition-opacity disabled:opacity-40"
        data-testid="stepper-next-button"
        onClick={navToStep(1)}
        disabled={
          activeStepId ===
          (evaluatedSteps.length > 0 ? steps[evaluatedSteps.length - 1]?.id : undefined)
        }>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          className="fill-brand"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false">
          <use href={`${defs}#arrow-right`} />
        </svg>
      </button>
    </>
  );
};

export default StepNavButtons;
