import React from 'react';
import { isMobile } from 'react-device-detect';
import ill from '@/assets/images/get-user-media-ask.svg';
import { Translation } from '@/components/Utilities/Translation';

export default class Ask extends React.PureComponent {
  render() {
    return isMobile ? (
      <></>
    ) : (
      <>
        <h2 className="mx-auto max-w-row text-center title" data-testid="ask-auth-button">
          <Translation keyName="ask.auth">
            En cliquant sur “Demander l'autorisation”, vous allez faire apparaître une fenêtre dans
            le coin supérieur gauche de votre écran.
          </Translation>
        </h2>
        <img src={ill} width={280} height={280} className="mx-auto h-auto w-full max-w-48" />
      </>
    );
  }
}
