import React, { useContext } from 'react';
import Settings from '@/components/MediaDevices/Settings';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '../../ExerciseContext';

const UpdateSettings = () => {
  const { ExerciseGraph, setStep } = useContext(ExerciseContext);
  const next = () => {
    setStep('micValidation');
  };
  return (
    <>
      <Settings />

      <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'authorization'}>
        <button className="cta cta--accent" id="update-settings-button" onClick={next}>
          <Translation keyName="general.next">Suivant</Translation>
        </button>
      </BottomBar>
    </>
  );
};

export default UpdateSettings;
