import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import { EvaluationType } from '@/models/DetailedFeedbackModel';

const DISPLAY_DURATION = 3000;
const FADING_DURATION = 300;

export type OriginSideType = 'TOP' | 'RIGHT' | 'BOTTOM' | 'LEFT';
export type TranslateYType = 'BOTTOM_TO_TOP' | 'BOTTOM_TO_BOTTOM' | 'TOP_TO_BOTTOM' | 'TOP_TO_TOP';
const TO_TOP = '-translate-y-10';
const TO_BOTTOM = 'translate-y-10';

type TooltipProps = {
  className?: string;
  evaluation?: EvaluationType;
  originSide?: OriginSideType;
  translateY?: TranslateYType;
  onClose: () => void;
};

const getTranslateYFadingClassName = (translateY: TranslateYType): { in: string; out: string } => {
  switch (translateY) {
    case 'BOTTOM_TO_TOP': {
      return {
        in: TO_BOTTOM,
        out: TO_TOP
      };
    }
    case 'BOTTOM_TO_BOTTOM': {
      return {
        in: TO_BOTTOM,
        out: TO_BOTTOM
      };
    }
    case 'TOP_TO_BOTTOM': {
      return {
        in: TO_TOP,
        out: TO_BOTTOM
      };
    }
    case 'TOP_TO_TOP': {
      return {
        in: TO_TOP,
        out: TO_TOP
      };
    }
  }
};

const getArrowClassName = (originSide: OriginSideType): string => {
  let className: string =
    'before:absolute before:h-2 before:w-2 before:-translate-x-1/2 before:rotate-45 before:rounded-[1px] before:bg-[var(--evaluation)]';

  switch (originSide) {
    case 'TOP': {
      return (
        className + ' before:bottom-full before:left-1/2 before:mt-[1px] before:translate-y-1/2'
      );
    }
    case 'RIGHT': {
      return (
        className + ' before:left-full before:top-1/2 before:-ml-[1px] before:-translate-y-1/2'
      );
    }
    case 'BOTTOM': {
      return (
        className + ' before:left-1/2 before:top-full before:-mt-[1px] before:-translate-y-1/2'
      );
    }
    case 'LEFT': {
      return className + ' before:left-0 before:top-1/2 before:ml-[1px] before:-translate-y-1/2';
    }
  }
};

const Tooltip = ({
  className = '',
  evaluation,
  originSide,
  translateY,
  onClose,
  children
}: PropsWithChildren<TooltipProps>) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentMaxHeight, setContentMaxHeight] = useState<string>();

  const [isFadingOut, setIsFadingOut]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);
  const [isFadingIn, setIsFadingIn]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(true);

  useEffect(() => {
    const fadeInTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setIsFadingIn(false);
      setContentMaxHeight(contentRef?.current?.offsetHeight + 'px');
    }, FADING_DURATION);

    return () => clearTimeout(fadeInTimer);
  });

  useEffect(() => {
    const displayTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
      setIsFadingOut(true);

      const fadeOutTimer: ReturnType<typeof setTimeout> = setTimeout(() => {
        onClose();
      }, FADING_DURATION);

      return () => clearTimeout(fadeOutTimer);
    }, DISPLAY_DURATION - FADING_DURATION);
    return () => clearTimeout(displayTimer);
  }, [onClose]);

  let arrowClassName: string = '';

  if (originSide) {
    arrowClassName = getArrowClassName(originSide);
  }

  let translateYFadingClassName: { in: string; out: string } = {
    in: '',
    out: ''
  };

  if (translateY) {
    translateYFadingClassName = getTranslateYFadingClassName(translateY);
  }

  return (
    <div
      style={{ maxHeight: contentMaxHeight }}
      className={`${className} max-h-0 transition-all duration-300`}>
      <div ref={contentRef} className="p-1">
        <p
          {...(evaluation && {
            style: {
              '--evaluation': `var(--${evaluation.toLowerCase()}-color)`
            } as React.CSSProperties
          })}
          className={`relative flex w-auto items-center rounded-2lg bg-[var(--evaluation)] px-4 py-2.5 text-sm font-semibold text-black/70 ${arrowClassName} transition-all duration-300 ${
            isFadingOut || isFadingIn ? 'opacity-0' : 'opacity-100'
          } ${translateY && isFadingIn ? translateYFadingClassName.in + ' ease-in' : ''} ${
            translateY && isFadingOut ? translateYFadingClassName.out + ' ease-out' : ''
          }`}>
          {children}
        </p>
      </div>
    </div>
  );
};

export default Tooltip;
