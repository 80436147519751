import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Translation } from '@/components/Utilities/Translation';

export const PasswordValidationIndicator = ({
  className = '',
  valided
}: {
  className?: string;
  valided: any;
}) => (
  <p className={`${className} -m-1 flex flex-wrap text-xs font-semibold text-brand/50`}>
    <span className={`m-1 whitespace-nowrap ${valided.number ? 'text-good' : ''}`}>
      <FontAwesomeIcon className="inline" icon={faCheck} />{' '}
      <Translation keyName="editPassword.digits">chiffres</Translation>
    </span>
    <span className={`m-1 whitespace-nowrap ${valided.special ? 'text-good' : ''}`}>
      <FontAwesomeIcon className="inline" icon={faCheck} />{' '}
      <Translation keyName="editPassword.special_char">caractère spécial</Translation>
    </span>
    <span className={`m-1 whitespace-nowrap ${valided.length ? 'text-good' : ''}`}>
      <FontAwesomeIcon className="inline" icon={faCheck} />{' '}
      <Translation keyName="editPassword.eight_char">8 caractères</Translation>
    </span>
    <span className={`m-1 whitespace-nowrap ${valided.min ? 'text-good' : ''}`}>
      <FontAwesomeIcon className="inline" icon={faCheck} />{' '}
      <Translation keyName="editPassword.lowercase">minuscule</Translation>
    </span>
    <span className={`m-1 whitespace-nowrap ${valided.maj ? 'text-good' : ''}`}>
      <FontAwesomeIcon className="inline" icon={faCheck} />{' '}
      <Translation keyName="editPassword.uppercase">majuscule</Translation>
    </span>
  </p>
);
