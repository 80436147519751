import Fetch from './Fetch';

export default class FetchInternalAPI extends Fetch {
  constructor(sdk) {
    super(sdk);
  }

  async run(uri, params) {
    return super.run(this.sdk.getInternalApiUrl() + '/v2' + uri, params, true);
  }
}
