import React from 'react';
import '@/styles/components/Achievement.scss';
import Lottie from '@/components/Utilities/Animation';

const TypedLottie = Lottie as React.ComponentType<{
  type?: string;
  loop?: boolean;
  autoplay?: boolean;
  name?: string;
}>;

const AchievementIcon = ({ achievement, name }: { achievement: any; name: string }) => {
  if (achievement.Type.startsWith('Ending') || achievement.Type === 'PedagogicalRecommendation') {
    return <TypedLottie type={achievement.ID} loop={false} autoplay={false} name={name} />;
  } else if (achievement.Type === 'Action_Good') {
    return <TypedLottie type="AchievementDone" loop={false} name={name} />;
  }
  return <TypedLottie type="AchievementFail" loop={false} name={name} />;
};

export default AchievementIcon;
