export default class SemanticSearchAPI {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async Initialize() {}

  async Ask(iSpeech, iExerciseID, iBranchingDecisionNodeID, iTargetBot) {
    let result = await this.sdk.fetch().post('/semanticsearchapi', {
      body: {
        input: iSpeech,
        ExerciseID: iExerciseID,
        BDNodeID: iBranchingDecisionNodeID,
        BotName: iTargetBot
      }
    });

    return result;
  }
}
