import React, { useState } from 'react';
import TabBootstrap from 'react-bootstrap/Tab';
import TabsBootstrap from 'react-bootstrap/Tabs';

const Tabs = ({ tabs }) => {
  const [key, setKey] = useState(tabs[0].key);
  const setActiveTab = (k) => {
    setKey(k);
    let el = document.querySelector('.nav-tabs');
    if (el) {
      el.setAttribute('tab-active', k);
    }
  };
  return (
    <TabsBootstrap activeKey={key} onSelect={(k) => setActiveTab(k)}>
      {tabs.map((tab) => (
        <TabBootstrap eventKey={tab.key} title={tab.title} key={tab.key}>
          {tab.content}
        </TabBootstrap>
      ))}
    </TabsBootstrap>
  );
};
export default Tabs;
