import log from 'loglevel';

export default class UserActionsDetectionAPI {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async Initialize() {}

  async Ask(iSpeech, iExerciseID, iBranchingDecisionNodeID, iEndpoint) {
    const body = {
      input: iSpeech,
      ExerciseID: iExerciseID,
      BDNodeID: iBranchingDecisionNodeID,
      Endpoint: iEndpoint
    };
    const maxRetries = 5;
    const retryDelay = 200;

    let result = {};
    let retryCount = 0;

    while (retryCount < maxRetries) {
      try {
        result = await this.sdk.fetch().post(iEndpoint, { body: body }, true);

        if (result.status === 429) {
          log.debug(`Received 429 error. Retrying after ${retryDelay} ms...`);
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          retryCount++;
          continue;
        }

        break;
      } catch (err) {
        retryCount++;
        if (retryCount === maxRetries) {
          throw err;
        }
      }
    }

    return { body, result };
  }
}
