import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class WaitForAll extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Internal values
  #ActivationsNumberToWaitFor = 0;
  #NodesThatActivatedMe = [];

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ".");
  }

  Initialize() {
    super.Initialize();
    this.ResetCounters();
  }

  ResetCounters() {
    this.#ActivationsNumberToWaitFor = 0;
    this.#NodesThatActivatedMe = [];
  }

  OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");

    // Refresh the number of activations to wait for
    this.#ActivationsNumberToWaitFor = this.Input.GetConnectionsCount();

    // If activator is not in the list of nodes that activated me, add it
    if (!this.#NodesThatActivatedMe.includes(iActivator)) {
      // Add the node to NodesThatActivatedMe
      this.#NodesThatActivatedMe.push(iActivator);

      log.debug(
        this.GetIdentity() +
          ' changed status to ' +
          this.#NodesThatActivatedMe.length +
          '/' +
          this.#ActivationsNumberToWaitFor +
          "'."
      );

      if (this.#NodesThatActivatedMe.length >= this.#ActivationsNumberToWaitFor) {
        // If we reached the number of activations to wait for, proceed to the end actions
        this.EndWaitingActions();
      }
    }
  }

  EndWaitingActions() {
    log.debug(this.GetIdentity() + ' reached the end of its waiting actions!');
    this.ActivateOutput();

    // Reset the number of activations to wait for
    this.ResetCounters();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.ActivateAllConnections();
  }
}
