import React, { Dispatch, SetStateAction, useContext } from 'react';
import defs from '@/assets/new-ui/defs.svg';

export const IsUserCamActivatedContext = React.createContext({
  isUserCamActivated: false,
  setIsUserCamActivated: () => {}
} as { isUserCamActivated: boolean; setIsUserCamActivated: Dispatch<SetStateAction<boolean>> });

const ExerciseControls = ({ callback }: { callback?: () => void }) => {
  const { isUserCamActivated, setIsUserCamActivated } = useContext(IsUserCamActivatedContext);

  const toggleCam = () => (event: React.MouseEvent) => {
    event.preventDefault();
    if (callback) {
      callback();
    }
    setIsUserCamActivated(!isUserCamActivated);
  };

  return (
    <>
      <button className="ml-6 p-2" onClick={toggleCam()}>
        <span className="flex size-10 items-center justify-center rounded-full bg-brand">
          {isUserCamActivated ? (
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              className="fill-off-white"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false">
              <use href={`${defs}#webcam-off`} />
            </svg>
          ) : (
            <svg
              width="22"
              height="16"
              viewBox="0 0 22 16"
              className="fill-off-white"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              focusable="false">
              <use href={`${defs}#webcam`} />
            </svg>
          )}
        </span>
      </button>
      <button className="ml-2 p-2" onClick={() => {}}>
        <span className="flex size-10 items-center justify-center rounded-full bg-brand">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            className="fill-off-white"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false">
            <use href={`${defs}#pause`} />
          </svg>
        </span>
      </button>
    </>
  );
};

export default ExerciseControls;
