import React from 'react';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';

const Error = (props) => {
  return (
    <>
      <p className="mx-auto max-w-row text-center text-fail title-sm">{props.message}</p>

      <BottomBar ExerciseGraph={{}} played={false} step={'error'}></BottomBar>
    </>
  );
};

export default Error;
