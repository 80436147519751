import log from 'loglevel';
import * as Sentry from '@sentry/browser';
import exprEval from 'expr-eval';

export default class Utils {
  static async Sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static ShuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }

    return array;
  }

  static CreateObjectID(iDate, iUserID) {
    return (
      iDate.getUTCFullYear().toString().padStart(4, '0') + // YYYY
      (iDate.getUTCMonth() + 1).toString().padStart(2, '0') + // MM
      iDate.getUTCDate().toString().padStart(2, '0') + // DD
      iDate.getUTCHours().toString().padStart(2, '0') + // HH
      iDate.getUTCMinutes().toString().padStart(2, '0') + // mm
      iDate.getUTCSeconds().toString().padStart(2, '0') + // ss
      iDate.getUTCMilliseconds().toString().padStart(3, '0') + // msmsms
      '-' +
      iUserID
    ); // -userID
  }

  static CreateObjectIDWithIncrement(iDate, iUserID, iIncrement) {
    return (
      iDate.getUTCFullYear().toString().padStart(4, '0') + // YYYY
      (iDate.getUTCMonth() + 1).toString().padStart(2, '0') + // MM
      iDate.getUTCDate().toString().padStart(2, '0') + // DD
      iDate.getUTCHours().toString().padStart(2, '0') + // HH
      iDate.getUTCMinutes().toString().padStart(2, '0') + // mm
      iDate.getUTCSeconds().toString().padStart(2, '0') + // ss
      iDate.getUTCMilliseconds().toString().padStart(3, '0') + // msmsms
      '-' +
      iUserID + // userID
      '-' +
      iIncrement
    ); // incremented value to make it unique
  }

  static StringVideoTimeToFloatSeconds(
    iTimeString // MM:SS:mmm
  ) {
    let time = iTimeString.split(':');
    return parseInt(time[0]) * 60 + parseInt(time[1]) + parseInt(time[2]) / 1000;
  }

  static FloatSecondsToStringVideoTime(
    iFloatTime // MM:SS:mmm
  ) {
    let minutes = Math.floor(iFloatTime / 60);
    let seconds = Math.floor(iFloatTime % 60);
    let milliseconds = Math.floor((iFloatTime - Math.floor(iFloatTime)) * 1000);

    return (
      minutes.toString().padStart(2, '0') +
      ':' +
      seconds.toString().padStart(2, '0') +
      ':' +
      milliseconds.toString().padStart(3, '0')
    );
  }

  static EvalMathematicalFunction(iStringFunction) {
    let result = null;
    let error = null;

    try {
      // replace && and || by "and" and "or" to be able to evaluate the expression with "expr-eval" lib
      // https://www.npmjs.com/package/expr-eval
      const expression = iStringFunction.replace(/&&/g, ' and ').replace(/\|\|/g, ' or ');

      const parser = new exprEval.Parser();
      result = parser.evaluate(expression);
    } catch (err) {
      error = err;
      Sentry.captureException(err);
      log.debug(
        "Utils.EvalMathematicalFunction: Error while executing function '" +
          iStringFunction +
          "'. Error = " +
          error
      );
    }

    return { result, error };
  }

  static GetNextBotVideoAfterPort(iPort, iMaxIterations = 5) {
    let portsToCheckNextIteration = [iPort];

    // For a maximum of iMaxIterations and still some ports to check, check if the connected port is a BotVideo node
    for (let i = 0; portsToCheckNextIteration.length > 0 && i < iMaxIterations; i++) {
      // Get the next port to check
      let currentPort = portsToCheckNextIteration.shift();

      // For each connected port, check if its ParentNode is a BotVideo node
      for (let j = 0; j < currentPort.ConnectedPorts.length; j++) {
        let nextNode = currentPort.ConnectedPorts[j].ParentNode;
        if (nextNode.Type === 'BotVideo') {
          // Return the video name
          return nextNode.VideoName;
        } else if (nextNode.Type === 'BotRandomVideo') {
          // Return the first video name of the BotRandomVideo list
          return nextNode.BotVideos[0].Video;
        } else if (nextNode.Type === 'If') {
          // If this is a conditionnal node, solve the condition and get the next port
          portsToCheckNextIteration.push(nextNode.ChooseOutputPort());
        } else {
          // Add the connected node's ports to the list of ports to check next iteration
          portsToCheckNextIteration.push(...nextNode.GetOutputPorts());
        }
      }
    }

    // If we get here, we didn't find a BotVideo node
    return 'notfound';
  }
  static GetSubtitleLanguage() {
    const urlParams = new URLSearchParams(window.location.search);
    const subtitleLang = urlParams.get('subtitle');
    return subtitleLang;
  }
}
