import React, { useContext } from 'react';
import { ExerciseContext } from '../Exercise/ExerciseContext';
import Scenario1Feedback from './Scenario1Feedback/Scenario1Feedback';

const FeedbackContainer = () => {
  const { ExerciseID, ExerciseSessionID } = useContext(ExerciseContext);

  return <Scenario1Feedback idExercise={ExerciseID} idExerciseSession={ExerciseSessionID} />;
};
export default FeedbackContainer;
