import React from 'react';
import log from 'loglevel';
import GetUserMedia from '@/components/MediaDevices/GetUserMedia';
import Settings from '@/components/MediaDevices/Settings';
import WebcamView from '@/components/MediaDevices/WebcamView';
import TopBar from '@/components/TopBar/TopBar';
import { Translation } from '@/components/Utilities/Translation';

export default class TestDevice extends React.Component {
  state = {
    showDeviceWorkflow: true,
    showDeviceSettings: false
  };

  componentDidMount() {}

  onStreamReady = () => {
    log.debug('on stream ready');
    this.setState({
      showDeviceWorkflow: false,
      showDeviceSettings: true
    });
  };

  render() {
    return (
      <>
        <TopBar />
        <div className="centered-row legacy py-8">
          {this.state.showDeviceWorkflow && (
            <GetUserMedia showLoader={() => {}} onStreamReady={this.onStreamReady} />
          )}

          {this.state.showDeviceSettings && (
            <>
              <div className="mx-auto max-w-half-row">
                <div className="relative mb-4 overflow-hidden rounded-2.5xl bg-black p-[28.169%_50%]">
                  <WebcamView className="absolute left-0 top-0 size-full" />
                </div>
              </div>
              <h3 className="my-6 title">
                <Translation keyName="device.settings">Paramètres des périphériques</Translation>
              </h3>
              <Settings />
            </>
          )}
        </div>
      </>
    );
  }
}
