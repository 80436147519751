import { Tolgee } from '@tolgee/react';
import { FormatIcu } from '@tolgee/format-icu';

export const DEFAULT_LANGUAGE: string = 'fr';
export const AVAILABLE_LANGUAGES: string[] = ['en', 'fr'];

export default function getTolgeeInstance() {
  return Tolgee()
    .use(FormatIcu())
    .init({
      defaultLanguage: window.localStorage.getItem('lang') || DEFAULT_LANGUAGE,
      staticData: {
        en: () => import('../i18n/en.json'),
        fr: () => import('../i18n/fr-FR.json')
      }
    });
}
