import React from 'react';
import log from 'loglevel';
import { Translation } from '@/components/Utilities/Translation';
import ExerciseButton from './ExerciseButton';

export default class RaiseHandButton extends ExerciseButton {
  raiseHandText = (<Translation keyName="raiseHandButton.raise">Lever la main</Translation>);
  lowerHandText = (<Translation keyName="raiseHandButton.wait">Patientez</Translation>);
  handRaised = false;
  variant = 'raising';

  constructor(props) {
    super(props);
    this.buttonText = this.raiseHandText;
  }

  // On clicked, change the label to "Lever la main"
  onClick() {
    log.debug('RaiseHandButton component: onClick');
    this.UpdateState(!this.handRaised);
    this.onclickedCallback(this.handRaised);
  }

  UpdateState(iHandRaised) {
    log.debug('RaiseHandButton component: UpdateState to ' + iHandRaised);
    this.waiting = this.handRaised = iHandRaised;
    this.buttonText = this.handRaised ? this.lowerHandText : this.raiseHandText;
    this.forceUpdate();
  }
}
