/**
 * Removes duplicate elements from an array based on a specified criterion.
 *
 * This function filters out duplicates by applying a function or using a property name
 * to determine the uniqueness of each element in the array. It utilizes a Map to track
 * seen elements for efficient duplicate detection.
 *
 * @param {Array} arr - The array to filter for unique elements.
 * @param {Function|string} fn - A function or string that determines the criterion for uniqueness.
 * If a function is provided, it takes an array element as its argument and returns a value to be
 * used for uniqueness comparison. If a string is provided, it is assumed to be the name of the property
 * to use for uniqueness comparison.
 *
 * @returns {Array} A new array containing only the unique elements of the original array based on the
 * specified criterion.
 *
 * @example
 * // Using a function to specify the uniqueness criterion
 * const items = [{ id: 1, name: 'A' }, { id: 2, name: 'B' }, { id: 1, name: 'C' }];
 * const uniqueItemsByFunction = uniqBy(items, item => item.id);
 * // Output: [{ id: 1, name: 'A' }, { id: 2, name: 'B' }]
 *
 * @example
 * // Using a property name to specify the uniqueness criterion
 * const items = [{ id: 1, name: 'A' }, { id: 2, name: 'B' }, { id: 1, name: 'C' }];
 * const uniqueItemsByProperty = uniqBy(items, 'id');
 * // Output: [{ id: 1, name: 'A' }, { id: 2, name: 'B' }]
 */
export function uniqBy(arr, fn) {
  const seen = new Map();
  return arr.filter((item) => {
    const key = typeof fn === 'function' ? fn(item) : item[fn];
    return seen.has(key) ? false : seen.set(key, true);
  });
}
