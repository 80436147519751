import React from 'react';
import defs from '@/assets/new-ui/defs.svg';
import { EvaluationType } from '@/models/DetailedFeedbackModel';

export const EvaluationPicto = ({
  className = '',
  evaluation
}: {
  className?: string;
  evaluation: EvaluationType;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    className={`${className} inline fill-current`}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    focusable="false">
    {evaluation === 'GOOD' && <use href={`${defs}#validation`} />}
    {evaluation === 'FAIL' && <use href={`${defs}#alert`} />}
    {evaluation === 'BAD' && <use href={`${defs}#warning`} />}
  </svg>
);
