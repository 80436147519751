import React from 'react';
import log from 'loglevel';

export default class ActionButton extends React.Component {
  variant = 'white';

  constructor(props) {
    super(props);

    try {
      props.registerButton(this);
    } catch (e) {
      log.error('ActionButton: registerButton not found');
    }
  }

  onClick() {
    log.debug('ActionButton: onClick');
    this.props.onClick();
  }

  render() {
    return (
      <button id="action-button" className="cta cta--white" onClick={() => this.onClick()}>
        {this.props.label}
      </button>
    );
  }
}
