import React from 'react';

export default class DebugPanel extends React.Component {
  render() {
    return (
      <article className="debug debug_graph_controls hidden bg-black/50 p-4 text-white">
        <h3 className="title-sm">User:</h3>

        <p className="text-sm font-semibold">
          {'UserID: ' + window.sdk.user().userID}
          <br />
          {'Name: ' + window.sdk.user().firstName + ' ' + window.sdk.user().lastName}
          <br />
          {'Email: ' + window.sdk.user().email}
          <br />
          {'Entity: ' + window.sdk.user().entity}
          <br />
          {'Role: ' + window.sdk.user().role}
          <br />
          {'AvailableExercises: ' +
            (!window.sdk.user().availableExercises || window.sdk.user().availableExercises === ''
              ? 'all'
              : window.sdk.user().availableExercises)}
        </p>
      </article>
    );
  }
}
