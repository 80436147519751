import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class Act extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters
  NodeName = '';
  ActNumber = 0;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.NodeName = iProperties.NodeName;
    this.ActNumber = iProperties.ActNumber;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", iNodeName = " + this.NodeName + ".");
  }

  OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");
    log.debug('TestCreation[Act] ', this.NodeName);
    this.Graph.CurrentActNode = this;
    this.ActivateOutput();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.ActivateAllConnections();
  }

  PrintParameters() {
    //log.debug("Act: NodeName = " + this.NodeName + ".");
  }
}
