import React from 'react';
import { Translation } from '@/components/Utilities/Translation';

const MenuAccessDevice = (props) => {
  return (
    <ul className={`${props.className ? props.className : ''} list-none p-0`}>
      <li
        className={`block border-r-4 pr-4 text-right transition-colors title ${
          props.active === 'materiel' ? 'border-accent text-accent' : 'border-light-gray'
        }`}>
        <Translation keyName="general.material">Matériel</Translation>
      </li>
      <li
        className={`block border-r-4 pr-4 text-right transition-colors title ${
          props.active === 'authorization' ? 'border-accent text-accent' : 'border-light-gray'
        }`}>
        <Translation keyName="general.authorisation">Autorisation</Translation>
      </li>
      <li
        className={`block border-r-4 pr-4 text-right transition-colors title ${
          props.active === 'configuration' || props.active === 'micValidation'
            ? 'border-accent text-accent'
            : 'border-light-gray'
        }`}>
        <Translation keyName="general.setup">Configuration</Translation>
      </li>
    </ul>
  );
};

export default MenuAccessDevice;
