import React, { useContext } from 'react';
import log from 'loglevel';
import { Translation } from '@/components/Utilities/Translation';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '@/pages/Exercise/ExerciseContext';

const Wait = (props) => {
  const { ExerciseGraph } = useContext(ExerciseContext);

  const showTutorialAuthorization = () => {
    log.debug('showTutorial 1');
    props.showTutorial();
  };
  return (
    <>
      <h2 className="mx-auto max-w-row text-center title">
        <Translation keyName="wait.click_allow">
          Cliquez sur Autoriser dans le coin supérieur gauche de votre écran pour autoriser l'accès
          à votre caméra et votre micro.
        </Translation>
      </h2>
      <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'authorization'}>
        <p className="mx-auto max-w-row text-center text-base">
          <Translation keyName="wait.no_window">Aucune fenêtre n’apparaît ?</Translation>
          <br />
          <button
            className="link text-accent hover:text-accent"
            onClick={showTutorialAuthorization}>
            <Translation keyName="general.click_here">Cliquez ici</Translation>
          </button>{' '}
          <Translation keyName="wait.following_steps">
            pour découvrir la marche à suivre
          </Translation>
        </p>
      </BottomBar>
    </>
  );
};

export default Wait;
