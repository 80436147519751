import React from 'react';
import log from 'loglevel';
import '@/styles/components/Feedback.scss';
import { useNavigate, useParams } from 'react-router-dom';
import TopBar from '@/components/TopBar/TopBar';
import { Translation } from '@/components/Utilities/Translation';
import Scenario1Achievement from './Step/Scenario1Achievement.tsx';
import StepCongrats from './Step/Scenario1Congrats';
import StepEnding from './Step/Scenario1Ending';
import StepFeedback from './Step/Scenario1Feedback';
import Scenario1Retry from './Step/Scenario1Retry';

export function withRouter(Children) {
  return (props) => {
    const match = { params: useParams() };
    const navigate = useNavigate();
    return <Children {...props} match={match} navigate={navigate} />;
  };
}

class Scenario1Feedback extends React.Component {
  listeners = [];

  state = {
    stepName: '',
    loaded: false
  };
  translateAmount = 80;
  translate = 0;
  totalStep;
  jsonGraph = {};
  stepName = '';
  step = 0;

  stepByExercise = {
    0: [
      // Test exercise
      'Feedback',
      'Congrats',
      'Ending',
      'Achievements',
      'Retry'
    ],
    1: [
      // Stimulus - Observation
      'Congrats'
    ],
    2: [
      // Stimulus - Reaction (non guide)
      'Congrats',
      'Ending',
      'Achievements',
      'Retry'
    ],
    3: [
      // Formuler un feedback negatif
      'Congrats',
      'Ending',
      'Achievements',
      'Retry'
    ],
    4: [
      // Stimulus - Reaction (guide)
      'Congrats',
      'Ending',
      //'Achievements',
      'Retry'
    ],
    6: [
      // Donner du Feedback 1v2
      'Congrats',
      'Ending',
      //'Achievements',
      'Retry'
    ]
  };

  async componentDidMount() {
    // Retrieve exercise graph
    const res = await window.sdk
      .exerciseSession()
      .getOneWithExercise(this.props.idExercise, this.props.idExerciseSession);

    this.exerciseSession = res.exerciseSession;
    this.jsonGraph = res.exercise;
    this.uiCustomization = this.jsonGraph.CustomizationValues;
    let availablePedagogicalEnds = this.jsonGraph.AvailablePedagogicalEnds;

    // Retrieve exercise session history
    const exerciseSessionHistory = await window.sdk
      .exerciseSession()
      .getExerciseSessionHistory(this.props.idExerciseSession);

    //set Step
    this.steps = this.stepByExercise[res.exercise.ID];
    if (!this.steps) this.steps = ['Congrats', 'Retry'];

    /////////////////////////////
    // ENDING
    if (this.steps.includes('Ending')) {
      // Extract ending done from history
      const endingDone = exerciseSessionHistory.find((item) => item.EventType === 'PedagogicalEnd');
      this.endingDone = endingDone.Content;

      // List all endings not done
      if (availablePedagogicalEnds) {
        let endingsNotDone = [...Object.values(availablePedagogicalEnds)];
        const isSameEnd = (end) => end.ID === endingDone.Content.ID;
        let endingDoneIndex = endingsNotDone.findIndex(isSameEnd);
        endingsNotDone.splice(endingDoneIndex, 1);
      }

      // Extract pedagogical addition from history and add it to the ending description
      const pedagogicalAddition = exerciseSessionHistory.find(
        (item) => item.EventType === 'PedagogicalAddition'
      );
      this.endingDone.Description =
        this.endingDone.Description +
        (pedagogicalAddition ? '<br>' + pedagogicalAddition.Content.Description : '');
    }
    /////////////////////////////
    // ACHIEVEMENTS

    // Extract achievements done from history
    const achievementsDoneEvents = exerciseSessionHistory.filter(
      (item) => item.EventType === 'Achievement'
    );
    let achievementsDone = achievementsDoneEvents.map((item) => item.Content);

    // Keep 3 random achievements done to show
    this.achievementsDoneToDisplay = achievementsDone.sort(() => 0.5 - Math.random()).slice(0, 3);

    /////////////////////////////
    // RETRY

    // Extract pedagogical recommendation from history
    let pedagogicalRecommendations = exerciseSessionHistory.filter(
      (item) => item.EventType === 'PedagogicalRecommendation'
    );

    // Keep 3 random endings not done to show them as goals
    this.goals = pedagogicalRecommendations
      .sort(() => 0.5 - Math.random())
      .slice(0, 3)
      .map((item) => item.Content);

    /////////////////////////////
    // STEPS
    this.totalStep = this.steps.length;
    this.stepName = this.steps[0];
    this.step = 0;
    this.setState({
      loaded: true,
      stepName: this.stepName
    });

    window.sdk.event().emit('FeedbackPanelEntered');

    this.listeners.push(
      window.sdk.event().on('restartExercise', () => {
        this.props.navigate('/exercise/' + this.props.idExercise, { replace: false });
      })
    );

    this.listeners.push(
      window.sdk.event().on('quitExercise', () => {
        this.stop();
      })
    );
  }

  componentWillUnmount() {
    for (const i in this.listeners) {
      this.listeners[i]();
    }
  }

  submitStep = async () => {
    window.sdk.sound().play('Swoosh');

    this.step++;
    this.stepName = this.steps[this.step];

    await this.setState({
      stepName: this.stepName
    });

    if (this.stepName === 'Ending') {
      setTimeout(() => {
        window.sdk.event().emit('playLottie', 'ending');
      }, 500);
    }

    if (this.stepName === 'Achievements') {
      setTimeout(() => {
        window.sdk.event().emit('playLottie', 'achievement-0');

        setTimeout(() => {
          this.setState({
            classAchievement: 'show2'
          });
          if (this.achievementsDoneToDisplay.length > 1) {
            setTimeout(() => {
              window.sdk.event().emit('playLottie', 'achievement-1');
            }, 500);
          }
          setTimeout(() => {
            this.setState({
              classAchievement: 'show3'
            });
            if (this.achievementsDoneToDisplay.length > 2) {
              setTimeout(() => {
                window.sdk.event().emit('playLottie', 'achievement-2');
              }, 500);
            }
          }, 1000);
        }, 500);
      }, 250);
    }
  };

  retry = () => {
    this.props.navigate('/exercise/' + this.props.idExercise, { replace: false });
  };

  stop = () => {
    if (window.sdk.isInIframe()) {
      window.sdk.event().emit('showExitedModal', {
        ExitMessage: this.jsonGraph.CustomizationValues.CompletedExercise_Text,
        ExitedButton_Visible:
          this.jsonGraph.CustomizationValues.CompletedExerciseButton_Visible === 'true',
        ExitedButton_Text: this.jsonGraph.CustomizationValues.CompletedExerciseButton_Text
      });
    } else {
      this.props.navigate('/', { replace: false });
    }
  };

  buttonAction = (action, defaultAction) => {
    log.debug('buttonAction', action, defaultAction);
    action = action ? action : defaultAction;
    log.debug('buttonAction', action);
    switch (action) {
      case 'quit':
      case 'stop':
        this.stop();
        break;
      case 'continue':
        this.submitStep();
        break;
      case 'retry':
        this.retry();
        break;
      default:
        log.debug('action not exist :( -> ', action);
        break;
    }
  };

  render() {
    if (!this.state.loaded) return null; //show loader
    return (
      <>
        <TopBar className="sticky left-0 top-0 z-50 w-full" />
        <div className="feedback centered-wide-row flex min-h-[calc(100vh-theme(spacing.12))] flex-col py-8">
          <div className="m-auto max-w-half-row">
            {this.state.stepName === 'Feedback' && <StepFeedback exerciseID={this.jsonGraph.ID} />}

            {this.state.stepName === 'Congrats' && <StepCongrats text={this.uiCustomization} />}

            {this.state.stepName === 'Ending' && (
              <StepEnding ending={this.endingDone} text={this.uiCustomization} />
            )}

            {this.state.stepName === 'Achievements' && (
              <Scenario1Achievement
                text={this.uiCustomization}
                actions={this.achievementsDoneToDisplay}
                classAchievement={this.state.classAchievement}
              />
            )}

            {this.state.stepName === 'Retry' && (
              <Scenario1Retry goals={this.goals} text={this.uiCustomization} />
            )}

            <div className="feedback__actions mt-6 flex flex-wrap items-center justify-center space-x-4">
              {this.stepName === 'Feedback' ? (
                <button className="cta cta--accent" onClick={this.submitStep}>
                  <Translation keyName="general.validate">Valider</Translation>
                </button>
              ) : (
                <>
                  {((this.stepName === 'Retry' &&
                    this.uiCustomization[this.state.stepName + 'ButtonStop_Visible'] !== 'false') ||
                    this.uiCustomization[this.state.stepName + 'ButtonStop_Visible'] ===
                      'true') && (
                    <button
                      className="cta cta--white"
                      onClick={() => {
                        this.buttonAction(
                          this.uiCustomization[this.state.stepName + 'ButtonStop_Action'],
                          'stop'
                        );
                      }}>
                      {this.uiCustomization[this.state.stepName + 'ButtonStop_Text'] ? (
                        this.uiCustomization[this.state.stepName + 'ButtonStop_Text']
                      ) : (
                        <Translation keyName="general.stop">Arrêter</Translation>
                      )}
                    </button>
                  )}
                  {this.stepName !== 'Retry' &&
                    this.uiCustomization[this.state.stepName + 'ButtonValidation_Visible'] !==
                      'false' && (
                      <button
                        className="cta cta--accent"
                        onClick={() => {
                          this.buttonAction(
                            this.uiCustomization[this.state.stepName + 'ButtonValidation_Action'],
                            'continue'
                          );
                        }}>
                        {this.uiCustomization[this.state.stepName + 'ButtonValidation_Text'] ? (
                          this.uiCustomization[this.state.stepName + 'ButtonValidation_Text']
                        ) : (
                          <Translation keyName="general.validate">Valider</Translation>
                        )}
                      </button>
                    )}

                  {((this.stepName === 'Retry' &&
                    this.uiCustomization[this.state.stepName + 'ButtonRetry_Visible'] !==
                      'false') ||
                    this.uiCustomization[this.state.stepName + 'ButtonRetry_Visible'] ===
                      'true') && (
                    <button
                      className="cta cta--accent"
                      onClick={() => {
                        this.buttonAction(
                          this.uiCustomization[this.state.stepName + 'ButtonRetry_Action'],
                          'retry'
                        );
                      }}>
                      {this.uiCustomization[this.state.stepName + 'ButtonRetry_Text'] ? (
                        this.uiCustomization[this.state.stepName + 'ButtonRetry_Text']
                      ) : (
                        <Translation keyName="general.restart">Recommencer</Translation>
                      )}
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Scenario1Feedback);
