import log from 'loglevel';
import SmartBranchingDecision from '../ExerciseNodes/SmartBranchingDecision';

export default class CustomModelsBranchingDecision extends SmartBranchingDecision {
  OnSpeechDetectedModeSpecific() {
    // Detect user actions
    if (!this.IgnoreUserActions || this.Exceptions.length > 0) {
      this.DetectUserActions();
    }
  }

  async ExecuteAnalysis() {
    let answer = null;
    try {
      // Send the request to Branching Decision API
      answer = await window.sdk
        .branchingDecisionAPI()
        .Ask(
          this.m_Speech,
          this.Graph.ExerciseID.toString(),
          'BD' + this.ID,
          this.BranchingDecisionName,
          this.Graph.APIsEndpoints.branchingdecisionapi,
          this.m_CurrentBranchRequestController.signal
        );
      if (!answer.result) {
        log.debug(this.GetIdentity() + '.ExecuteAnalysis: Call failed.');
        return { status: 'failed', request: answer.body, answer: answer.result, branch: null };
      }

      // Get branch from answer
      let chosenBranch = this.Branches.find(
        (branch) => branch.Name === answer.result.chosen_branch
      );

      log.debug(this.GetIdentity() + '.ExecuteAnalysis: answer = ', answer);
      return {
        status: 'success',
        request: answer.body,
        answer: answer.result,
        branch: chosenBranch
      };
    } catch (error) {
      if (error.name === 'AbortError') {
        log.debug(this.GetIdentity() + '.ExecuteAnalysis: Request was aborted.');
        throw error;
      } else if (answer) {
        log.debug(
          this.GetIdentity() + '.ExecuteAnalysis: An error occurred, but answer was filled.'
        );
        let partialAnswer = {
          status: 'failed',
          request: answer.body ? answer.body : null,
          answer: answer.result ? answer.result : null,
          branch: null
        };
        return partialAnswer;
      } else {
        log.debug(
          this.GetIdentity() + '.ExecuteAnalysis: An error occurred, and answer was not filled.'
        );
        return { status: 'failed', request: null, answer: null, branch: null };
      }
    }
  }

  // User Actions
  async DetectUserActions() {
    this.m_UserActionsDetectionStartTime = new Date();

    // Send the request to User Actions Detection API
    let answer = await window.sdk
      .userActionsDetectionAPI()
      .Ask(
        this.m_Speech,
        this.Graph.ExerciseID.toString(),
        'BD' + this.ID,
        this.Graph.APIsEndpoints.useractionsdetectionapi
      );
    log.debug(this.GetIdentity() + '.DetectUserActions: userActionsDetectionAPI answer = ', answer);

    // Push analyzer task to DynamoDB
    this.LogUserActionDetectionResultToDynamoDB(answer);

    // Push all detected actions to history and keep track of the toast ones to pop-up
    for (let i = 0; i < answer.result.chosen_labels.length; i++) {
      let userActionLabel = answer.result.chosen_labels[i];

      // Pass if we should ignore this user action
      if (this.IgnoreUserActions && !this.Exceptions.includes(userActionLabel)) {
        log.debug(
          this.GetIdentity() + ".DetectUserActions: Ignoring user action '" + userActionLabel + "'."
        );
        continue;
      }
      log.debug('this.Graph.AvailableUserActions)', this.Graph.AvailableUserActions);

      // Get corresponding user action from graph
      let userAction = this.Graph.AvailableUserActions[userActionLabel];

      if (!userAction) {
        log.debug(
          this.GetIdentity() +
            ".DetectUserActions: User action '" +
            userActionLabel +
            "' not found in graph."
        );
        continue;
      }

      // Add user action to history
      this.Graph.History.AddUserAction(
        this.ID,
        userAction.ID,
        this.Graph.GetCurrentActName(),
        this.Graph.LastBranchingDecisionNode.DatabaseID
      );

      // Prepare a fake UserActionFeedback and push it directly
      this.ToastUserActionsToPop.push(userAction);
    }

    this.MakeUserActionToastsPop();
  }

  async LogAnalysisResultToDynamoDB(iResult) {
    let apiVersion = '';
    let input = '';
    let answer = '';
    let status = 'raw';

    // Handle errors
    if (iResult.answer) {
      input = JSON.stringify(iResult.request);
      answer = JSON.stringify(iResult.answer);

      if (iResult.answer['api-version']) {
        apiVersion = iResult.answer['api-version'];
      } else {
        status = 'failed';
      }
    } else {
      status = 'failed';
    }

    // Log branching decision analysis to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.DatabaseID, // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      'BranchingDecisionAPI', // analyzer Engine
      apiVersion, // Analyzer Version
      status, // Analysis Status
      input, // Analysis Input
      this.m_BranchDetectionStartTime, // Start Time
      this.m_BranchDetectionDuration.toString(), // Analysis duration (milliseconds)
      '' /*JSON.stringify(this.ItemsToFind.map(itemToFind => itemToFind.Item))*/, // Possible choices
      answer, // Analysis Result
      this.Graph.ExerciseID.toString() // Exercise ID
    );
  }

  // Log to DynamoDB
  async LogUserActionDetectionResultToDynamoDB(iResult) {
    let apiVersion = '';
    let input = '';
    let result = '';
    let status = 'raw';
    let analysisDuration = (
      new Date().getTime() - this.m_UserActionsDetectionStartTime.getTime()
    ).toString();

    // Handle errors
    if (iResult.result) {
      input = JSON.stringify(iResult.body.input);
      result = JSON.stringify(iResult.result);

      if (iResult.result['api-version']) {
        apiVersion = iResult.result['api-version'];
        log.debug(
          this.GetIdentity() +
            '.LogUserActionDetectionResultToDynamoDB: iResult.result[api-version] != null apiVersion = ',
          apiVersion
        );
      } else {
        status = 'failed';
        log.debug(
          this.GetIdentity() +
            '.LogUserActionDetectionResultToDynamoDB: iResult.result != null && iResult.result[api-version] == null'
        );
      }
    } else {
      status = 'failed';
      log.debug(
        this.GetIdentity() + '.LogUserActionDetectionResultToDynamoDB: iResult.result === null'
      );
    }

    // Log branching decision analysis to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.DatabaseID, // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      'UserActionsDetectionAPI', // analyzer Engine
      apiVersion, // Analyzer Version
      status, // Analysis Status
      input, // Analysis Input
      this.m_UserActionsDetectionStartTime, // Start Time
      analysisDuration.toString(), // Analysis duration (milliseconds)
      '' /*JSON.stringify(this.ItemsToFind.map(itemToFind => itemToFind.Item))*/, // Possible choices
      result, // Analysis Result
      this.Graph.ExerciseID.toString() // Exercise ID
    );
  }
}
