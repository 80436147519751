import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AvatarName } from '@UIKit/AvatarName/AvatarName';
import React from 'react';
import log from 'loglevel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Translation } from '@/components/Utilities/Translation';
import Utils from '../../AppClasses/Utils/Utils';
import DbMeter from './DbMeter';
import DeviceSelector from './DeviceSelector';
import WebcamView from './WebcamView';

export default class Settings extends React.PureComponent {
  state = {
    show: false,
    videoinput: [],
    audioinput: []
  };

  async componentDidMount() {
    let devices = await window.sdk.videoconf().mediaDevices().enumerate();
    while (Object.keys(devices).length === 0) {
      log.debug('devices', devices);
      await Utils.Sleep(300);
      devices = await window.sdk.videoconf().mediaDevices().enumerate(true);
    }
    this.setState({
      show: true,
      audioinput: devices['audioinput'],
      videoinput: devices['videoinput']
    });
  }

  tooltip1 = (
    <Tooltip>
      <div className="tooltip--settings">
        <Translation keyName="settings.tooltip">
          <b>La ligne ne s'anime pas en orange ?</b>
          <br />
          <br />
          Votre micro ne capte probablement aucun son. Vérifiez qu’il est :
          <br />
          <br />
          1. Bien branché
          <br />
          2. Non coupé
          <br />
          3. Bien sélectionné dans la liste
        </Translation>
      </div>
    </Tooltip>
  );
  tooltip2 = (
    <Tooltip>
      <div className="tooltip--settings">
        <Translation keyName="tooltip2">
          Vérifiez que votre casque soit <br />
          <strong>bien branché </strong>et <strong>non coupé. </strong>
          <br />
          Assurez-vous d’être dans un <strong>environnement calme </strong>pour ne pas capter de
          bruits extérieurs.
        </Translation>
      </div>
    </Tooltip>
  );

  render() {
    if (!this.state.show) return null;
    return (
      <div className="mx-auto max-w-row md:flex md:items-center md:space-x-8">
        <div className="md:flex-1">
          <div className="relative mb-6 overflow-hidden rounded-2.5xl bg-black p-[28.169%_50%]">
            <WebcamView className="absolute left-0 top-0 size-full" />
            <AvatarName className="absolute bottom-2 left-2">
              <Translation keyName="general.you">Vous</Translation>
            </AvatarName>
          </div>

          <DeviceSelector
            kind="videoinput"
            label="Video input"
            icon={'icon-cam'}
            devices={this.state.videoinput}
          />
        </div>
        <ol className="list-decimal max-md:mt-8 md:flex-1">
          <li>
            <Translation keyName="settings.choose_mic">Choisissez votre micro :</Translation>

            <DeviceSelector
              kind="audioinput"
              label="Audio input"
              icon={'icon-mic'}
              dbmeter={false}
              devices={this.state.audioinput}
            />
          </li>
          <li className="mt-6">
            <Translation keyName="settings.say_phrase">Prononcez une phrase</Translation>
            <DbMeter className="my-2" />
            <Translation keyName="settings.purple_bar">
              La ligne devrait s'animer en orange
            </Translation>
            <OverlayTrigger placement="top" overlay={this.tooltip1}>
              <FontAwesomeIcon className="ml-2 inline" icon={faCircleInfo} />
            </OverlayTrigger>
          </li>
          <li className="mt-6">
            <Translation keyName="settings.not_working">
              Ca ne fonctionne pas ? Contactez le support en bas à droite
            </Translation>
          </li>
        </ol>
      </div>
    );
  }
}
