import React from 'react';

const MakeARequest = () => {
  return (
    <>
      <h3>Introduction</h3>
      <p>
        Formuler une demande de manière claire et précise est une compétence essentielle pour tout
        manager. Une demande bien formulée permet de s’assurer que les besoins et objectifs sont
        compris par tous, et donne à vos collaborateurs l’opportunité de réagir de manière
        appropriée. Voici comment structurer vos demandes pour maximiser leur&nbsp;efficacité.
      </p>
      <h4>L’Importance de la Clarté</h4>
      <p>
        Une demande claire et bien structurée facilite la compréhension et l’exécution des tâches.
        En tant que manager, il est crucial de communiquer de manière précise pour éviter toute
        confusion. Cela implique de spécifier ce qui doit être fait, pourquoi cela est important, et
        comment la tâche doit être accomplie. De plus, une bonne communication renforce la confiance
        et la collaboration au sein de&nbsp;l’équipe.
      </p>
      <article>
        <p>
          <b>Pourquoi formuler une demande?</b>
        </p>
        <ul>
          <li>Pour s’assurer que les besoins et objectifs soient bien compris par&nbsp;tous</li>
          <li>Pour donner à son collaborateur l’opportunité de réagir à la&nbsp;demande</li>
        </ul>
      </article>
      <h4>Encourager les Réactions et les&nbsp;Questions</h4>
      <p>
        Lorsque vous formulez une demande, il est important de donner à vos collaborateurs
        l’opportunité de poser des questions ou de proposer des suggestions. Cela non seulement
        montre que vous valorisez leur input, mais permet également de clarifier les points qui
        pourraient être ambiguës. Encourager les échanges améliore la compréhension mutuelle et peut
        conduire à des solutions plus&nbsp;innovantes.
      </p>
      <h4>Exemple de phrases</h4>
      <ul>
        <li>
          «&nbsp;J’aimerais dorénavant que tu envoies les ordres du jour 24 heures avant
          la&nbsp;réunion.&nbsp;»
        </li>
        <li>
          «&nbsp;Je souhaiterais que tu envoies un mail récapitulatif des 5 points principaux de
          la&nbsp;réunion.&nbsp;»
        </li>
      </ul>
    </>
  );
};

export default MakeARequest;
