import React from 'react';
import { Translation } from '@/components/Utilities/Translation';

export default class Scenario1Congrats extends React.Component {
  render() {
    return (
      <div className="feedback-step feedback-step-congrats">
        <div className="feedback-step__inner feedback-step-inner-congrats">
          <div className="feedback-step-congrats-star-left"></div>
          <div className="feedback-step-congrats-star-right"></div>

          <h1
            className="title"
            dangerouslySetInnerHTML={{ __html: this.props.text.CongratsTitle_Text }}
          />

          <p
            className="mt-6 text-base"
            dangerouslySetInnerHTML={{ __html: this.props.text.CongratsText_Text }}
          />
        </div>
      </div>
    );
  }
}
Scenario1Congrats.defaultProps = {
  text: {
    CongratsTitle_Text: <Translation keyName="s1.congrats">Félicitations !</Translation>,
    CongratsText_Text: (
      <Translation keyName="s1.congrats_text">
        Vous avez terminé le scénario. <br /> Passez à la page suivante pour découvrir vos badges !
      </Translation>
    )
  }
};
