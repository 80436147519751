import { useEffect, useState, RefObject } from 'react';

export enum SCROLL_DIRECTIONS {
  UP = 'up',
  DOWN = 'down'
}

/**
 * Custom hook to detect scroll direction using wheel, scroll, and keyboard events
 * @param {RefObject<HTMLElement>} [scrollRef] - Optional ref to the scroll container
 * @returns {'up' | 'down' | null} - Returns 'up' or 'down' based on scroll direction
 */
export function useScrollDirection(scrollRef?: RefObject<HTMLElement>): SCROLL_DIRECTIONS | null {
  const [scrollDirection, setScrollDirection] = useState<SCROLL_DIRECTIONS | null>(null);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isSimulatedScroll, setIsSimulatedScroll] = useState(false);

  useEffect(() => {
    const scrollContainer = scrollRef?.current || window;
    const isWindow = scrollContainer === window;

    if (!scrollContainer) return;

    const handleScroll = () => {
      if (isSimulatedScroll) {
        setIsSimulatedScroll(false);
        return;
      }

      const currentScrollTop = isWindow
        ? window.scrollY
        : (scrollContainer as HTMLElement).scrollTop;

      if (currentScrollTop > lastScrollTop) {
        setScrollDirection(SCROLL_DIRECTIONS.DOWN);
      } else if (currentScrollTop < lastScrollTop) {
        setScrollDirection(SCROLL_DIRECTIONS.UP);
      }

      setLastScrollTop(currentScrollTop);
    };

    const handleWheel = (event: Event) => {
      if (event instanceof WheelEvent) {
        if (isSimulatedScroll) {
          setIsSimulatedScroll(false);
          return;
        }

        if (event.deltaY > 0) {
          setScrollDirection(SCROLL_DIRECTIONS.DOWN);
        } else if (event.deltaY < 0) {
          setScrollDirection(SCROLL_DIRECTIONS.UP);
        }
      }
    };

    const handleKeyDown = (event: Event) => {
      if (event instanceof KeyboardEvent) {
        switch (event.key) {
          case 'ArrowDown':
          case 'PageDown':
          case ' ':
            event.preventDefault();
            setScrollDirection(SCROLL_DIRECTIONS.DOWN);
            break;
          case 'ArrowUp':
          case 'PageUp':
            event.preventDefault();
            setScrollDirection(SCROLL_DIRECTIONS.UP);
            break;
        }
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    scrollContainer.addEventListener('wheel', handleWheel);
    window.addEventListener('keydown', handleKeyDown);

    // Expose a method to set isSimulatedScroll
    (window as any).setSimulatedScroll = () => setIsSimulatedScroll(true);

    return () => {
      scrollContainer.removeEventListener('scroll', handleScroll);
      scrollContainer.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
      delete (window as any).setSimulatedScroll;
    };
  }, [scrollRef, lastScrollTop, isSimulatedScroll]);

  return scrollDirection;
}
