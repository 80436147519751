import Accordion from '@UIKit/Accordion/Accordion';
import Card from '@UIKit/Card/Card';
import React, { createRef, useRef } from 'react';
import { Translation } from '@/components/Utilities/Translation';
import '@/styles/components/Achievement.scss';
import log from 'loglevel';
import fallbackSrc from '@/assets/images/feedback/ending/Default.svg';

const Scenario1Retry = ({ text, goals }: { text: any; goals: any }) => {
  const tryRequire = (name: string) => {
    try {
      return require('@/assets/images/feedback/lock/' + name + '.svg');
    } catch (err) {
      log.debug('Impossible to get image key.svg! ', err);
      return fallbackSrc;
    }
  };

  const siblingRefs = useRef<any[] | (typeof Accordion)[]>([]);
  siblingRefs.current = goals.map((a: any, i: number) => siblingRefs.current[i] ?? createRef());

  const closeSiblings = (activeIndex: number) => {
    siblingRefs.current.forEach((ref, i: number) => {
      if (i !== activeIndex) ref.current.close();
    });
  };

  return (
    <>
      {text.AchievementsTitle_Text ? (
        <h2
          className="mt-4 text-center title"
          dangerouslySetInnerHTML={{ __html: text.RetryTitle_Text }}
        />
      ) : (
        <h2 className="mt-4 text-center title">
          <Translation keyName="s1.replay">
            Rejouez le scénario pour déverrouiller de nouveaux badges
          </Translation>
        </h2>
      )}

      {goals.map((action: any, i: number) => (
        <Card className="mt-4" key={action.ID}>
          <Accordion
            id={action.ID}
            ref={siblingRefs.current[i]}
            openingCallback={() => closeSiblings(i)}>
            <Accordion.CTALabel className="flex items-center pr-[calc(18px+theme(spacing.4))] font-semibold after:right-0">
              <div className="mr-4 h-auto w-10">
                <img src={tryRequire(action.ID)} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: action.DisplayedName }} />
              <span className="ml-auto text-sm max-sm:hidden">En savoir plus</span>
            </Accordion.CTALabel>
            <div
              className="feedback-ending-desc mt-4 p-0"
              dangerouslySetInnerHTML={{ __html: action.Description }}></div>
          </Accordion>
        </Card>
      ))}
    </>
  );
};

export default Scenario1Retry;
