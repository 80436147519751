import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import '@/new-ui-index.scss';
import { StepContext } from '@UIKit/Stepper/Stepper';
import { Route, Routes, useLocation } from 'react-router-dom';
import Brieffing from './Brieffing/Brieffing';
import Exercise from './Exercise/Exercise';
import Feedback from './Feedback/Feedback';
import Home from './Home/Home';

const RoutesList = () => {
  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transition, setTransition] = useState('animate-fadeIn');

  useEffect(() => {
    if (location !== displayLocation) {
      setTransition('animate-fadeOut');
    }
  }, [location, displayLocation]);

  return (
    <div
      className={`${transition}`}
      onAnimationEnd={() => {
        if (transition === 'animate-fadeOut') {
          setTransition('animate-fadeIn');
          setDisplayLocation(location);
        }
      }}>
      <Routes location={displayLocation}>
        <Route path="/" element={<Home />} />
        <Route path="/brieffing" element={<Brieffing />} />
        <Route path="/exercise" element={<Exercise />} />
        <Route path="/feedback" element={<Feedback />} />
      </Routes>
    </div>
  );
};

const UIKitDemo = () => {
  const [activeStepId, setActiveStepId]: [string, Dispatch<SetStateAction<string>>] =
    useState<string>('ACT-0');

  return (
    <StepContext.Provider value={{ activeStepId, setActiveStepId }}>
      <RoutesList />
    </StepContext.Provider>
  );
};

export default UIKitDemo;
