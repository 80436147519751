import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class AppEvent extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);

  // Parameters
  EventType = '';
  EventParams = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.EventType = iProperties.EventType;
    if (iProperties.EventParams) {
      this.EventParams = iProperties.EventParams;
    }

    log.debug(
      this.GetIdentity() +
        ' constructor: graph = ' +
        this.Graph.ExerciseName +
        ', id = ' +
        this.ID +
        ', EventType = ' +
        this.EventType +
        ', EventParams = ' +
        JSON.stringify(this.EventParams)
    );
  }

  OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(
      this.GetIdentity() +
        " has been activated by '" +
        iActivator.GetIdentity() +
        "'.\nSending event '" +
        this.EventType +
        "' with params '" +
        JSON.stringify(this.EventParams) +
        "'."
    );

    // Broadcast the event to the exercise interface
    if (this.EventType) window.sdk.event().emit(this.EventType, this.EventParams);

    this.SetActive(false);
  }

  PrintParameters() {}
}
