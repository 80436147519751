import React, { useEffect, useRef } from 'react';
import Lottie from 'lottie-react';
import AchievementDoneAnimation from '../../assets/animations/AchievementDone';
import AchievementFailAnimation from '../../assets/animations/AchievementFail';
import BlaseAnimation from '../../assets/animations/Blase';
import BlaseNoBorderAnimation from '../../assets/animations/Blase_noborder';
import BullesAnimation from '../../assets/animations/Bulles';
import ContentAnimation from '../../assets/animations/Content';
import ContentNoBorderAnimation from '../../assets/animations/Content_noborder';
import EncouragementsAnimation from '../../assets/animations/Encouragements';
import Ending1Animation from '../../assets/animations/Ending1.json';
import Ending2Animation from '../../assets/animations/Ending2.json';
import Ending3Animation from '../../assets/animations/Ending3.json';
import InsultesAnimation from '../../assets/animations/Insultes';
import NonInterventionAnimation from '../../assets/animations/NonIntervention';
import Phase4Animation from '../../assets/animations/Phase4';
import raisingHandAnimation from '../../assets/animations/RaisingHand';
import RecadrageAnimation from '../../assets/animations/Recadrage';
import RecentrageAnimation from '../../assets/animations/Recentrage';
import ReformulationAnimation from '../../assets/animations/Reformulation';
import speechAnimation from '../../assets/animations/Speech';
import TristeAnimation from '../../assets/animations/Triste';
import TristeNoBorderAnimation from '../../assets/animations/Triste_noborder';
import waitingAnimation from '../../assets/animations/Waiting';
import WellRoundedAnimation from '../../assets/animations/WellRounded';

const Animation = (props) => {
  const components = {
    waiting: waitingAnimation,
    speech: speechAnimation,
    bulles: BullesAnimation,
    raisingHand: raisingHandAnimation,
    Ending_0: Ending1Animation,
    Ending_1: Ending1Animation,
    Ending_2: Ending2Animation,
    Ending_3: Ending3Animation,
    Ending: Ending3Animation,
    AchievementDone: AchievementDoneAnimation,
    AchievementFail: AchievementFailAnimation,
    Encouragements: EncouragementsAnimation,
    Insultes: InsultesAnimation,
    NonIntervention: NonInterventionAnimation,
    Phase4: Phase4Animation,
    Recadrage: RecadrageAnimation,
    Recentrage: RecentrageAnimation,
    WellRounded: WellRoundedAnimation,
    Reformulation: ReformulationAnimation,
    Questionnement: ReformulationAnimation,
    Blase: BlaseAnimation,
    Content: ContentAnimation,
    Triste: TristeAnimation,
    BlaseNoBorder: BlaseNoBorderAnimation,
    ContentNoBorder: ContentNoBorderAnimation,
    TristeNoBorder: TristeNoBorderAnimation
  };
  const lottieRef = useRef();

  useEffect(() => {
    window.sdk.event().on('playLottie', (name) => {
      if (name === props.name && props.type !== 'Ending_0') {
        lottieRef.current.stop();
        lottieRef.current.play();
      }
    });
    window.sdk.event().on('pauseLottie', (name) => {
      if (name === props.name && props.type !== 'Ending_0') lottieRef.current.stop();
    });
  }, []);

  return (
    <div className={'icon-animation-lottie ' + (props.classCSS ? props.classCSS : '')}>
      <Lottie
        lottieRef={lottieRef}
        animationData={components[props.type]}
        loop={props.loop}
        autoplay={props.autoplay}
      />
    </div>
  );
};

export default Animation;
