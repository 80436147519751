import Tooltip, { OriginSideType, TranslateYType } from '@UIKit/Tooltip/Tooltip';
import React, { Dispatch, SetStateAction, useContext } from 'react';
import { EvaluationType } from '@/models/DetailedFeedbackModel';

export type ToastDataModel = {
  id: string;
  evaluation: EvaluationType;
  body: JSX.Element;
};

export type ToastsContextModel = {
  toasts: ToastDataModel[];
  setToasts: Dispatch<SetStateAction<ToastDataModel[]>>;
};

export const ToastsContext = React.createContext({
  toasts: [],
  setToasts: () => {}
} as ToastsContextModel);

type ToastsProps = {
  className?: string;
  originSide?: OriginSideType;
  translateY?: TranslateYType;
};

const Toasts = ({ className = '', originSide, translateY }: ToastsProps) => {
  const { toasts, setToasts } = useContext(ToastsContext);

  const removeToast = (id: string) => {
    setToasts((toast) => toast.filter((t) => t.id !== id));
  };

  return (
    <ul className={className}>
      {toasts.map((toast) => (
        <li key={toast.id}>
          <Tooltip
            evaluation={toast.evaluation}
            originSide={originSide}
            translateY={translateY}
            onClose={() => removeToast(toast.id)}>
            {toast.body}
          </Tooltip>
        </li>
      ))}
    </ul>
  );
};

export default Toasts;
