import * as Sentry from '@sentry/react';
import { TolgeeProvider } from '@tolgee/react';
import Modal from '@UIKit/Modal/Modal';
import React, { useState, useEffect } from 'react';
import log from 'loglevel';
import CookieConsent from 'react-cookie-consent';
import { browserName, browserVersion, isBrowser } from 'react-device-detect';
import { useLocation, useNavigate } from 'react-router-dom';
import AlertBeforeLeaving from '@/components/AlertBeforeLeaving/AlertBeforeLeaving';
import AlertBrowser from '@/components/AlertBrowser/AlertBrowser';
import AllowCookies from '@/components/AllowCookies/AllowCookies';
import DebugFtueUserProfile from '@/components/DebugFtueUserProfile/DebugFtueUserProfile';
import { Translation } from '@/components/Utilities/Translation';
import getTolgeeInstance from '@/helpers/getTolgeeInstance';
import AuthLayout from '@/pages/auth/AuthLayout';
import MainLayout from '@/pages/MainLayout';
import Sdk from '@/sdk/Sdk';
import '@/styles/components/App.scss';

export function withRouter(Children) {
  return (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    return <Children {...props} match={location.state} navigate={navigate} />;
  };
}

const loadingScreen = (
  <div className="flex h-screen items-center">
    <div className="spinner-loader dark mx-auto block"></div>
  </div>
);

function CustomModal(props) {
  const [isOpened, setIsOpened] = useState(false);
  const [isBackdropCloseButton, setIsBackdropCloseButton] = useState(true);
  const [content, setContent] = useState(<></>);

  const openModal = ({ content, isBackdropCloseButton }) => {
    setIsOpened(true);
    setContent(content);
    setIsBackdropCloseButton(isBackdropCloseButton);
  };

  const closeModal = () => {
    setIsOpened(false);
    setContent(<></>);
    setIsBackdropCloseButton(true);
  };

  useEffect(() => {
    window.sdk.event().on('modalOpen', openModal);
    window.sdk.event().on('modalClose', closeModal);

    return () => {
      window.sdk.event().removeListener('modalOpen', openModal);
      window.sdk.event().removeListener('modalClose', closeModal);
    };
  });

  return (
    isOpened && (
      <Modal isBackdropCloseButton={isBackdropCloseButton} onClose={() => setIsOpened(false)}>
        <Modal.Content>{content}</Modal.Content>
      </Modal>
    )
  );
}

class App extends React.Component {
  constructor(props) {
    log.debug('+++ ' + new Date().toUTCString() + ' - Appplication started +++');

    super(props);
    this.state = {
      loggedIn: false,
      waitingInitialization: true,
      showSessionConfirmation: false,
      fullscreenExited: false,
      showExitedModal: false,
      showFullscreenExitedModal: false,
      exerciseURL: null,
      exitApp: false,
      alertCookie: false
    };

    this.zendeskKey = '573753d1-2f9f-4343-8339-1e364ee6fd0b';
    this.browserAvailables = {
      Chrome: 76,
      Edge: 88,
      Safari: 16
      // Firefox: 100
    };
  }
  manageUnsupportedBrowser() {
    if (process.env.REACT_APP_ENV !== 'prod') {
      return;
    }

    let unsupportedBrowser = !Object.keys(this.browserAvailables).includes(browserName);
    let unsupportedBrowserVersion = unsupportedBrowser
      ? false
      : this.browserAvailables[browserName] > browserVersion;

    if (unsupportedBrowser) {
      this.setState({ alertBrowser: 'browser' });
    } else if (unsupportedBrowserVersion) {
      this.setState({ alertBrowser: 'version' });
    }
  }

  async componentDidMount() {
    window.zESettings = {
      webWidget: {
        color: { theme: '#75309A' }
      }
    };

    const tolgeeInstance = getTolgeeInstance();
    this.setState({ tolgeeInstance });
    window.log = log;
    // DEBUG QA tests interface
    window.testMode = {
      functions: {
        skipNavigatorCheck: this.skipNavigatorCheck,
        forceUserActions: this.forceUserActions,
        skipExerciseStep: this.skipExerciseStep,
        stopExercise: this.stopExercise,
        setPauseTimeouts: this.setPauseTimeouts
      },
      autoSubs: false,
      autoSubsLanguage: 'english',
      skipSTTSetupTest: false,
      skipDeviceActivation: false,
      skipDeviceDisconnectCheck: false,
      fakeUserAudioToPlay: '',
      fakeUserSpeechToForce: '',
      fakeUserMode: false,
      fakeUserParams: {
        contextualInfo: `Camille est le manager de Sophie et il a initié un appel en visio avec elle car elle a eu des retards récemment alors que tout va bien d'habitude.
Le but de Camille est de comprendre ce qui se passe et de trouver une solution pour que Sophie puisse retrouver son efficacité.`,
        userBehaviour: `Camille a tendance à être très à l'écoute et à ne jamais sauter sur les solutions pour aider ses collaborateurs.`
      },
      forceUserActionsMode: false,
      fillAppStateValues: false,
      appStateValues: {},
      noDevicesMode: false
    };

    const isCookieEnabled = navigator.cookieEnabled;

    let storageOrUndefined = null;
    try {
      storageOrUndefined = window.localStorage;
    } catch (e) {
      storageOrUndefined = null;
    }

    if (!isCookieEnabled || !storageOrUndefined) {
      this.setState({ alertCookie: true });
    }

    log.debug("Browser '" + browserName + "' Version '" + browserVersion + "'");

    if (!isBrowser) {
      this.setState({ alertBrowser: 'device' });
      return;
    }

    this.manageUnsupportedBrowser();

    if (process.env.REACT_APP_ENV === 'dev') {
      log.setLevel('debug');
    } else {
      log.setLevel('warn');
    }

    window.policyUri =
      'https://uploads-ssl.webflow.com/64536c788c1d11148f3105e0/649f08d1544cd9ef641e563b_Politique%20de%20confidentialit%C3%A9%20PractiVizio.pdf';

    window.sdk = new Sdk();

    document.addEventListener('contextmenu', (event) => event.preventDefault());

    document.addEventListener('keydown', function (event) {
      // Ctrl + B to toggle debug mode
      window.sdk.event().emit('keydownDetected');
      if (event.ctrlKey && event.altKey && event.key === 'd') {
        document.body.classList.toggle('debug-mode');
      }
    });

    window.sdk.event().on('userLogged', () => {
      this.setState({
        loggedIn: true,
        showFullscreenExitedModal: true,
        fullscreenExited: false
      });
    });

    window.sdk.event().on('userLogOut', () => {
      this.setState({
        loggedIn: false,
        showExitedModal: false,
        showFullscreenExitedModal: false
      });
    });

    window.sdk.event().on('goTo', (url) => {
      url = url.replace(/^.*\/\/[^/]+/, '');
      this.props.navigate(url, { replace: false });
      this.setState({
        loggedIn: true,
        showFullscreenExitedModal: true,
        fullscreenExited: false
      });

      log.debug('--- DEBUG: Listener catched goTo to url: ', url);
    });

    window.sdk.event().on('needSesionConfirmation', (tmpMe, confirmation) => {
      this.sessionConfirmationCallback = confirmation;
      this.setState({
        showSessionConfirmation: true,
        tmpMe: tmpMe
      });
    });

    // @TODO: temporary disable exit fullscreen modal
    // window.sdk.event().on('fullscreenExited', () => {
    //   if (window.sdk.isInIframe()) {
    //     this.setState({
    //       fullscreenExited: true
    //     });
    //   }
    // });

    window.sdk.event().on('exitApp', () => {
      this.exitPractiVizio();
    });

    window.sdk.event().on('showExitedModal', (data) => {
      if (window.sdk.isInIframe()) {
        window.sdk.closeFullscreen();
        this.setState({
          showFullscreenExitedModal: false,
          showExitedModal: true,
          exitModalData: data
        });
      }
    });

    window.sdk.event().on('quitExercise', () => {
      if (window.sdk.isInIframe()) {
        window.sdk.closeFullscreen();
        this.setState({
          showFullscreenExitedModal: false,
          showExitedModal: true
        });
      }
    });

    await window.sdk.init();

    window.infoVersion = await window.sdk.fetchInternalAPI().get('/version');

    // Update App version in Sentry
    if (
      !window.location.host.includes('localhost') &&
      !window.location.host.includes('127.0.0.1')
    ) {
      Sentry.configureScope((scope) =>
        scope.addEventProcessor(
          (event) =>
            new Promise((resolve) =>
              resolve({
                ...event,
                release: 'PractiVizio-front@' + window.infoVersion.version,
                environment: window.sdk.env()
              })
            )
        )
      );
    }
    /* eslint-disable */
    log.debug(
      'Appplication initialized: environment = ' +
        window.sdk.env() +
        ', version = ' +
        window.infoVersion.version
    );
    /* eslint-enable */

    // Get the exercise url and exclude the potential "?needCheck=1" in the end
    const exerciseURL = window.location.pathname;
    log.debug('Exercise URL = ' + exerciseURL);
    const regex = /^\/exercise\//;
    if (regex.test(exerciseURL)) {
      window.sdk.setRedirectUrl(exerciseURL);
    }
    this.setState({
      waitingInitialization: false,
      exerciseURL: exerciseURL
    });
  }

  sessionConfirmYES = () => {
    this.sessionConfirmationCallback(true);

    this.setState({
      showSessionConfirmation: false
    });
  };

  sessionConfirmNO = () => {
    window.localStorage.setItem('cookie-consent', false);

    this.sessionConfirmationCallback(false);

    this.setState({
      showSessionConfirmation: false
    });
  };

  exitPractiVizio = () => {
    this.setState({
      exitApp: true
    });
  };

  goFullscreen = () => {
    this.setState({
      fullscreenExited: false
    });
    window.sdk.openFullscreen();
  };

  iframeRetry = () => {
    // TODO: better way to restart the exercise than reloading the full iframe?
    log.debug(
      "iframeRetry: Current URL: '" +
        window.location.href +
        "'. Exercise URL: '" +
        this.state.exerciseURL +
        "'."
    );

    // Using navigate on this.state.exerciseURL to load the exercise URL
    // Because if we come from the feedbacks 'Stop' button, a simple reload loads back the feedbacks panel
    if (this.state.exerciseURL) {
      window.sdk.event().emit('goTo', this.state.exerciseURL);
    }

    // Forcing a reload of the iframe
    // Because without it, the iframe is in a broken state if we come from a 'Stop' button in the middle of an exercise
    window.location.reload(false);

    // Make the modal disappear
    this.setState({
      showExitedModal: false,
      showFullscreenExitedModal: false
    });
  };

  // Debug or QA tests functions
  forceUserActions = (userActionIDs) => {
    window.sdk.event().emit('forceUserActions', userActionIDs);
  };

  skipNavigatorCheck = () => {
    this.setState({
      alertBrowser: false
    });
  };

  skipExerciseStep = () => {
    window.sdk.event().emit('skipExerciseStep');
  };

  stopExercise = () => {
    window.sdk.event().emit('stopExercise');
  };

  setPauseTimeouts = (timeBeforePopup, timeBeforeStop) => {
    window.sdk.event().emit('setPauseTimeouts', timeBeforePopup, timeBeforeStop);
  };

  skipCookieDisabledModal = () => {
    this.setState({
      alertCookie: false
    });
  };

  skipBrowserNotAllowed = () => {
    this.setState({
      alertBrowser: false
    });
  };

  render() {
    const { tolgeeInstance } = this.state;

    if (!tolgeeInstance) {
      return loadingScreen;
    }

    if (this.state.exitApp) {
      return (
        <TolgeeProvider tolgee={getTolgeeInstance()} fallback={loadingScreen}>
          <AlertBeforeLeaving />
        </TolgeeProvider>
      );
    }

    if (this.state.alertCookie) {
      return (
        <TolgeeProvider tolgee={getTolgeeInstance()} fallback={loadingScreen}>
          <AllowCookies onSkip={this.skipCookieDisabledModal} onRefresh={this.iframeRetry} />
        </TolgeeProvider>
      );
    }

    if (this.state.alertBrowser) {
      return (
        <TolgeeProvider tolgee={tolgeeInstance} fallback={loadingScreen}>
          <AlertBrowser
            kind={this.state.alertBrowser}
            exitPractiVizio={this.exitPractiVizio}
            skipNavigatorCheck={this.skipNavigatorCheck}
            skipBrowserNotAllowed={this.skipBrowserNotAllowed}
          />
        </TolgeeProvider>
      );
    }

    if (this.state.waitingInitialization) return loadingScreen;

    return (
      <TolgeeProvider tolgee={getTolgeeInstance()} fallback={loadingScreen}>
        {!this.state.showSessionConfirmation && !this.state.showExitedModal && (
          <>{this.state.loggedIn ? <MainLayout /> : <AuthLayout />}</>
        )}

        <CustomModal />

        <div className="legacy">
          {window.localStorage.getItem('cookie-consent') !== 'ok' && (
            <CookieConsent
              cookieSecurity={true}
              expires={30}
              onAccept={() => {
                window.localStorage.setItem('cookie-consent', 'ok');
              }}
              location="bottom"
              buttonText="J'accepte"
              overlay={true}
              style={{
                backgroundColor: 'rgb(54, 18, 35)',
                color: 'rgb(252, 246, 233)',
                fontSize: '14px',
                padding: '0.25rem 1rem'
              }}
              buttonStyle={{
                backgroundColor: 'rgb(252, 246, 233)',
                color: 'rgb(54, 18, 35)',
                borderRadius: '10px',
                fontWeight: '600',
                fontSize: '14px',
                padding: '1rem 1.5rem'
              }}
              overlayStyle={{
                backgroundColor: 'rgba(255,255,255,0.7)',
                zIndex: 99999999
              }}>
              <Translation keyName="app.uses_cookies">
                Ce site utilise des cookies pour améliorer l'expérience utilisateur.
              </Translation>
            </CookieConsent>
          )}

          {this.state.showSessionConfirmation && (
            <Modal isBackdropCloseButton={false}>
              <Modal.Content>
                <p className="text-center">
                  Êtes-vous bien {this.state.tmpMe.FirstName} {this.state.tmpMe.LastName} ?
                </p>
                <div className="debug debug_graph_controls">
                  <DebugFtueUserProfile />
                </div>
                <div className="space-x-6 text-center">
                  <button className="cta cta--accent" onClick={this.sessionConfirmNO}>
                    <Translation keyName="general.no">Non</Translation>
                  </button>
                  <button className="cta cta--accent" onClick={this.sessionConfirmYES}>
                    <Translation keyName="general.yes">Oui</Translation>
                  </button>
                </div>
              </Modal.Content>
            </Modal>
          )}
          {this.state.showExitedModal ? (
            <Modal isBackdropCloseButton={false} hasOpaqueBackground={true}>
              <Modal.Content>
                <p className="text-center text-xl">
                  <strong>Cette partie de l'expérience est terminée</strong>
                </p>
                <p className="text-center text-lg">
                  Faites défiler l'écran vers le bas pour découvrir la suite de la formation
                </p>
                <p className="text-center text-lg">
                  Si vous souhaitez la recommencer, actualisez la page à l'aide de la touche F5
                </p>

                {/* <p className="text-center">
                  {this.state.exitModalData.ExitMessage
                    ? this.state.exitModalData.ExitMessage
                    : 'Merci pour votre participation !'}
                </p> */}

                {/* {this.state.exitModalData.ExitedButton_Visible && (
                  <button
                    className="cta cta--accent mx-auto block w-fit"
                    onClick={this.iframeRetry}>
                    {this.state.exitModalData.ExitedButton_Text ? (
                      this.state.exitModalData.ExitedButton_Text
                    ) : (
                      <Translation keyName="general.restart">Recommencer</Translation>
                    )}
                  </button>
                )} */}
              </Modal.Content>
            </Modal>
          ) : (
            <>
              {this.state.showFullscreenExitedModal &&
                this.state.fullscreenExited &&
                this.state.loggedIn && (
                  <Modal isBackdropCloseButton={false} hasOpaqueBackground={true}>
                    <Modal.Content>
                      <p className="text-center">
                        <Translation keyName="app.fullscree">
                          Vous venez de quitter le mode plein écran. Cliquez sur "Continuer" pour
                          reprendre où vous en étiez.
                        </Translation>
                      </p>

                      <button
                        className="cta cta--accent mx-auto block w-fit"
                        onClick={this.goFullscreen}>
                        <Translation keyName="general.continue">Continuer</Translation>
                      </button>
                    </Modal.Content>
                  </Modal>
                )}
            </>
          )}
        </div>
      </TolgeeProvider>
    );
  }
}

let AppExport;
if (!window.location.host.includes('localhost') && !window.location.host.includes('127.0.0.1'))
  AppExport = Sentry.withProfiler(App);
else AppExport = App;

export default withRouter(AppExport);
