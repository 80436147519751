import React from 'react';
import log from 'loglevel';
import TopBar from '@/components/TopBar/TopBar';
import Bot from '@/pages/Exercise/VisioExercise/Bot';
import ParticipantsModule from '../../AppClasses/Participants/ParticipantsModule';

export default class TestBot extends React.Component {
  state = {
    started: false,
    bot: null
  };

  componentDidMount() {}

  start = () => {
    this.setState({
      started: true
    });

    this.BotsModuleInstance = new ParticipantsModule({
      onNewBot: (bot) => {
        this.setState({
          bot
        });
      }
    });

    ParticipantsModule.Instance.StartABot('Toto', () => {
      log.debug('that is the end');
    });

    let bot = ParticipantsModule.Instance.GetBot('Toto');
    bot.SetWaitingLoop(
      'https://http.jay.invaders.stream/video-transition/AttenteBienveillant_Leopold_LR_L1V1.mp4'
    );

    setTimeout(() => {
      let bot = ParticipantsModule.Instance.GetBot('Toto');
      log.debug('bot play');
      bot.PlayVideo(
        'https://http.jay.invaders.stream/video-transition/Reworked_NonPasPossibleNTLong_Leopold.mp4'
      );
      bot.SetWaitingLoop(
        'https://http.jay.invaders.stream/video-transition/ReworkedJecouteLong_Leopold.mp4'
      );
      setTimeout(() => {
        bot.PlayVideo(
          'https://http.jay.invaders.stream/video-transition/ReworkedBonjourLong_Leopold.mp4'
        );
      }, 1000);
    }, 5000);
  };

  render() {
    return (
      <>
        <TopBar />

        {!this.state.started && <button onClick={this.start}>START</button>}

        {this.state.bot !== null && <Bot bot={this.state.bot} />}
      </>
    );
  }
}
