import React from 'react';
import log from 'loglevel';
import Utils from '@/AppClasses/Utils/Utils';
import ParticipantVideoSlot from './ParticipantVideoSlot';

export default class Bot extends ParticipantVideoSlot {
  type = 'bot';

  constructor(props) {
    log.debug('Bot:constructor', props.bot.getID());

    super(props, props.bot);

    this.botClass = '.participant_' + this.props.bot.getID();
  }

  async componentDidMount() {
    while (!document.querySelector(this.botClass + ' .bot_layer1')) {
      await Utils.Sleep(100);
    }

    this.props.bot.domIsReady(document.querySelector(this.botClass));

    this.props.bot.subscribeToSubtitlesChange(this.handleSubtitlesChange);

    this.setState({ subtitles: '' });

    super.componentDidMount();
  }

  componentWillUnmount() {
    this.props.bot.subscribeToSubtitlesChange(null);

    super.componentWillUnmount();
  }

  handleSubtitlesChange = (newSubtitles) => {
    this.setSubtitles(newSubtitles);
  };

  setSubtitles = (text) => {
    this.setState({ subtitles: text });
  };

  renderVideo() {
    return (
      <>
        <div className="wrapper relative overflow-hidden rounded-2.5xl p-[28.169%_50%]">
          <div className="video_layer bot_layer1"></div>
          <div className="video_layer bot_layer2"></div>
        </div>
        <p className="absolute left-1/2 top-12 line-clamp-5 w-[calc(100%-theme(spacing.8))] -translate-x-1/2 text-center text-sm text-white text-shadow-subtitles">
          {this.state.subtitles}
        </p>
      </>
    );
  }
}
