import Accordion from '@UIKit/Accordion/Accordion';
import Card from '@UIKit/Card/Card';
import React from 'react';
import { Translation } from '@/components/Utilities/Translation';
import AchievementIcon from './AchievementIcon';
import '@/styles/components/Achievement.scss';

export default class Scenario1Ending extends React.Component {
  render() {
    return (
      <div className="feedback-step-endings">
        <h2
          className="mt-4 text-center title"
          dangerouslySetInnerHTML={{ __html: this.props.text.EndingTitle_Text }}></h2>

        <Card className="mt-4">
          <Accordion id={'Scenario1Ending'}>
            <Accordion.CTALabel className="flex items-center pr-[calc(18px+theme(spacing.4))] font-semibold after:right-0">
              <div className="mr-4 h-auto w-10">
                <AchievementIcon achievement={this.props.ending} name={'ending'} />
              </div>
              <div dangerouslySetInnerHTML={{ __html: this.props.ending.DisplayedName }} />
              <span className="ml-auto text-sm max-sm:hidden">En savoir plus</span>
            </Accordion.CTALabel>
            <div
              className="feedback-ending-desc mt-4 p-0"
              dangerouslySetInnerHTML={{ __html: this.props.ending.Description }}></div>
          </Accordion>
        </Card>
      </div>
    );
  }
}
Scenario1Ending.defaultProps = {
  text: {
    CongratsTitle_Text: (
      <Translation keyName="s1.discover_end">
        Découvrez la fin que <br /> vos actions ont débloquée
      </Translation>
    )
  }
};
