import { T, TranslationKey } from '@tolgee/react';
import React from 'react';

export const TOLGEE_TAGS_INTERPOLATION_PARAMS: { [key: string]: JSX.Element } = {
  p: <p />,
  a: <a />,
  h1: <h1 />,
  h2: <h2 />,
  h3: <h3 />,
  h4: <h4 />,
  h5: <h5 />,
  h6: <h6 />,
  ul: <ul />,
  ol: <ol />,
  li: <li />,
  table: <table />,
  thead: <thead />,
  tbody: <tbody />,
  tr: <tr />,
  th: <th />,
  td: <td />,
  span: <span />,
  strong: <strong />,
  em: <em />,
  i: <i />,
  b: <b />,
  u: <u />,
  s: <s />,
  blockquote: <blockquote />,
  br: <br />,
  hr: <hr />
};

export const Translation = ({
  keyName,
  children
}: {
  keyName: string;
  children?: string | TranslationKey | undefined;
}) => {
  return (
    <T keyName={keyName} params={TOLGEE_TAGS_INTERPOLATION_PARAMS}>
      {children}
    </T>
  );
};
