import React, { ReactNode, RefObject, useContext, useEffect, useState } from 'react';
import defs from '@/assets/new-ui/defs.svg';
import { UserActionModel } from '@/models/DetailedFeedbackModel';
import { FeedbackTabsContext } from './FeedbackDetail';

export const UserActionsHeader = ({
  userActions = [],
  className,
  children
}: {
  userActions: UserActionModel[];
  className?: string;
  children: ReactNode;
}) => {
  const { activeUserActionId, setActiveUserActionId } = useContext(FeedbackTabsContext);

  const [tabRefs, setTabRefs] = useState<RefObject<HTMLButtonElement>[]>([]);

  const getActiveUserActionIndex = () =>
    userActions.findIndex((userAction: UserActionModel) => userAction.id === activeUserActionId);

  useEffect(() => {
    setActiveUserActionId(userActions[0].id);
    setTabRefs(userActions.map(() => React.createRef<HTMLButtonElement>()));
  }, [userActions]);

  const selectUserAction = (rewiewId: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    setActiveUserActionId(rewiewId);
  };

  const navPrevUserAction = () => (event: React.MouseEvent) => {
    event.preventDefault();
    const currentIndex: number = getActiveUserActionIndex();
    if (currentIndex > 0) {
      setActiveUserActionId(userActions[currentIndex - 1].id);
    }
  };

  const navNextUserAction = () => (event: React.MouseEvent) => {
    event.preventDefault();
    const currentIndex: number = getActiveUserActionIndex();

    if (currentIndex < userActions.length) {
      setActiveUserActionId(userActions[currentIndex + 1].id);
    }
  };

  const onKeydown = (event: React.KeyboardEvent) => {
    const tabsLength: number = userActions.length;
    if (!tabsLength) {
      return;
    }

    const activeTabIndex: number = getActiveUserActionIndex();

    let timer: undefined | ReturnType<typeof setTimeout>;

    switch (event.key) {
      case 'ArrowRight': {
        event.preventDefault();
        if (activeTabIndex < tabsLength - 1) {
          setActiveUserActionId(userActions[activeTabIndex + 1].id);
          timer = setTimeout(() => tabRefs[activeTabIndex + 1].current?.focus());
        }
        break;
      }
      case 'ArrowLeft': {
        event.preventDefault();
        if (activeTabIndex > 0) {
          setActiveUserActionId(userActions[activeTabIndex - 1].id);
          timer = setTimeout(() => tabRefs[activeTabIndex - 1].current?.focus());
        }
        break;
      }
      default: {
        break;
      }
    }

    return () => clearTimeout(timer);
  };

  return (
    <header className={`${className} flex w-full items-center`}>
      <button
        className="-ml-6 h-12 px-6 transition-opacity disabled:opacity-40"
        onClick={navPrevUserAction()}
        disabled={activeUserActionId === userActions[0].id}>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false">
          <use href={`${defs}#chevron-left`} />
        </svg>
      </button>
      <div className="mx-auto">
        <h3
          className={`transition-colors label [&.label]:text-sm ${
            userActions[getActiveUserActionIndex()]?.isAdvice
              ? 'text-advice'
              : 'text-[var(--evaluation)]'
          }`}>
          {children}
        </h3>

        <ul
          className="flex justify-center"
          role="tablist"
          aria-orientation="horizontal"
          aria-label="Slides">
          {userActions.map((userAction: UserActionModel, index: number) => (
            <li key={userAction.id}>
              <button
                id={'tab-' + userAction.id}
                ref={tabRefs[index]}
                role="tab"
                aria-controls={'panel-' + userAction.id + ' extra-panel-' + userAction.id}
                aria-selected={activeUserActionId === userAction.id}
                tabIndex={activeUserActionId === userAction.id ? 0 : -1}
                style={
                  {
                    '--evaluation': userAction?.isAdvice
                      ? `var(--advice-color)`
                      : userAction.evaluation
                        ? `var(--${userAction.evaluation.toLowerCase()}-color)`
                        : ''
                  } as React.CSSProperties
                }
                className={`${
                  activeUserActionId === userAction.id ? 'size-2' : 'size-1'
                } m-0.5 rounded-full bg-[var(--evaluation)] text-[0] transition-all`}
                onClick={selectUserAction(userAction.id)}
                onKeyDown={onKeydown}
                onFocus={() => selectUserAction(userAction.id)}>
                {index}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <button
        onClick={navNextUserAction()}
        className="-mr-6 h-12 px-6 transition-opacity disabled:opacity-40"
        data-testid="feedback-uaf-next-button"
        disabled={activeUserActionId === userActions[userActions.length - 1].id}>
        <svg
          width="10"
          height="18"
          viewBox="0 0 10 18"
          className="fill-current"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false">
          <use href={`${defs}#chevron-right`} />
        </svg>
      </button>
    </header>
  );
};
