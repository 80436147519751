import BaseNode from './BaseNode';

export default class ExerciseNode extends BaseNode {
  // Internal values
  m_IsActive = false;
  m_IsRewindMode = false;

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);
  }

  Reset() {
    this.SetActive(false);
  }

  Update() {
    if (this.m_IsActive) {
      // Update
    }
  }

  OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    this.SetIsRewindMode(iIsRewindMode);

    if (iIsRewindMode) {
      this.SetActive(false);
      return;
    }

    this.Graph.History.AddNodeActivation(this.ID, this.Type);
    this.SetActive(true);
  }

  OnDeactivated() {
    this.SetActive(false);
  }

  SetActive(iActive) {
    this.m_IsActive = iActive;
  }

  SetIsRewindMode(iIsInRewindMode) {
    this.m_IsRewindMode = iIsInRewindMode;
  }

  IsRewindMode() {
    return this.m_IsRewindMode;
  }

  IsActive() {
    return this.m_IsActive;
  }

  Pause() {}

  Resume() {}

  FreezeSystem() {}

  UnfreezeSystem() {}

  IsPaused() {
    return this.Graph.IsPaused();
  }

  IsSystemFrozen() {
    return this.Graph.IsSystemFrozen();
  }
}
