import Card from '@UIKit/Card/Card';
import Modal from '@UIKit/Modal/Modal';
import React, { Dispatch, SetStateAction, useState } from 'react';
import defs from '@/assets/new-ui/defs.svg';
import { ReactComponent as Practicio } from '@/assets/new-ui/practicio.svg';
import TopBar from '@/components/TopBar/TopBar';
import SmCover from './MakeARequest/images/cover-with-teammates-sm.jpg';
import SmCover2x from './MakeARequest/images/cover-with-teammates-sm@2x.jpg';
import Cover from './MakeARequest/images/cover-with-teammates.jpg';
import Cover2x from './MakeARequest/images/cover-with-teammates@2x.jpg';
import MakeARequest from './MakeARequest/MakeARequest';
import '@/new-ui-index.scss';

const Home = () => {
  const [isModalOpened, setIsModalOpened]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);

  const toggleModal = () => {
    setIsModalOpened(!isModalOpened);
  };

  return (
    <>
      <TopBar className="sticky left-0 top-0 z-50 w-full" />
      <div className="centered-row min-h-[calc(100vh-theme(spacing.12))]">
        <h1 className="text-3xl font-bold underline">UI Kit</h1>

        <Practicio className="max-w-full fill-accent" />
        <Practicio className="max-w-full fill-brand" />

        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          focusable="false"
          width={126}
          height={96}
          className="fill-brand">
          <use href={`${defs}#watermark`} />
        </svg>

        <Card className="max-w-2xl" theme="BRAND">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            width={126}
            height={96}>
            <use href={`${defs}#watermark`} />
          </svg>
          <h2>Test</h2>
        </Card>

        <ul className="list-disc">
          <li>
            <a className="underline" href="/demo-kit-ui/brieffing">
              brieffing
            </a>
          </li>
          <li>
            <a className="underline" href="/demo-kit-ui/exercise">
              exercise
            </a>
          </li>

          <li>
            <a className="underline" href="/demo-kit-ui/feedback">
              feedback
            </a>
          </li>
        </ul>

        <button className="cta cta--accent" onClick={() => setIsModalOpened(true)}>
          Open modal
        </button>

        {isModalOpened && (
          <Modal onClose={() => toggleModal()}>
            <Modal.Header
              cover={{
                alt: '',
                smImage: {
                  src: SmCover,
                  height: 250,
                  width: 640
                },
                smImage2x: {
                  src: SmCover2x,
                  height: 500,
                  width: 1280
                },
                image: {
                  src: Cover,
                  height: 250,
                  width: 1000
                },
                image2x: {
                  src: Cover2x,
                  height: 500,
                  width: 2000
                }
              }}>
              <Modal.Title label={<>Tout savoir sur&hellip;</>}>Formuler une demande</Modal.Title>
            </Modal.Header>
            <Modal.Content>
              <MakeARequest />
            </Modal.Content>
          </Modal>
        )}
      </div>
    </>
  );
};

export default Home;
