import React from 'react';

const DebugFtueUserProfile = () => {
  return (
    <>
      <h3>User profile type:</h3>
      <select
        value={window.testMode.forceFirstTime}
        onChange={(e) => (window.testMode.forceFirstTime = e.target.value)}>
        <option value=""></option>
        <option value="noftue_user">Force User - 6 month</option>
        <option value="unknown_user">Force Unknown user</option>
        <option value="known_user">Force User + 6 month</option>
      </select>
    </>
  );
};
export default DebugFtueUserProfile;
