import React, { PropsWithChildren } from 'react';
import { EvaluationType } from '@/models/DetailedFeedbackModel';
import styles from './Card.module.css';

export type CardThemeType = 'BRAND' | 'LIGHT_BRAND';

type CardProps = {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  theme?: CardThemeType;
  role?: string;
  tabIndex?: number;
  areCustomStyles?: boolean;
  dataTestid?: string;
};

const getThemeClassName = (theme?: CardThemeType): string => {
  switch (theme) {
    case 'BRAND':
      return ' bg-brand text-off-white';
    case 'LIGHT_BRAND':
      return ' bg-light-brand text-off-white';
    default:
      return ' bg-white text-light-brand';
  }
};

const Card = ({
  id,
  className = '',
  style,
  theme,
  role,
  tabIndex,
  children,
  areCustomStyles = true,
  dataTestid = ''
}: PropsWithChildren<CardProps>) => {
  const themeClassName = getThemeClassName(theme);

  return (
    <article
      data-testid={dataTestid}
      {...{
        ...(id && { id: id }),
        ...(role && { role: role }),
        ...(style && { style: style }),
        ...(tabIndex && { tabIndex: tabIndex })
      }}
      className={`${
        areCustomStyles ? '' : styles.content
      } ${className} ${themeClassName} rounded-2.5xl fill-current p-6`}>
      {children}
    </article>
  );
};

const getBorderClassName = (color?: 'accent' | 'advice' | EvaluationType): string => {
  if (typeof color === 'undefined') {
    return 'border-l-4 border-light-brand';
  } else if (color === 'accent') {
    return 'border-l-4 border-accent';
  } else {
    return 'border-l-4 border-[var(--evaluation)]';
  }
};

const Border = ({
  color,
  ...props
}: PropsWithChildren<{ color?: 'accent' | 'advice' | EvaluationType }>) => {
  const borderClassName = getBorderClassName(color);

  return (
    <div
      {...(color !== 'accent' &&
        typeof color !== 'undefined' && {
          style: {
            '--evaluation': `var(--${color.toLowerCase()}-color)`
          } as React.CSSProperties
        })}
      className={`${borderClassName} pl-4`}>
      {props.children}
    </div>
  );
};

Card.Border = Border;

const getTitleColorClassName = (color?: 'accent' | 'advice' | EvaluationType): string => {
  if (typeof color === 'undefined') {
    return 'text-light-brand';
  } else if (color === 'accent') {
    return 'text-accent';
  } else {
    return 'text-[var(--evaluation)]';
  }
};

const Title = ({
  color,
  label,
  ...props
}: PropsWithChildren<{ color?: 'accent' | 'advice' | EvaluationType; label?: JSX.Element }>) => {
  const titleColorClassName = getTitleColorClassName(color);

  return (
    <>
      {label && <p className="flex-1 label">{label}</p>}
      <h2
        {...(color !== 'accent' &&
          typeof color !== 'undefined' && {
            style: {
              '--evaluation': `var(--${color.toLowerCase()}-color)`
            } as React.CSSProperties
          })}
        className={`mt-2 title ${titleColorClassName}`}>
        {props.children}
      </h2>
    </>
  );
};

Card.Title = Title;

export default Card;
