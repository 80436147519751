import log from 'loglevel';
import { CognitoUser, AuthenticationDetails, CognitoUserPool } from 'amazon-cognito-identity-js';

export default class Cognito {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async loginInfo() {
    return {
      UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
      ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
    };
  }

  async init() {
    let loginInfo = await this.loginInfo();
    this.userPool = new CognitoUserPool(loginInfo);
  }

  async login(username, password) {
    return new Promise((resolve) => {
      const authenticationDetails = new AuthenticationDetails({
        Username: username,
        Password: password
      });

      const cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
      });

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          log.debug('Cognito', 'authentication successful', session);
          resolve({
            state: 'SUCCESS',
            session
          });
        },
        onFailure: (error) => {
          log.debug('Cognito', 'authentication failed', error);
          resolve({
            state: 'ERROR',
            error
          });
        },
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          // User needs to set a new password
          log.debug('Password change required', userAttributes, requiredAttributes);
          userAttributes.name = username;
          delete userAttributes.email;
          delete userAttributes.email_verified;
          const newPassword = password; // Get new password from user input
          cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: (session) => {
              log.debug('Password changed successfully:', session);
              resolve({
                state: 'SUCCESS',
                session
              });
            },
            onFailure: (error) => {
              log.error('Error changing password:', error);
              resolve({
                state: 'ERROR',
                error
              });
            }
          });
        }
      });
    });
  }

  async validateSession() {
    return new Promise((resolve) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        resolve({
          state: 'NO_VALID'
        });
        return;
      }

      cognitoUser.getSession(function (error, session) {
        if (error) {
          resolve({
            state: 'NO_VALID',
            error
          });
          return;
        }

        // Vérifier si la session complète est valide
        let isValid = session.isValid();

        if (!isValid) {
          resolve({
            state: 'NO_VALID'
          });
          return;
        }

        // Vérifier spécifiquement l'expiration de l'accessToken
        const currentTimestamp = Math.floor(new Date().getTime() / 1000);

        const isAccessTokenExpired = session.getAccessToken().getExpiration() < currentTimestamp;
        if (isAccessTokenExpired) {
          resolve({
            state: 'EXPIRED'
          });
          return;
        }

        // Vérifier spécifiquement l'expiration de l'idToken
        const isIdTokenExpired = session.getIdToken().getExpiration() < currentTimestamp;
        if (isIdTokenExpired) {
          resolve({
            state: 'EXPIRED'
          });
          return;
        }

        //log.debug('Cognito', 'session.getIdToken()', session.getIdToken());

        resolve({
          state: 'VALID',
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken()
        });
      });
    });
  }

  async refreshSession() {
    return new Promise((resolve) => {
      const cognitoUser = this.userPool.getCurrentUser();

      if (!cognitoUser) {
        resolve({
          state: 'ERROR'
        });
        return;
      }

      cognitoUser.getSession(function (error, session) {
        if (error) {
          resolve({
            state: false,
            error
          });
          return;
        }

        if (session.isValid()) {
          resolve({
            state: 'VALID'
          });
          return;
        }

        // Si la session est invalide (c'est-à-dire que les jetons sont expirés), renouvelez-la
        const refreshToken = session.getRefreshToken();
        if (refreshToken && refreshToken.getToken()) {
          cognitoUser.refreshSession(refreshToken, (refreshErr, newSession) => {
            if (refreshErr) {
              resolve({
                state: 'ERROR',
                error: refreshErr
              });
              log.error('Cognito', 'Refresh session error:', refreshErr);
              return;
            }

            let accessToken = newSession.getAccessToken().getJwtToken();
            let idToken = newSession.getIdToken().getJwtToken();

            log.debug('Cognito', 'Session has been refreshed!');
            log.debug('Cognito', 'New Access Token:', accessToken);
            log.debug('Cognito', 'New ID Token:', idToken);

            resolve({
              state: 'SUCCESS',
              accessToken,
              idToken
            });
          });
        } else {
          log.error('Cognito', 'No refresh token available.');
          resolve({
            state: 'ERROR',
            error: 'No refresh token available.'
          });
        }
      });
    });
  }

  async logout() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
      log.debug('Cognito', 'User has been signed out.');
    }
  }

  async forgotPassword({ email }) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool
    });

    return new Promise((resolve) => {
      cognitoUser.forgotPassword({
        onSuccess: function (data) {
          log.debug('Cognito', 'forgotPassword onSuccess', data);
          resolve({
            state: 'success',
            info: data
          });
        },
        onFailure: function (error) {
          log.debug('Cognito', 'forgotPassword onFailure', error);
          resolve({
            state: 'fail',
            info: error
          });
        }
      });
    });
  }

  async confirmForgotPassword({ email, code, newPassword }) {
    const cognitoUser = new CognitoUser({
      Username: email,
      Pool: this.userPool
    });

    return new Promise((resolve) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: function () {
          log.debug('Cognito', 'confirmPassword onSuccess');
          resolve({
            state: 'success'
          });
        },
        onFailure: function (error) {
          log.debug('Cognito', 'confirmPassword onFailure', error);
          resolve({
            state: 'fail',
            info: error
          });
        }
      });
    });
  }
}
