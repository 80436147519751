import {
  SpeechConfig,
  AudioConfig,
  SpeechRecognizer,
  ResultReason,
  PropertyId,
  OutputFormat,
  PhraseListGrammar
} from 'microsoft-cognitiveservices-speech-sdk';
import log from 'loglevel';

export default class SpeechToTextManager {
  constructor(sdk) {
    this.sdk = sdk;
    this.speechConfig = null;
    this.audioConfig = null;
    this.recognizer = null;
    this.isListening = false;
    this.onRecognizedCallbacks = [];
    this.onRecognizingCallbacks = [];
    this.language = this.sdk.getLanguage() === 'en' ? 'en-US' : 'fr-FR';
    this.tokenExpirationTime = null;
    this.customSpeechEndpoint = process.env.REACT_APP_CUSTOM_SPEECH_ENDPOINT;
  }

  async initialize(isMicValidation = false, phraseList = []) {
    this.language = this.sdk.getLanguage() === 'en' ? 'en-US' : 'fr-FR';
    try {
      await this.refreshTokenIfNeeded(isMicValidation);
      if (!this.audioConfig) {
        this.audioConfig = AudioConfig.fromDefaultMicrophoneInput();
      }
      this.setupRecognizer(phraseList);
    } catch (error) {
      log.error('Failed to initialize speech recognition:', error);
    }
  }

  async refreshTokenIfNeeded(isMicValidation) {
    const tokenData = await this.sdk.getSpeechToken();
    if (tokenData) {
      const { token, region } = tokenData;
      this.speechConfig = SpeechConfig.fromAuthorizationToken(token, region);
      this.speechConfig.speechRecognitionLanguage = this.language;
      this.speechConfig.setProfanity(2); // Allow profanites to be sent over STT

      if (!isMicValidation) {
        this.speechConfig.enableAudioLogging(); // Enable audio logging only when not in mic validation
      }

      if (this.customSpeechEndpoint) {
        this.speechConfig.endpointId = this.customSpeechEndpoint;
        this.speechConfig.setProperty(
          PropertyId.SpeechServiceConnection_EndpointId,
          this.customSpeechEndpoint
        );
      }
    } else {
      log.warn('Failed to get speech token, using previous configuration if available');
    }
  }

  setupRecognizer(phraseList = []) {
    if (this.recognizer) {
      this.recognizer.close();
    }
    if (this.speechConfig && this.audioConfig) {
      this.recognizer = new SpeechRecognizer(this.speechConfig, this.audioConfig);
      this.setupRecognizerCallbacks();

      // Add phrase list to the recognizer
      if (phraseList.length > 0) {
        const phraseListGrammar = PhraseListGrammar.fromRecognizer(this.recognizer);
        phraseList.forEach((phrase) => phraseListGrammar.addPhrase(phrase));
      }
    } else {
      throw new Error('Speech configuration or audio configuration is not set');
    }
  }

  setupRecognizerCallbacks() {
    this.recognizer.recognizing = (s, e) => {
      const result = e.result;
      const sessionId = e.sessionId;
      this.onRecognizingCallbacks.forEach((callback) =>
        callback(result.text, result.privResultId, sessionId)
      );
    };

    this.recognizer.recognized = (s, e) => {
      const result = e.result;
      const sessionId = e.sessionId;
      if (result.reason === ResultReason.RecognizedSpeech) {
        log.debug(
          `Recognized speech. privResultId: ${result.privResultId}, Session ID: ${sessionId}`
        );
        this.onRecognizedCallbacks.forEach((callback) =>
          callback(result.text, result.privResultId)
        );
      }
    };

    this.recognizer.canceled = (s, e) => {
      log.error(`Speech recognition canceled: ${e.errorDetails}`);
      // You might want to add a callback for cancellation as well
    };

    this.recognizer.sessionStarted = (s, e) => {
      const sessionId = e.sessionId;
      log.debug(`Speech recognition session started. Session ID: ${sessionId}`);
      // You might want to add a callback for session start
      this.sdk.event().emit('speechRecognitionSessionStarted', { sessionId });
    };
  }

  async startListening() {
    if (this.isListening) return;

    try {
      await this.refreshTokenIfNeeded();
      this.isListening = true;
      this.recognizer.startContinuousRecognitionAsync(
        () => log.info('Continuous recognition started from STT Manager'),
        (error) => {
          log.error(`Error starting continuous recognition: ${error}`);
          // You might want to add a callback for errors
        }
      );
    } catch (error) {
      log.error('Failed to start listening:', error);
    }
  }

  stopListening() {
    if (!this.isListening) return;

    this.isListening = false;
    if (this.recognizer) {
      this.recognizer.stopContinuousRecognitionAsync(
        () => log.info('Continuous recognition stopped'),
        (error) => log.error(`Error stopping continuous recognition: ${error}`)
      );
    }
  }

  addOnRecognizedCallback(callback) {
    this.onRecognizedCallbacks.push(callback);
  }

  addOnRecognizingCallback(callback) {
    this.onRecognizingCallbacks.push(callback);
  }

  clearCallbacks() {
    this.onRecognizedCallbacks = [];
    this.onRecognizingCallbacks = [];
  }

  dispose() {
    this.stopListening();
    if (this.recognizer) {
      this.recognizer.close();
    }
  }

  async createTranscriptionSession(
    onPartialTranscriptedTextCallback,
    onTranscriptedTextCallback,
    isMicValidation = false,
    phraseList = []
  ) {
    await this.initialize(isMicValidation, phraseList);

    this.addOnRecognizedCallback((text, dataID) => {
      if (onTranscriptedTextCallback) onTranscriptedTextCallback(text, dataID);

      const data = { text, dataID };
      this.sdk.event().emit('transcriptedText', data); // to delete ?
    });

    this.addOnRecognizingCallback((text, dataID) => {
      if (onPartialTranscriptedTextCallback) onPartialTranscriptedTextCallback(text, dataID);

      const data = { text, dataID };
      this.sdk.event().emit('partialTranscriptedText', data); // to delete ?
    });

    return {
      start: async () => {
        await this.sdk.getSpeechToken(); // Ensure token is valid before starting
        this.startListening();
      },
      close: async () => {
        this.clearCallbacks();
        this.stopListening();
        await this.sdk.refreshSpeechTokenIfNeeded();
      }
    };
  }

  async createMicValidationSession(onSpeechDetectedCallback) {
    await this.initialize(true); // Pass true to indicate mic validation mode

    this.addOnRecognizedCallback(onSpeechDetectedCallback);
    this.addOnRecognizingCallback(onSpeechDetectedCallback);

    return {
      start: async () => {
        await this.sdk.getSpeechToken(); // Ensure token is valid before starting
        this.startListening();
      },
      close: async () => {
        this.stopListening();
        await this.sdk.refreshSpeechTokenIfNeeded();
      }
    };
  }

  async handleFakeSession(onPartialTranscriptedTextCallback, onTranscriptedTextCallback) {
    const fakeSpeech = await this.getFakeUserSpeech();

    // Simulate partial speech
    if (onPartialTranscriptedTextCallback) {
      onPartialTranscriptedTextCallback(fakeSpeech);
    }
    this.sdk.event().emit('partialTranscriptedText', { text: fakeSpeech }); // to delete ?

    // Simulate final speech after a short delay
    setTimeout(() => {
      if (onTranscriptedTextCallback) {
        onTranscriptedTextCallback(fakeSpeech);
      }
      this.sdk.event().emit('transcriptedText', { text: fakeSpeech }); // to delete ?
    }, 1000);
  }

  async getFakeUserSpeech() {
    // Implement this method to generate or retrieve fake user speech
    return 'This is a fake user speech for testing purposes.';
  }
}
