import log from 'loglevel';

export default class FakeUser {
  constructor() {}

  static async GetFakeUserSpeech(iConversation) {
    try {
      const gptAnswer = await window.sdk.fetchInternalAPI().post('/llm/fake-user-speech', {
        body: {
          contextualInfo: window.testMode.fakeUserParams.contextualInfo,
          userBehaviour: window.testMode.fakeUserParams.userBehaviour,
          conversation: iConversation,
          exerciseSessionID: window.sdk.exerciseSessionID,
          temperature: window.testMode.fakeUserParams.temperature
        }
      });
      log.debug('FakeUser.GetFakeUserSpeech: fake user speech = ', gptAnswer.fakeUserSpeech);

      return gptAnswer.fakeUserSpeech;
    } catch (error) {
      log.error(`FakeUser.GetFakeUserSpeech: ${error}`);
    }

    return null;
  }
}
