import { ExerciseSessionHistory } from './useExerciseSessionHistory';
// @ts-ignore
import { uniqBy } from '../AppClasses/Utils/uniqBy';

type RetryRunInfoParams = {
  exerciseSessionHistory: ExerciseSessionHistory;
  rerunNodeID: string;
  exerciseGraph: any;
};

function findFirstSmartBranchingDecisionEventAfterRerunNodeID({
  exerciseSessionHistory,
  rerunNodeID
}: {
  exerciseSessionHistory: ExerciseSessionHistory;
  rerunNodeID: string;
}) {
  const BRANCHING_DECISION_TYPE = 'BranchingDecision';

  const startIndex = exerciseSessionHistory.findIndex(
    (event) => event.Content.NodeID === parseInt(rerunNodeID)
  );

  for (let i = startIndex + 1; i < exerciseSessionHistory.length; i++) {
    if (exerciseSessionHistory[i].EventType === BRANCHING_DECISION_TYPE) {
      return exerciseSessionHistory[i];
    }
  }

  return null;
}

export function useRetryRunInfo({
  exerciseSessionHistory,
  rerunNodeID,
  exerciseGraph
}: RetryRunInfoParams) {
  const rerunSmartBranchingDecisionEvent = findFirstSmartBranchingDecisionEventAfterRerunNodeID({
    exerciseSessionHistory,
    rerunNodeID
  });

  if (!rerunSmartBranchingDecisionEvent) {
    return;
  }

  // Get all user actions that are available in the rerun node
  const userActionsFeedbacks = [];

  const availableUserActionsFeedbacks = exerciseGraph.GetNodeByID(
    rerunSmartBranchingDecisionEvent.Content.NodeID
  ).AvailableUserActionsFeedbacks;

  for (const userActionFeedbackID of Object.keys(availableUserActionsFeedbacks)) {
    const uaf = exerciseGraph.GetFullUserActionFeedbackData(
      userActionFeedbackID,
      rerunSmartBranchingDecisionEvent.Content.NodeID
    );
    userActionsFeedbacks.push(uaf);
  }

  // Get all detailed feedbacks that are available in the rerun node
  const detailedFeedbacks = uniqBy(userActionsFeedbacks, 'ID').filter(
    (userActionFeedback: any) =>
      userActionFeedback.Tags.includes('goodAction') &&
      !userActionFeedback.Tags.includes('limitCase') &&
      userActionFeedback.DisplayedName
  );
  //.sort((a: any, b: any) => a.PriorityRank - b.PriorityRank) // No longer needed/possible after Ua refactoring?

  // Return the detailed feedbacks and the scene node to rerun
  return {
    detailedFeedbacks,
    rerunSceneNode: exerciseGraph.GetNodeByID(
      rerunSmartBranchingDecisionEvent.Content.CurrentSceneNodeID
    )
  };
}
