import { useNavigate } from 'react-router-dom';

export const useExerciseNavigation = (exerciseID: string | undefined) => {
  const navigate = useNavigate();

  const restartExercise = () => {
    navigate(`/exercise/${exerciseID}`, { replace: false });
  };

  const quitExercise = (CustomizationValues: any) => {
    if (window.sdk.isInIframe()) {
      window.sdk.event().emit('showExitedModal', {
        ExitMessage: CustomizationValues.CompletedExercise_Text,
        ExitedButton_Visible: CustomizationValues.CompletedExerciseButton_Visible === 'true',
        ExitedButton_Text: CustomizationValues.CompletedExerciseButton_Text
      });
    } else {
      navigate('/', { replace: false });
    }
  };

  return { restartExercise, quitExercise };
};
