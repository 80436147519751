import log from 'loglevel';

export default class Fetch {
  constructor(sdk) {
    this.sdk = sdk;
    this.globalHeaders = [];
  }

  addGlobalHeader(key, value) {
    let index = Object.keys(this.globalHeaders).find(
      (headerIndex) => this.globalHeaders[headerIndex].key === key
    );
    if (typeof index !== 'undefined') {
      this.globalHeaders[index] = { key, value };
    } else {
      this.globalHeaders.push({ key, value });
    }
  }

  async run(uri, params, external) {
    this.sdk.event().emit('fetchStarted');
    if (params.needSessionValidate !== false) {
      await this.sdk.user().validateSession();
    }
    if (!params.headers) params.headers = new window.Headers();
    for (const i in this.globalHeaders) {
      params.headers.append(this.globalHeaders[i].key, this.globalHeaders[i].value);
    }
    params.headers.append('Content-Type', 'application/json');
    let response = null;
    try {
      if (external) {
        // call an external API
        response = await window.fetch(uri, params);
      } else {
        response = await window.fetch('//' + this.sdk.getApiUrl() + uri, params);
      }
      let res = await response.json();

      // if request failed, throw an error
      if (!response.ok) {
        // TODO: refactor failed request handling
        /*if (res.error) {
          throw new Error(`HTTP error! status: ${response.status}, message: ${res.error}`);
        } else {
          throw new Error(
            `HTTP error! status: ${response.status}, message: ${response.statusText}`
          );
        }*/

        log.error('Error with request:', response, uri, JSON.stringify(params));
        log.warn(
          'Error with request:',
          response,
          uri,
          JSON.stringify(params),
          '\nWe should refactor failed request handling!'
        );
      }

      if (!res) {
        return {
          state: 'error'
        };
      }

      if (res.token && res.token === 'EXPIRED') {
        this.sdk.event().emit('tokenExpired');
        return {
          state: 'error'
        };
      }
      this.sdk.event().emit('fetchFinished');
      return res;
    } catch (error) {
      if (error.name === 'AbortError') {
        log.debug('Request was aborted:', uri);
        this.sdk.event().emit('fetchAborted');
        throw error;
      } else {
        log.error('Error with request:', error, uri, JSON.stringify(params));
        this.sdk.event().emit('fetchError');
        throw error;
      }
    }
  }

  async get(uri, params = {}, external = false) {
    params.method = 'GET';
    return await this.run(uri, params, external);
  }

  async post(uri, params = {}, external = false) {
    params.method = 'POST';
    params.body = JSON.stringify(params.body);
    return await this.run(uri, params, external);
  }

  async put(uri, params = {}) {
    params.method = 'PUT';
    params.body = JSON.stringify(params.body);
    return await this.run(uri, params);
  }

  async delete(uri, params = {}) {
    params.method = 'DELETE';
    return await this.run(uri, params);
  }
}
