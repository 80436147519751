import { AvatarName } from '@UIKit/AvatarName/AvatarName';
import { EvaluationPicto } from '@UIKit/EvaluationPicto/EvaluationPicto';
import { IsUserCamActivatedContext } from '@UIKit/Stepper/ExerciseControls';
import Stepper from '@UIKit/Stepper/Stepper';
import Toasts, { ToastDataModel, ToastsContext } from '@UIKit/Toasts/Toasts';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import defs from '@/assets/new-ui/defs.svg';
import TopBar from '@/components/TopBar/TopBar';

const FADING_DURATION = 600;

const Exercise = () => {
  const [isUserCamActivated, setIsUserCamActivated]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);

  const [isUserCamFadedOut, setIsUserCamFadedOut]: [boolean, Dispatch<SetStateAction<boolean>>] =
    useState<boolean>(false);

  const [iAToasts, setIAToasts]: [ToastDataModel[], Dispatch<SetStateAction<ToastDataModel[]>>] =
    useState<ToastDataModel[]>([]);

  const [userToasts, setUserToasts]: [
    ToastDataModel[],
    Dispatch<SetStateAction<ToastDataModel[]>>
  ] = useState<ToastDataModel[]>([]);

  /*TODO: remove after test */
  const onKeydown = (event: KeyboardEvent) => {
    const uniqueId = Math.random().toString(36).substr(2, 9);
    if (event.key === 's') {
      setIAToasts((toasts) => [
        ...toasts,
        {
          id: 'TOOLTIP-' + uniqueId,
          body: (
            <>
              <EvaluationPicto evaluation="BAD" className="mr-2" /> Besoin de réassurance
            </>
          ),
          evaluation: 'BAD'
        }
      ]);
    }

    if (event.key === 'c') {
      setUserToasts((toasts) => [
        ...toasts,
        {
          id: 'TOOLTIP-' + uniqueId,
          body: (
            <>
              <EvaluationPicto evaluation="GOOD" className="mr-2" /> Rassurant
            </>
          ),
          evaluation: 'GOOD'
        }
      ]);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', onKeydown);
    return () => {
      window.removeEventListener('keydown', onKeydown);
    };
  });

  useEffect(() => {
    let timer: undefined | ReturnType<typeof setTimeout>;
    if (isUserCamActivated) {
      setIsUserCamFadedOut(false);
      timer = setTimeout(() => {
        setIsUserCamFadedOut(true);
      }, FADING_DURATION);
    } else {
      setIsUserCamFadedOut(false);
      clearTimeout(timer);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [isUserCamActivated]);

  return (
    <IsUserCamActivatedContext.Provider value={{ isUserCamActivated, setIsUserCamActivated }}>
      <div className="flex min-h-screen flex-col">
        <TopBar className="w-full" />
        <div className="centered-wide-row flex w-full flex-1 flex-col py-8">
          <div className="relative mx-6 flex-1">
            <div className="absolute left-0 top-0 size-full overflow-hidden rounded-2.5xl bg-white">
              <img
                src="https://placebear.com/g/2800/1600"
                width={2800}
                height={1600}
                className="h-full w-auto min-w-full object-cover"
              />
              <AvatarName className="absolute left-4 top-4">Sophie</AvatarName>
            </div>

            <ToastsContext.Provider value={{ toasts: iAToasts, setToasts: setIAToasts }}>
              <Toasts
                className="absolute left-1/2 top-4 -translate-x-1/2"
                originSide="BOTTOM"
                translateY="BOTTOM_TO_BOTTOM"
              />
            </ToastsContext.Provider>

            <div className="absolute -bottom-6 -left-6 h-32 w-56">
              <div className={`relative size-full overflow-hidden rounded-2.5xl bg-accent`}>
                <svg
                  width="126"
                  height="96"
                  viewBox="0 0 126 96"
                  className="absolute left-1/2 top-1/2 h-auto w-19 -translate-x-1/2 -translate-y-1/2 fill-brand"
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false">
                  <use href={`${defs}#watermark`} />
                </svg>

                <p
                  className={`${
                    isUserCamFadedOut
                      ? 'hidden'
                      : '[animation-duration:.2s] ' +
                        (isUserCamActivated ? 'animate-fadeOut' : 'animate-fadeIn')
                  }`}>
                  <img
                    src="https://placebear.com/g/444/250"
                    width={444}
                    height={250}
                    className="absolute left-1/2 top-0 block h-full w-auto min-w-full -translate-x-1/2 object-cover"
                  />
                  <AvatarName className="absolute bottom-2 left-2">Camille</AvatarName>
                </p>
              </div>
              <ToastsContext.Provider value={{ toasts: userToasts, setToasts: setUserToasts }}>
                <Toasts
                  className="absolute left-full top-1/2 ml-4 -translate-y-1/2"
                  originSide="LEFT"
                  translateY="BOTTOM_TO_TOP"
                />
              </ToastsContext.Provider>
            </div>
          </div>

          <Stepper className="mt-8 w-full sm:px-6" isExerciseRunning={true} />
        </div>
      </div>
    </IsUserCamActivatedContext.Provider>
  );
};

export default Exercise;
