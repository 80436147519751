import React from 'react';
import log from 'loglevel';
import '@/styles/components/Welcome.scss';
import {
  faArrowRight,
  faCheck,
  faMasksTheater,
  faPlay,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@tolgee/react';
import Accordion from '@UIKit/Accordion/Accordion';
import { useNavigate } from 'react-router-dom';
import TopBar from '@/components/TopBar/TopBar';
import { Tooltip } from '@/components/Utilities/Tooltip';
import { Translation } from '@/components/Utilities/Translation';

const ExerciseStatus = ({ status }) => {
  let icon = null;
  let title = '';
  switch (status) {
    case 'todo':
      icon = faArrowRight;
      title = <Translation keyName="welcome.todo">A faire</Translation>;
      break;
    case 'progress':
      icon = faPlay;
      title = <Translation keyName="welcome.progress">En cours</Translation>;
      break;
    case 'done':
      icon = faCheck;
      title = <Translation keyName="welcome.done">Terminé</Translation>;
      break;
  }
  if (!icon) {
    return null;
  }
  return (
    <div className={'exercise-status exercise-status--' + status}>
      <Tooltip title={title} placement={'top'}>
        <FontAwesomeIcon icon={icon} />
      </Tooltip>
    </div>
  );
};

const ExerciseCategory = ({ category, ...props }) => {
  const { name } = category;
  return (
    <div className={'exercise-category'} {...props}>
      <FontAwesomeIcon icon={faMasksTheater} className={'exercise-category__icon'} />
      <p className={'exercise-category__name'}>{name}</p>
    </div>
  );
};

const ExerciseItem = ({ exercise, id }) => {
  const navigate = useNavigate();
  const { t } = useTranslate();
  const { title, image, level, status, replay, disabled, category } = exercise;

  // Si clic sur replay, on redirige vers replay même si exercise desactivé
  const onClick = (e, exercise) => {
    e.stopPropagation();

    // If replay button
    if (e.currentTarget.classList.contains('item__replay_link')) {
      return navigate(exercise.replay, { replace: false });
    }

    // If disabled exercise line
    if (exercise.disabled) {
      window.sdk.event().emit('modalOpen', {
        content: (
          <>
            <h2>Exercise désactivé</h2>
            <p>
              <Translation keyName="welcome.ex">L'exercise</Translation>{' '}
              <strong>"{exercise.title}"</strong>
              <Translation keyName="welcome.deactivated">est pour le moment désactivé.</Translation>
            </p>
          </>
        )
      });
      return false;
    }

    // If this exercise line is ok, start the exercise
    navigate('/exercise/' + exercise.id, { replace: false });
  };

  return (
    <li id={id} className={'exercise-item ' + (disabled ? 'exercise-item--disabled' : '')}>
      <button className="cta cta--accent w-full text-left" onClick={(e) => onClick(e, exercise)}>
        <div className={'item__level item__level--' + level}>
          <div className={'item__level_inner'}>
            <Translation keyName="general.level">Niveau</Translation> {level}
          </div>
        </div>
        {image && (
          <div className={'item__image'}>
            <img className={'img-fluid'} src={image} alt={title} />
          </div>
        )}
        <div className={'item__description'}>
          <h3 className={'item__title'}>{title}</h3>
          <div className={'item__category'}>
            <ExerciseCategory category={category} />
          </div>
        </div>
        {replay && (
          <div className={'item__replay'}>
            <Tooltip title={t('welcome.replay', 'Accéder au replay')} placement={'top'}>
              <span className={'item__replay_link'} onClick={(e) => onClick(e, exercise)}>
                <FontAwesomeIcon icon={faVideo} />
              </span>
            </Tooltip>
          </div>
        )}
      </button>
      <div className={'item__status'}>
        <ExerciseStatus status={status} />
      </div>
    </li>
  );
};

class Welcome extends React.Component {
  state = {
    progress: {},
    loaded: false,
    showNothing: false
  };

  async componentDidMount() {
    if (window.sdk.refreshingExercise) {
      this.setState({
        showNothing: true
      });
      return;
    }

    //this.playSound();
    let data = await window.sdk.exercise().getAll();
    log.debug('Refreshed exercises list, data = ', data);
    this.setState({
      exercises: data.exercises,
      progress: data.progress,
      loaded: true
    });
  }

  componentWillUnmount() {}

  render() {
    if (!this.state.loaded || this.state.showNothing) return null; //show loader
    const sections = this.state.exercises;
    return (
      <>
        <TopBar className="sticky left-0 top-0 z-50 w-full" />
        <div className="dashboard legacy">
          <div className="centered-row min-h-[calc(100vh-theme(spacing.12))] py-8">
            <h1 className="mb-4 title" data-testid="welcome-dashboard-title">
              <Translation keyName="welcome.choice">Choix d'exercice</Translation>
            </h1>

            {sections.map(({ title, exercises }, i) => (
              <Accordion id={'section-' + i} key={'section-' + i}>
                <Accordion.CTALabel className="border-t py-6 pr-[calc(18px+theme(spacing.4))] font-semibold after:right-0">
                  {title}
                </Accordion.CTALabel>
                <ul>
                  {exercises.map((exercise) => (
                    <React.Fragment key={'exercise-' + exercise.id}>
                      <ExerciseItem
                        id={'exerciseMenuExercise-' + exercise.id}
                        exercise={exercise}
                      />
                    </React.Fragment>
                  ))}
                </ul>
              </Accordion>
            ))}
          </div>
        </div>
      </>
    );
  }
}

export default Welcome;
