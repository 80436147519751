import React from 'react';
import FirstRun from './ScenarioIntro/FirstRun';
import RetryRun from './ScenarioIntro/RetryRun';
import '@/styles/components/scenario/Intro.scss';

const Intro = () => {
  if (window.sdk.getParam('run') === 'retry') return <RetryRun />;
  return <FirstRun />;
};

export default Intro;
