import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslate } from '@tolgee/react';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import { LinkContainer } from 'react-router-bootstrap';
import { useLocation } from 'react-router-dom';
import { PasswordValidationIndicator } from '@/components/Utilities/PasswordValidationIndicator';
import { Translation } from '@/components/Utilities/Translation';

const Login = (props) => {
  const location = useLocation();
  const { t } = useTranslate();

  const [uname] = useState(location.state ? location.state.email : '');
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [failed, setFailed] = useState(false);
  const [info, setInfo] = useState({});
  const [valided, setValided] = useState({
    number: false,
    special: false,
    length: false,
    min: false,
    maj: false,
    email: true,
    password: true
  });

  const submit = async () => {
    if (!uname || !valided.email) {
      setValided((prev) => ({ ...prev, email: false }));
      return false;
    }
    if (!password || !valided.number || !valided.special || !valided.min || !valided.maj) {
      setValided((prev) => ({ ...prev, password: false }));
      return false;
    }

    let res;
    try {
      res = await window.sdk.user().login({
        username: uname,
        password: password,
        newpassword: password,
        name: uname,
        redirect_url: location.state.redirect_url
      });
    } catch (e) {
      setFailed(true);
      setInfo({
        message: <Translation keyName="login.connection_error">Erreur connexion</Translation>
      });
      window.sdk.event().emit('loaderHide');
    }

    if (res && res.state === 'ERROR') {
      setFailed(true);
      setInfo(res.error);
    }
  };

  const updatePassword = (value) => {
    setValided((prev) => ({
      ...prev,
      password: true,
      number: value.search(/.*\d/) >= 0,
      special: /[^a-zA-Z0-9]/.test(value),
      length: value.length >= 8,
      min: value.search(/.*[a-z]/) >= 0,
      maj: value.search(/.*[A-Z]/) >= 0
    }));
    setPassword(value);
    setFailed(false);
  };

  const togglePassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <h2 className="title">
        <Translation keyName="login.welcome">
          Bienvenue, connectez-vous pour accéder à vos simulations
        </Translation>
      </h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        {failed && (
          <Alert className="alert mt-4" variant="danger">
            {info.message}
          </Alert>
        )}

        <Form.Group className="mt-4" controlId="email">
          <Form.Label className="pl-4 label">
            <Translation keyName="general.email">Email</Translation>
          </Form.Label>
          <p className="flex items-center justify-between pl-4">
            <i>{uname}</i>
            <LinkContainer to="/">
              <a className="link text-xs">
                <Translation keyName="general.edit">Modifier</Translation>
              </a>
            </LinkContainer>
          </p>
        </Form.Group>

        <Form.Group className="mt-4" controlId="password">
          <Form.Label className="pl-4 label">
            <Translation keyName="general.password">Mot de passe</Translation>
          </Form.Label>
          <div className="relative [&_.form-control]:pr-10">
            <Form.Control
              data-testid="login-password-input"
              type={showPassword ? 'text' : 'password'}
              name="password"
              autoComplete="current-password"
              placeholder={t('login.your_password', 'Votre mot de passe')}
              onChange={(e) => updatePassword(e.target.value)}
              value={password}
              isInvalid={!valided.password}
              required
            />
            <button
              type="button"
              className="absolute right-4 top-1/2 -translate-y-1/2"
              onClick={togglePassword}>
              <FontAwesomeIcon icon={!showPassword ? faEye : faEyeSlash} />
            </button>
          </div>
          <PasswordValidationIndicator className="mt-3" valided={valided} />
        </Form.Group>

        <button
          className="cta cta--accent mt-4"
          data-testid="login-submit-btn"
          type="submit"
          disabled={props.loading}
          onClick={submit}>
          <Translation keyName="general.login">Connexion</Translation>
        </button>

        <p className="mt-4">
          <LinkContainer to="/password-lost" state={{ email: uname }}>
            <a className="link text-xs" data-testid="forgot-password-button">
              <Translation keyName="login.forgot_pwd">Mot de passe oublié ?</Translation>
            </a>
          </LinkContainer>
        </p>
      </form>
    </>
  );
};

export default Login;
