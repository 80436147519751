export default class ExercisesAPIEndpoints {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async init() {}

  async getOne(exerciseID) {
    await new Promise((r) => setTimeout(r, 1)); // TODO: remove this artificial timer to allow us to hardcode the value, this is only related to S1
    return {
      branchingdecisionapi: this.sdk.getMlApiUrl() + '/ml/GenAIAPI',
      useractionsdetectionapi: this.sdk.getMlApiUrl() + '/ml/UsersActionDetectionAPI/input'
    };
  }
}
