import Utils from '../../AppClasses/Utils/Utils';

export default class AnalysisTask {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async init() {}

  async createOne(
    iBranchingDecisionID,
    iNodeID,
    iAnalyzerEngine,
    iAnalyzerVersion,
    iAnalysisStatus,
    iAnalysisInput,
    iStartTime,
    iAnalysisDuration,
    iPossibleChoices,
    iAnalysisResult,
    iExerciseID
  ) {
    const analysisInput =
      typeof iAnalysisInput === 'string' ? iAnalysisInput : JSON.stringify(iAnalysisInput);
    const analysisResult =
      typeof iAnalysisResult === 'string' ? iAnalysisResult : JSON.stringify(iAnalysisResult);

    let body = {
      BranchingDecisionID: iBranchingDecisionID,
      ID: Utils.CreateObjectIDWithIncrement(iStartTime, window.sdk.user().userID, iAnalyzerEngine),
      NodeID: iNodeID,
      AnalyzerEngine: iAnalyzerEngine,
      AnalyzerVersion: iAnalyzerVersion,
      AnalysisStatus: iAnalysisStatus,
      AnalysisInput: analysisInput,
      StartTime: iStartTime,
      AnalysisDuration: iAnalysisDuration,
      PossibleChoices: iPossibleChoices,
      AnalysisResult: analysisResult,
      ExerciseID: iExerciseID
    };
    let res = await this.sdk.fetchInternalAPI().post('/analysis-task', { body });

    return res;
  }

  async updateOne(iBranchingDecisionID, iID, iUpdateObject) {
    let body = {
      BranchingDecisionID: iBranchingDecisionID,
      ID: iID,
      ...iUpdateObject // Add all the properties of the object to the body
    };

    let res = await this.sdk.fetchInternalAPI().post('/analysis-task/update', { body });

    return res;
  }
}
