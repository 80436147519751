import React from 'react';
import allowCookieGif from '@/assets/images/allow_cookie.gif';
import { Translation } from '@/components/Utilities/Translation';

const AllowCookies = (props: { onSkip: () => void; onRefresh: () => void }) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="centered-row text-center">
        <h1 className="mx-auto max-w-half-row title">
          <Translation keyName="app.cookies">
            Autorisez les cookies pour lancer Practicio.
          </Translation>
        </h1>
        <p className="mx-auto mt-4 max-w-half-row">
          <Translation keyName="app.problem">En cas de problème, merci de contacter</Translation>{' '}
          <a className="link" href="mailto:support@practicio.fr">
            support@practicio.fr
          </a>
        </p>
        <img className="my-6 block" src={allowCookieGif} />
        <input
          className="hidden"
          type="text"
          value=""
          name="skip-cookieDisabledModal"
          onChange={props.onSkip}
        />
        <button className="cta cta--accent" onClick={props.onRefresh}>
          <Translation keyName="general.refresh">Rafraichir</Translation>
        </button>
      </div>
    </div>
  );
};

export default AllowCookies;
