import BaseNode from './BaseNode';
import NodePort from '../NodePort';

export default class ValueString extends BaseNode {
  // Ports
  Set = new NodePort('Set', 'input', this);
  Get = new NodePort('Get', 'output', this);

  // Parameters
  ValueName = '';

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.ValueName = iProperties.ValueName;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", name = " + this.ValueName);
  }

  SetValue(iValue) {
    this.Graph.SetStringValue(this.ValueName, iValue);
  }

  GetValue() {
    return this.Graph.GetStringValue(this.ValueName);
  }
}
