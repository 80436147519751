import { DEFAULT_LANGUAGE } from '../../helpers/getTolgeeInstance';
export default class Exercise {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async init() {}

  async getAll() {
    let res = await this.sdk.fetchInternalAPI().get('/exercises');
    return res;
  }

  async downloadExerciseGraph(iExerciseID) {
    const lang = window.sdk.getLanguage();
    if (lang !== DEFAULT_LANGUAGE) {
      iExerciseID += `?lang=${lang}`;
    }
    let res = await this.sdk.fetchInternalAPI().get('/exercises/' + iExerciseID);
    if (res.statusCode === 403) {
      return null;
    }
    return res;
  }

  async preloadExerciseAssets(exerciseGraph, cacheProgression) {
    // Get the list of files to preload
    const videosToPreload = exerciseGraph.VideosToPreload;

    // Start the bots videos cache pre-loading, and update the progression
    // Note that, we do not await the end of the preloading,
    // as we want to keep the user free to continue on the brief steps during preloading
    window.sdk.cacheManager().preload(videosToPreload, (progressionInPercent) => {
      cacheProgression(progressionInPercent);
    });
  }
}
