export function simulateWheelScroll(deltaY: number) {
  (window as any).setSimulatedScroll();
  const wheelEvent = new WheelEvent('wheel', {
    deltaY: deltaY,
    bubbles: true,
    cancelable: true,
    view: window
  });
  window.dispatchEvent(wheelEvent);
}
