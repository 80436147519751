/* global */
import React, { useContext } from 'react';
import { Translation } from '@/components/Utilities/Translation';
import { ExerciseContext } from '../../pages/Exercise/ExerciseContext';
import BottomBar from '../VisioExerciceBottomBar/VisioExerciceBottomBar';

const CamUsed = () => {
  const { ExerciseGraph } = useContext(ExerciseContext);
  const refresh = () => {
    window.location.reload();
  };

  return (
    <>
      <h2 className="mx-auto max-w-row text-center title">
        <Translation keyName="camUsed.prompt">
          Il semblerait que votre webcam soit utilisée par un autre logiciel (skype, teams, zoom ou
          un autre navigateur) ou qu'elle soit désactivée
        </Translation>
      </h2>
      <p className="mx-auto mt-4 max-w-row text-center text-base">
        <Translation keyName="camUsed.please_check">
          Merci de vérifier et cliquer sur le bouton "Rafraîchir".
        </Translation>
      </p>

      <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'authorization'}>
        <button className="cta cta--accent" onClick={refresh}>
          <Translation keyName="general.refresh">Rafraîchir</Translation>
        </button>
      </BottomBar>
    </>
  );
};

export default CamUsed;
