import React from 'react';
import ParticipantsModule from '@/AppClasses/Participants/ParticipantsModule';
import Bot from '../Bot';
import LocalUser from '../LocalUser';

const OneToOne = () => {
  // Add the human video slot to participants videos list
  let participantsVideos = ParticipantsModule.Instance.m_Humans.map((participant) => (
    <React.Fragment key={participant.ID}>
      <LocalUser human={participant} />
    </React.Fragment>
  ));

  let Videos = ParticipantsModule.Instance.m_Bots.map((participant) => (
    <div className="relative mx-auto w-4/5" key={participant.ID}>
      <div className="pl-6 [&_.name]:bottom-auto [&_.name]:top-4">
        <Bot bot={participant} />
      </div>
      <div className="absolute -bottom-6 z-10 w-1/3">{participantsVideos}</div>
    </div>
  ));

  return <>{Videos}</>;
};

export default OneToOne;
