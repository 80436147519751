export default class BotVideo {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async init() {}

  async getOne(videoID) {
    let res = await this.sdk.fetchInternalAPI().get('/bot-video/' + videoID);
    return res;
  }
}
