/* eslint-disable */
import Accordion from '@UIKit/Accordion/Accordion';
import Card from '@UIKit/Card/Card';
import React, { createRef, useRef } from 'react';
import { Translation } from '@/components/Utilities/Translation';
import AchievementIcon from './AchievementIcon';
import '@/styles/components/Achievement.scss';

const Scenario1Achievement = ({
  text,
  actions,
  classAchievement
}: {
  text: any;
  actions: any;
  classAchievement: string;
}) => {
  const siblingRefs = useRef<any[] | (typeof Accordion)[]>([]);
  siblingRefs.current = actions.map((a: any, i: number) => siblingRefs.current[i] ?? createRef());

  const closeSiblings = (activeIndex: number) => {
    siblingRefs.current.forEach((ref, i: number) => {
      if (i !== activeIndex) ref.current.close();
    });
  };

  return (
    <div className={'feedback-step-achievements ' + (classAchievement || '')}>
      {text.AchievementsTitle_Text ? (
        <h1
          className="text-center title"
          dangerouslySetInnerHTML={{ __html: text.AchievementsTitle_Text }}
        />
      ) : (
        <h1 className="text-center title">
          <Translation keyName="s1.discover_badge">
            Découvrez les badges que vos actions ont débloqués
          </Translation>
        </h1>
      )}

      {actions.length <= 0 && (
        <p className="text-center text-base">
          <Translation keyName="s1.no_badge">
            Vous n'avez pas obtenu de badge de réaction durant cette session.
          </Translation>
        </p>
      )}
      {actions.length > 0 &&
        actions.map((action: any, i: number) => {
          if (!action.Type.startsWith('Ending'))
            return (
              <Card className="mt-4" key={action.ID}>
                <Accordion
                  id={action.ID}
                  ref={siblingRefs.current[i]}
                  openingCallback={() => closeSiblings(i)}>
                  <Accordion.CTALabel className="flex items-center pr-[calc(18px+theme(spacing.4))] font-semibold after:right-0">
                    <div className="mr-4 h-auto w-10">
                      <AchievementIcon achievement={action} name={'achievement-' + i} />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: action.DisplayedName }} />
                    <span className="ml-auto text-sm max-sm:hidden">En savoir plus</span>
                  </Accordion.CTALabel>
                  <div
                    className="feedback-ending-desc mt-4 p-0"
                    dangerouslySetInnerHTML={{ __html: action.Description }}></div>
                </Accordion>
              </Card>
            );
        })}
    </div>
  );
};
export default Scenario1Achievement;
