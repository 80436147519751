/* global */
import React, { useContext } from 'react';
import { Translation } from '@/components/Utilities/Translation';
import { ExerciseContext } from '../../pages/Exercise/ExerciseContext';
import BottomBar from '../VisioExerciceBottomBar/VisioExerciceBottomBar';

const Refuse = (props) => {
  const { ExerciseGraph, setStep } = useContext(ExerciseContext);
  const refresh = () => {
    if (props.showTutorial) props.showTutorial(true);
    else setStep('authorization');
  };
  const exit = () => {
    window.sdk.event().emit('exitApp');
  };
  return (
    <>
      <h2 className="mx-auto max-w-row text-center title">
        <Translation keyName="refuse.cam_mic">
          Il semble que Practicio n'ait pas accès à votre caméra et/ou votre micro.
        </Translation>
      </h2>
      <p className="mx-auto max-w-row text-center text-base">
        <Translation keyName="refuse.prereq">
          Ces accès sont des prérequis pour participer à l'expérience Practicio.
        </Translation>
      </p>

      <BottomBar ExerciseGraph={ExerciseGraph} played={false} step={'authorization'}>
        <button className="cta cta--white" onClick={exit}>
          <Translation keyName="general.quit">Quitter</Translation>
        </button>
        <button className="cta cta--accent" onClick={refresh}>
          <Translation keyName="refuse.configure_auth">Configurer les autorisations</Translation>
        </button>
      </BottomBar>
    </>
  );
};

export default Refuse;
