import log from 'loglevel';
import * as Sentry from '@sentry/browser';

export default class AbstractSolver {
  Graph = null;
  Logger = log;

  constructor(iGraph) {
    this.Graph = iGraph;
  }

  HandleError(iErrorMessage) {
    this.Logger.error(iErrorMessage);
    Sentry.captureMessage(iErrorMessage);
  }
}
