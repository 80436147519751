import log from 'loglevel';
import { useEffect, useState } from 'react';

export type ExerciseSession = {
  UserID: { S: string };
  ActivatedBranchingDecisionsCount: { S: string };
  AppVersion: { S: string };
  ExerciseID: { S: string };
  ExerciseVersion: { S: string };
  ID: { S: string };
  Month: { S: string };
  StartTime: { S: string };
};

export type ExerciseTrophy = {
  ID: string;
  Type: string;
  DisplayedName: string;
  Description: string;
  Condition: string;
};

export type Exercise = {
  Name: string;
  ID: number;
  Category: string;
  ScenarioID: string;
  Version: string;
  AvailableTrophies: ExerciseTrophy[];
  CustomizationValues: any;
};

export function useExerciseSession(
  exerciseID: string | undefined,
  exerciseSessionID: string | undefined
) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [session, setSession] = useState<ExerciseSession | null>(null);
  const [exercise, setExercise] = useState<Exercise | null>(null);

  useEffect(() => {
    const fetchSession = async () => {
      if (!exerciseSessionID) {
        setIsLoading(false);
        return;
      }

      try {
        const data = await window.sdk
          .exerciseSession()
          .getOneWithExercise(exerciseID, exerciseSessionID);
        setExercise(data.exercise);
        setSession(data.exerciseSession);
      } catch (error: any) {
        setErrorMessage(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSession();
  }, [exerciseID, exerciseSessionID]);

  return { session, exercise, isLoading, errorMessage };
}
