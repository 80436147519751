import log from 'loglevel';
import React, { createContext, useState } from 'react';
import ExerciseGraphClass from '../../AppClasses/ExerciseScenario/ExerciseGraph';

export const ExerciseContext = createContext({});

export const ExerciseContextProvider = ({ children, idExercise, idExerciseSession }) => {
  const [jsonGraph, _setJsonGraph] = useState({});
  const [ExerciseID, setExerciseID] = useState(idExercise ? idExercise : '');
  const [ExerciseSessionID, setExerciseSessionID] = useState(
    idExerciseSession ? idExerciseSession : ''
  );
  const [ExerciseGraph, setExerciseGraph] = useState('');
  const [step, setStep] = useState('');
  const [tryStart, setTryStart] = useState(0);
  const [cache, setCache] = useState({
    currentFile: 'waiting size informations',
    currentFileProgression: 'waiting size informations',
    totalProgression: 0
  });
  const [exerciseStarted, setExerciseStarted] = useState(false);
  const [ExerciseSession, setExerciseSession] = useState('');
  const [ExerciseSessionHistory, setExerciseSessionHistory] = useState('');
  const [RerunNodeID, setRerunNodeID] = useState(null);

  const LoadExerciseGraph = async () => {
    let graph = await window.sdk.exercise().downloadExerciseGraph(ExerciseID);

    if (!graph) {
      return undefined;
    }

    if (RerunNodeID && ExerciseSessionID) {
      const exerciseSessionHistory = await window.sdk
        .exerciseSession()
        .getExerciseSessionHistory(ExerciseSessionID);

      setExerciseSessionHistory(exerciseSessionHistory);

      const historyAfterLastRewind = getHistoryAfterLastRewind(exerciseSessionHistory);
      const initialHistory = getHistoryBefore(RerunNodeID, historyAfterLastRewind);
      const lastUnlockedTrophiesToDisplayEvents =
        getLastUnlockedTrophiesToDisplayEvent(exerciseSessionHistory);

      if (lastUnlockedTrophiesToDisplayEvents) {
        initialHistory.push(lastUnlockedTrophiesToDisplayEvents);
      }

      setJsonGraph(graph, initialHistory);
      return graph;
    }

    setJsonGraph(graph);
    return graph;
  };

  const getHistoryAfterLastRewind = (history) => {
    let lastIndex = -1;
    for (let i = 0; i < history.length; i++) {
      if (history[i].EventType === 'Rewind') {
        lastIndex = i;
      }
    }

    return lastIndex !== -1 ? history.slice(lastIndex + 1) : history;
  };

  const getHistoryBefore = (rerunNodeID, history) => {
    let targetNodeIndex = history.findIndex(
      (event) => event.Content.NodeID === parseInt(rerunNodeID)
    );

    if (targetNodeIndex === -1) {
      return [];
    }

    return history.slice(0, targetNodeIndex);
  };

  const getLastUnlockedTrophiesToDisplayEvent = (history) => {
    let lastUnlockedTrophiesToDisplayEvent = null;

    for (let i = history.length - 1; i >= 0; i--) {
      if (history[i].EventType === 'UnlockedTrophiesToDisplay') {
        lastUnlockedTrophiesToDisplayEvent = history[i];
        break;
      }
    }

    return lastUnlockedTrophiesToDisplayEvent;
  };

  const setJsonGraph = (graph, initialHistory = null) => {
    _setJsonGraph(graph);

    if (graph.state !== 'fail') {
      const exerciseGraph =
        RerunNodeID && initialHistory
          ? new ExerciseGraphClass(graph, initialHistory, ExerciseSessionID, RerunNodeID)
          : new ExerciseGraphClass(graph);

      setExerciseGraph(exerciseGraph);
    }
    window.sdk.event().emit('ExerciseLoaded');
  };

  const setDebugInfo = (iID, iValue) => {
    window.sdk.event().emit('addDebugInfo', { iID, iValue });
  };

  const showHelpBtn = () => {
    if (window.zE) {
      window.zE('webWidget', 'prefill', {
        name: {
          value: window.sdk.user().firstName + ' ' + window.sdk.user().lastName,
          readOnly: true // optional
        },
        email: {
          value: window.sdk.user().email,
          readOnly: true // optional
        }
      });
      window.zE('webWidget', 'show');

      window.zE('webWidget:on', 'close', function () {
        window.zE('webWidget', 'show');
      });
    }
  };

  const getTrophy = () => {
    if (!jsonGraph.AvailableTrophies || jsonGraph.AvailableTrophies.length === 0) return {};

    let trophies = Object.values(jsonGraph.AvailableTrophies);
    if (ExerciseSessionHistory && ExerciseSessionHistory.length > 0) {
      let sessionTrophiesID = ExerciseSessionHistory.filter(
        (item) => item.EventType && item.EventType === 'UnlockedTrophiesToDisplay'
      ).flatMap((item) => item.Content.UnlockedTrophies.map((trophy) => trophy.ID));

      trophies.map((trophy) => {
        trophy.unlocked = sessionTrophiesID.includes(trophy.ID);
        return trophy;
      });
    }
    log.debug(trophies);
    return trophies;
  };

  return (
    <ExerciseContext.Provider
      value={{
        jsonGraph,
        setJsonGraph,
        LoadExerciseGraph,
        ExerciseID,
        setExerciseID,
        ExerciseGraph,
        setDebugInfo,
        step,
        setStep,
        showHelpBtn,
        setTryStart,
        tryStart,
        cache,
        setCache,
        exerciseStarted,
        setExerciseStarted,
        ExerciseSessionID,
        setExerciseSessionID,
        ExerciseSession,
        setExerciseSession,
        ExerciseSessionHistory,
        setExerciseSessionHistory,
        RerunNodeID,
        setRerunNodeID,
        getTrophy
      }}>
      {children}
    </ExerciseContext.Provider>
  );
};
