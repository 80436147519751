export const USER_ACTION_TAGS = {
  BAD_ACTION: 'badAction',
  GOOD_ACTION: 'goodAction',
  LIMIT_CASE: 'limitCase',
  TECHNICAL_ACTION: 'technicalAction',
  NEUTRAL_ACTION: 'neutralAction',
  FEATURED_ACTION: 'featuredAction'
};

export const STOP_TYPES = {
  FAILED: 'Failed',
  SUCCEEDEED: 'Succeeded'
};

export const DETAILED_FEEDBACKS_LOTTIE_NAMES = {
  BAD_ACTION: 'Triste',
  GOOD_ACTION: 'Content',
  MISSED_OPPORTUNITY: 'Blase'
};
