import React, { ReactNode } from 'react';

export const AvatarName = ({
  className = '',
  children
}: {
  className?: string;
  children: ReactNode;
}) => (
  <span className={`${className} rounded-sm-md bg-white px-2 py-1 text-xxs transition-colors`}>
    {children}
  </span>
);
