import React from 'react';
import ParticipantsModule from '@/AppClasses/Participants/ParticipantsModule';
import Bot from '../Bot';
import LocalUser from '../LocalUser';

const Mosaique = () => {
  let participantsVideos = ParticipantsModule.Instance.m_Bots.map((participant) => (
    <li key={participant.ID} className="m-3 [&_.wrapper]:w-[426px]">
      <Bot bot={participant} />
    </li>
  ));

  ParticipantsModule.Instance.m_Humans.map((participant) =>
    participantsVideos.push(
      <li key={participant.ID} className="m-3 [&_.wrapper]:w-[426px]">
        <LocalUser human={participant} />
      </li>
    )
  );
  return <ul className="-m-3 flex flex-wrap justify-center pl-0">{participantsVideos}</ul>;
};

export default Mosaique;
