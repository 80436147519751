import * as Sentry from '@sentry/browser';
import log from 'loglevel';
import seedrandom from 'seedrandom';
import ParticipantsModule from '../Participants/ParticipantsModule';
import Utils from '../Utils/Utils';
import CustomModelsBranchingDecision from './BranchingDecisionModes/CustomModelsBranchingDecision';
import PromptBranchingDecision from './BranchingDecisionModes/PromptBranchingDecision';
import Act from './ExerciseNodes/Act';
import ActionButton from './ExerciseNodes/ActionButton';
import AppEvent from './ExerciseNodes/AppEvent';
import BadWordDetector from './ExerciseNodes/BadWordDetector';
import BotConnection from './ExerciseNodes/BotConnection';
import BotDisconnection from './ExerciseNodes/BotDisconnection';
import BotRandomVideo from './ExerciseNodes/BotRandomVideo';
import BotVideo from './ExerciseNodes/BotVideo';
import BranchingDecision from './ExerciseNodes/BranchingDecision';
import Deactivate from './ExerciseNodes/Deactivate';
import Delay from './ExerciseNodes/Delay';
import DynamicPanel from './ExerciseNodes/DynamicPanel';
import ExerciseNode from './ExerciseNodes/ExerciseNode';
import GoOffstage from './ExerciseNodes/GoOffstage';
import GoOnstage from './ExerciseNodes/GoOnstage';
import GoOnstageBriefing from './ExerciseNodes/GoOnstageBriefing';
import GoToButton from './ExerciseNodes/GoToButton';
import GPT3BoolQuestion from './ExerciseNodes/GPT3BoolQuestion';
import GPT3Classifier from './ExerciseNodes/GPT3Classifier';
import GPTChoice from './ExerciseNodes/GPTChoice';
import GroupPaths from './ExerciseNodes/GroupPaths';
import If from './ExerciseNodes/If';
import NarrativeSolver from './ExerciseNodes/NarrativeSolver';
import RaiseHandButton from './ExerciseNodes/RaiseHandButton';
import RandomBranch from './ExerciseNodes/RandomBranch';
import ScenarioRoot from './ExerciseNodes/ScenarioRoot';
import Scene from './ExerciseNodes/Scene';
import SemanticSearch from './ExerciseNodes/SemanticSearch';
import SetBool from './ExerciseNodes/SetBool';
import SetInt from './ExerciseNodes/SetInt';
import SmartBranchingDecision from './ExerciseNodes/SmartBranchingDecision';
import SpeechToText from './ExerciseNodes/SpeechToText';
import StatusChange from './ExerciseNodes/StatusChange';
import StopExercise from './ExerciseNodes/StopExercise';
import ToWhomDetector from './ExerciseNodes/ToWhomDetector';
import TrackedEvent from './ExerciseNodes/TrackedEvent';
import UserAction from './ExerciseNodes/UserAction';
import ValueBool from './ExerciseNodes/ValueBool';
import ValueInt from './ExerciseNodes/ValueInt';
import ValueString from './ExerciseNodes/ValueString';
import WaitForAll from './ExerciseNodes/WaitForAll';
import WordDetector from './ExerciseNodes/WordDetector';
import ExerciseSessionHistory from './ExerciseSessionHistory';
import AchievementsSolver from './Solvers/AchievementsSolver';
import FeedbackSolver from './Solvers/FeedbackSolver';
import TrophiesSolver from './Solvers/TrophiesSolver';
import DetailedFeedbacksSolver from './Solvers/DetailedFeedbacksSolver';
import StatsFeedbacksSolver from './Solvers/StatsFeedbacksSolver';

export default class ExerciseGraph {
  // References
  //ParentExerciseComponent = null;

  // Parameters
  FrameDuration = 1000 / 30; // Running at 30 fps

  // Graph content
  ExerciseName;
  ExerciseID;
  ScenarioID;
  RerunNodeID = null;
  UserName = 'Camille';
  Nodes;
  BoolValues = {};
  IntValues = {};
  StringValues = {};
  VideosToPreload = [];
  APIsEndpoints = {};

  // Types
  Type = 'ExerciseGraph';
  NodeTypes = {
    ScenarioRoot: ScenarioRoot,
    StopExercise: StopExercise,
    Act: Act,
    Scene: Scene,
    DynamicPanel: DynamicPanel,
    BranchingDecision: BranchingDecision,
    SmartBranchingDecision: SmartBranchingDecision,
    CustomModelsBranchingDecision: CustomModelsBranchingDecision,
    PromptBranchingDecision: PromptBranchingDecision,
    Delay: Delay,
    GroupPaths: GroupPaths,
    WaitForAll: WaitForAll,
    RandomBranch: RandomBranch,
    Deactivate: Deactivate,
    StatusChange: StatusChange,
    BotConnection: BotConnection,
    BotDisconnection: BotDisconnection,
    BotVideo: BotVideo,
    BotRandomVideo: BotRandomVideo,
    SpeechToText: SpeechToText,
    GPT3BoolQuestion: GPT3BoolQuestion,
    GPTChoice: GPTChoice,
    GPT3Classifier: GPT3Classifier,
    ToWhomDetector: ToWhomDetector,
    BadWordDetector: BadWordDetector,
    WordDetector: WordDetector,
    SemanticSearch: SemanticSearch,
    GoOnstage: GoOnstage,
    GoOffstage: GoOffstage,
    GoOnstageBriefing: GoOnstageBriefing,
    ActionButton: ActionButton,
    GoToButton: GoToButton,
    RaiseHandButton: RaiseHandButton,
    If: If,
    TrackedEvent: TrackedEvent,
    UserAction: UserAction,
    NarrativeSolver: NarrativeSolver,
    SetBool: SetBool,
    SetInt: SetInt,
    ValueBool: ValueBool,
    ValueInt: ValueInt,
    ValueString: ValueString,
    AppEvent: AppEvent
  };

  // Dynamic values
  CurrentExerciseSessionID = '';

  State = '';
  History = null;
  InitialHistory = null;
  FeedbackSolver = null;
  TrophiesSolver = null;
  StatsFeedbacksSolver = null;
  GlobalFeedbacksSolver = null;
  SpecialCardsSolver = null;
  TimelineSolver = null;
  AchievementsSolver = null;
  Paused = false;
  SystemFrozen = false;
  StoppedByUser = false;
  RestarstingByUser = false;
  LastBranchingDecisionNode = null;
  StartTime = null;
  RandomSeed = -1;
  ExerciseSettings = null;
  IntroPanel = null;

  CurrentActNode = null;
  CurrentSceneNode = null;

  ActivatedBranchingDecisionsCount = 0;

  DeconnectedAfterPauseTimeout = false;

  // Tasks to wait before ending the graph and going to the feedbacks page
  TasksToWait = [];
  TaskIDIncrement = 0;

  constructor(
    iJsonGraph,
    iInitialHistory = [],
    iCurrentExerciseSessionID = null,
    iRerunNodeID = null
  ) {
    // Singleton initialization
    if (ExerciseGraph.instance) {
      throw new Error("ExerciseGraph: Singleton classes can't be instantiated more than once.");
    }
    ExerciseGraph.Instance = this;
    log.debug(
      "ExerciseGraph.constructor: loading exercise '" +
        iJsonGraph.Name +
        "' version '" +
        iJsonGraph.Version +
        "'."
    );

    // Check if the graph is updated to the new User Actions format
    if (
      iJsonGraph.ID !== 1 && // Don't check for scenario 1 (ex 1 and 4) since it's not concerned by the UA refactoring
      iJsonGraph.ID !== 4 &&
      Object.values(iJsonGraph.AvailableUserActions)[0]?.DetailedFeedback?.Rank
    ) {
      throw new Error(
        "ExerciseGraph: This graph wasn't updated to the new User Actions format!\nNo AvailableUserActions.DetailedFeedback.Rank field found."
      );
    }

    // Create the history
    this.History = new ExerciseSessionHistory(this, this.UserName);

    // Create the solvers
    this.FeedbackSolver = new FeedbackSolver(this);
    this.AchievementsSolver = new AchievementsSolver(this);
    this.TrophiesSolver = new TrophiesSolver(this);
    this.StatsFeedbacksSolver = new StatsFeedbacksSolver(this);
    this.DetailedFeedbacksSolver = new DetailedFeedbacksSolver(this);

    // Base information
    this.ExerciseName = iJsonGraph.Name;
    this.ExerciseID = iJsonGraph.ID;
    this.ExerciseVersion = iJsonGraph.Version;
    this.ScenarioID = iJsonGraph.ScenarioID;
    window.sdk.ScenarioLanguage = iJsonGraph.ScenarioLanguage || 'fr';
    this.SttWindowEarlyOpenSec = iJsonGraph.ExerciseSettings.SttWindowEarlyOpenSec || 0;
    this.InitialHistory = iInitialHistory || [];
    this.CurrentExerciseSessionID = iCurrentExerciseSessionID || null;
    this.RerunNodeID = iRerunNodeID || null;

    this.BotsCount = iJsonGraph.BotsCount || 0;
    this.UsersCount = iJsonGraph.UsersCount || 0;
    this.ExerciseSettings = iJsonGraph.ExerciseSettings;
    this.IntroPanel = iJsonGraph.IntroPanel;

    this.RetrieveAPIsEndpoints();

    // ============= FEEDBACK
    // Available user actions
    if (!iJsonGraph.AvailableUserActions) {
      this.AvailableUserActions = {};
      log.debug('ExerciseGraph.constructor: There is no Available User Actions in this exercise');
    } else {
      this.AvailableUserActions = iJsonGraph.AvailableUserActions;
    }

    // Available feedback user actions
    if (!iJsonGraph.AvailableUserActionsFeedbacks) {
      this.AvailableUserActionsFeedbacks = {};
      log.debug('ExerciseGraph.constructor: There is no Available User Actions in this exercise');
    } else {
      this.AvailableUserActionsFeedbacks = iJsonGraph.AvailableUserActionsFeedbacks;
    }

    // User actions prompt
    if (!iJsonGraph.UserActionsPrompt) {
      this.userActionsPrompt = {};
      log.debug('ExerciseGraph.constructor: There is no User Actions Prompt in this exercise');
    } else {
      this.userActionsPrompt = iJsonGraph.UserActionsPrompt;
    }

    // Available achievements
    if (!iJsonGraph.AvailableAchievements) {
      this.availableAchievements = {};
      log.debug('ExerciseGraph.constructor: There is no Available Achievements in this exercise');
    } else {
      this.availableAchievements = iJsonGraph.AvailableAchievements;
    }

    // AvailablePedagogicalEnds
    if (!iJsonGraph.AvailablePedagogicalEnds) {
      this.availablePedagogicalEnds = {};
      log.debug('ExerciseGraph.constructor: There is no Pedagogical Ends in this exercise');
    } else {
      this.availablePedagogicalEnds = iJsonGraph.AvailablePedagogicalEnds;
    }

    // AvailablePedagogicalRecommendation
    if (!iJsonGraph.AvailablePedagogicalRecommendations) {
      this.availablePedagogicalRecommendations = {};
      log.debug(
        'ExerciseGraph.constructor: There is no Pedagogical Recommendations in this exercise'
      );
    } else {
      this.availablePedagogicalRecommendations = iJsonGraph.AvailablePedagogicalRecommendations;
    }

    // AvailablePedagogicalAddition
    if (!iJsonGraph.AvailablePedagogicalAdditions) {
      this.availablePedagogicalAdditions = {};
      log.debug('ExerciseGraph.constructor: There is no Pedagogical Additions in this exercise');
    } else {
      this.availablePedagogicalAdditions = iJsonGraph.AvailablePedagogicalAdditions;
    }

    if (!iJsonGraph.AvailableTrophies) {
      this.availableTrophies = {};
      log.debug('ExerciseGraph.constructor: There is no Available throphies in this exercise');
    } else {
      this.availableTrophies = iJsonGraph.AvailableTrophies;
    }

    if (!iJsonGraph.AvailableLimitCases) {
      this.availableLimitCases = {};
      log.debug('ExerciseGraph.constructor: There is no Available throphies in this exercise');
    } else {
      this.availableLimitCases = iJsonGraph.AvailableLimitCases;
    }

    // VideosToPreload
    this.VideosToPreload = this.GetAllVideosToPreload(iJsonGraph);

    // Nodes
    this.Nodes = {};
    for (const node of Object.values(iJsonGraph.Nodes)) {
      // Protection against corrupted nodes (ex: no longer used content value)
      // node.ID == null to make sure ID = 0 won't be ignored
      if (!Number.isInteger(node.ID) || !node.Type) {
        log.error(
          "ExerciseGraph.constructor: Corrupted node won't be created. Corrupted node data = ",
          node
        );
        continue;
      }

      this.CreateNode(node).Initialize();
    }

    // Links
    for (let l = 0; l < iJsonGraph.Links.length; l++) {
      this.CreateLink(
        iJsonGraph.Links[l].Source.NodeID,
        iJsonGraph.Links[l].Source.PortName,
        iJsonGraph.Links[l].Target.NodeID,
        iJsonGraph.Links[l].Target.PortName
      );
    }

    // Events
    window.sdk.event().on('waitingVideoData', () => {
      this.waitingVideoData = true;
      this.FreezeSystem();
    });

    window.sdk.event().on('videoDataIsLoaded', () => {
      if (this.waitingVideoData) {
        this.UnfreezeSystem();
        this.waitingVideoData = false;
      }
    });

    window.sdk.event().on('resume', () => {
      this.Resume();
      window.sdk.forbiddenInteractionWarning().pause(false);
    });

    window.sdk.event().on('pause', (byUser = false) => {
      this.Pause();
      this.PausedByUser = false;
      if (byUser) this.PausedByUser = true;
    });

    window.sdk.event().on('fullscreenExited', () => {
      if (window.sdk.isInIframe()) {
        log.debug('fullscreenExited');
        this.Pause();
      }
    });

    window.sdk.event().on('fullscreenOpened', () => {
      if (
        window.sdk.isInIframe() &&
        this.IsPaused() &&
        !this.PausedByUser &&
        !this.DeconnectedAfterPauseTimeout
      ) {
        this.Resume();
      }
    });

    window.sdk.event().on('exerciceDeconnected', () => {
      this.DeconnectedAfterPauseTimeout = true;
    });

    window.sdk.event().on('skipExerciseStep', () => {
      this.Skip();
    });

    window.sdk.event().on('stopExercise', () => {
      this.Stop();
    });

    window.sdk.event().on('quitExercise', () => {
      this.StoppedByUser = true;
      window.sdk.forbiddenInteractionWarning().destroy();
    });

    window.sdk.event().on('restartExercise', () => {
      this.StoppedByUser = true;
      this.RestarstingByUser = true;
      window.sdk.forbiddenInteractionWarning().destroy();
    });

    window.sdk.event().on('RewindGraphToNode', (params) => {
      log.debug(
        "Graph: RewindGraphToNode event received, rewinding to node '" + params.nodeID + "'."
      );
      this.RewindGraphToNode(params.nodeID);
    });

    window.sdk.event().on('forceUserActions', (iUserActionIDs) => {
      this.LastBranchingDecisionNode.ForceUserActions(iUserActionIDs);
    });
  }

  async RetrieveAPIsEndpoints() {
    this.APIsEndpoints = await window.sdk.ExercisesAPIEndpoints().getOne(this.ExerciseID);
  }

  CreateNode(iProperties) {
    let newNode = undefined;

    // Create the node if type exists
    if (iProperties.Type in this.NodeTypes) {
      // Special case for SmartBranchingDecision modes
      if (iProperties.Type === 'SmartBranchingDecision') {
        if (iProperties.GPTMode) {
          newNode = new PromptBranchingDecision(this, iProperties);
        } else {
          newNode = new CustomModelsBranchingDecision(this, iProperties);
        }
      } else {
        // Classic case for other nodes
        newNode = new this.NodeTypes[iProperties.Type](this, iProperties);
      }
    }

    // Add the new node if it is not null
    if (newNode !== undefined) {
      this.AddNode(newNode);
    }

    return newNode;
  }

  AddNode(iNode) {
    this.Nodes[iNode.ID] = iNode;
  }

  GetNode(iID) {
    return this.Nodes[iID];
  }

  CreateLink(iSourceNodeID, iSourcePortName, iTargetNodeID, iTargetPortName) {
    let sourceNode = this.GetNode(iSourceNodeID);
    if (sourceNode === undefined) {
      log.error("ExerciseGraph.CreateLink Error: sourceNode ID '" + iSourceNodeID + "' not found!");
      return;
    }

    let targetNode = this.GetNode(iTargetNodeID);
    if (targetNode === undefined) {
      log.error("ExerciseGraph.CreateLink Error: sourceNode ID '" + iTargetNodeID + "' not found!");
      return;
    }

    let sourcePort = sourceNode.GetPortByName(iSourcePortName);
    if (sourcePort === undefined) {
      log.error(
        "ExerciseGraph.CreateLink Error: sourcePort named '" +
          iSourcePortName +
          "' not found in node '" +
          sourceNode.GetIdentity() +
          "'!"
      );
      return;
    }

    let targetPort = targetNode.GetPortByName(iTargetPortName);
    if (targetPort === undefined) {
      log.error(
        "ExerciseGraph.CreateLink Error: targetPort named '" +
          iTargetPortName +
          "' not found in node '" +
          targetNode.GetIdentity() +
          "'!"
      );
      return;
    }

    sourcePort.Connect(targetPort);
    targetPort.Connect(sourcePort);
  }

  Reset() {
    this.Paused = false;
    this.SystemFrozen = false;
    this.ActivatedBranchingDecisionsCount = 0;
    this.TasksToWait = [];
    this.TaskIDIncrement = 0;

    // Reset all ExerciseNode instances
    for (const node of Object.values(this.Nodes)) {
      if (node instanceof ExerciseNode) {
        node.Reset();
      }
    }
  }

  async InitExerciseSession() {
    // create a new one only if there is no current session provided
    if (!this.CurrentExerciseSessionID) {
      const session = await window.sdk
        .exerciseSession()
        .createOne(
          window.infoVersion.version,
          this.ExerciseID.toString(),
          this.ExerciseVersion.toString(),
          window.sdk.user().userID,
          this.StartTime
        );
      log.debug('ExerciseGraph.Start: exercise session logged.', session);
      this.CurrentExerciseSessionID = session.ID;

      // Save session date in user for FTUE
      window.sdk.user().latestSessionDate = session.StartTime;
    }

    log.debug(
      'ExerciseGraph.InitExerciseSession: exercise session ID = ' + this.CurrentExerciseSessionID
    );

    // Add SessionID to Sentry
    Sentry.setTag('ExerciseSessionID', this.CurrentExerciseSessionID);

    // Add to debug values and test variables
    if (window.testMode.fillAppStateValues) {
      window.testMode.appStateValues['exerciseSessionID'] = this.CurrentExerciseSessionID;
    }
  }

  async Start() {
    log.debug(this.GetIdentity() + ' has started.');

    this.Reset();
    this.StartTime = new Date();
    this.State = 'started';

    await this.InitExerciseSession();

    if (window.testMode.fillAppStateValues) {
      window.testMode.appStateValues['exerciseSessionID'] = this.CurrentExerciseSessionID;
    }

    // Random seed
    this.RandomSeed = Math.random();
    log.debug('ExerciseGraph.constructor: Random seed = ' + this.RandomSeed);
    this.History.AddEvent('RandomSeed', { Seed: this.RandomSeed });

    // Create the local human
    ParticipantsModule.Instance.StartLocalHuman({ name: this.UserName });

    if (this.RerunNodeID) {
      await this.RedoInitialHistory();
    }

    // Activate the first node
    let startNode = this.GetStartNode();
    if (startNode !== undefined) {
      startNode.OnActivated(this, null);
    } else {
      log.error('ExerciseGraph.Start Error: No start node found!');
    }

    // Start the update loop
    this.Update();

    log.debug(this.GetIdentity() + ' has started.');
  }

  Pause() {
    this.Paused = true;
    for (const node of Object.values(this.Nodes)) {
      if (node instanceof ExerciseNode) {
        try {
          node.Pause();
        } catch (err) {
          log.warn('This node must implement pause function', node.GetIdentity());
        }
      }
    }
    // Log event to database
    this.History.AddEvent('Pause', { State: 'Paused' });
    window.sdk.forbiddenInteractionWarning().pause(true);
    window.sdk.event().emit('exercicePaused');
  }

  Resume() {
    if (!this.IsPaused()) return;
    this.Paused = false;
    this.PausedByUser = false;

    for (const node of Object.values(this.Nodes)) {
      if (node instanceof ExerciseNode) {
        try {
          node.Resume();
        } catch (err) {
          log.warn('This node must implement resume function', node.GetIdentity());
        }
      }
    }

    // Log event to database
    this.History.AddEvent('Pause', { State: 'Resumed' });
    window.sdk.forbiddenInteractionWarning().pause(false);
    window.sdk.event().emit('exerciceResumed');
  }

  FreezeSystem() {
    this.SystemFrozen = true;
    for (const node of Object.values(this.Nodes)) {
      if (node instanceof ExerciseNode) {
        try {
          node.FreezeSystem();
        } catch (err) {
          log.warn('This node must implement freeze function', node.GetIdentity());
        }
      }
    }
  }

  UnfreezeSystem() {
    this.SystemFrozen = false;

    for (const node of Object.values(this.Nodes)) {
      if (node instanceof ExerciseNode) {
        try {
          node.UnfreezeSystem();
        } catch (err) {
          log.warn('This node must implement unfreeze function', node.GetIdentity());
        }
      }
    }
  }

  async RedoInitialHistory() {
    const presentBots = new Set();
    const activatedNodes = new Set();
    let currentNode = null;

    this.History.AddRewind(this.RerunNodeID);

    for (const event of this.InitialHistory) {
      this.History.AddEvent(event.EventType, event.Content);

      switch (event.EventType) {
        case 'BotConnection':
          presentBots.add(event.Content.Character);
          break;
        case 'BotDisconnection':
          presentBots.delete(event.Content.Character);
          break;
        default:
          if (activatedNodes.has(event.Content.NodeID)) {
            break;
          }

          currentNode = this.GetNodeByID(event.Content.NodeID);
          if (currentNode) {
            currentNode.OnActivated(this, null, true);
          }
          activatedNodes.add(event.Content.NodeID);
      }
    }

    await this.ActivateBotsAfterRewind(presentBots);
  }

  async ActivateBotsAfterRewind(ibotNames) {
    for (const botName of ibotNames) {
      const bot = ParticipantsModule.Instance.GetBot(botName);

      if (!bot) {
        continue;
      }

      bot.setConnectionState('connected');
      bot.SetWaitingLoop(bot.DefaultLoopVideoName);
      bot.ReturnToWaitLoop();
    }
  }

  StopAllActiveNodes() {
    for (const node of Object.values(this.Nodes)) {
      if (node instanceof ExerciseNode && node.IsActive()) {
        try {
          node.Reset();
        } catch (err) {
          log.warn('This node must implement stop function', node.GetIdentity());
        }
      }
    }
  }

  async Stop(iStoppedByUser = false) {
    this.State = 'stopped';
    this.Paused = false;
    this.SystemFrozen = false;
    window.sdk.forbiddenInteractionWarning().destroy();

    // Stop the local human and bots
    log.debug('Stop', 'StopAllParticipants');
    ParticipantsModule.Instance.StopAllParticipants();

    // Stop all ExerciseNode nodes
    for (const node of Object.values(this.Nodes)) {
      if (node instanceof ExerciseNode) {
        node.OnDeactivated();
      }
    }

    // Wait for tasks to finish
    while (this.TasksToWait.length > 0) {
      log.debug(
        'ExerciseGraph.Stop: Waiting for tasks to finish.\nTasks left: ' +
          this.TasksToWait.length +
          '.\nTasks = ' +
          this.TasksToWait.map((task) => task.ID)
      );
      await Utils.Sleep(200);
    }

    // Solve feedbacks
    if (!this.StoppedByUser && !iStoppedByUser) {
      if (this.ShouldSolveOldFeedbacks()) {
        this.SolveOldFeedbacks();
        this.EmitEndExerciseEvent();
      } else {
        // we use "then" promise callback to make sure that the feedbacks are solved
        // before emitting the endExercise event
        this.SolveFeedbacks().then(() => {
          this.EmitEndExerciseEvent();
        });
      }
    } else if (this.RestarstingByUser) {
      window.sdk.event().emit('restartingExercise');
    }
  }

  EmitEndExerciseEvent() {
    window.sdk.event().emit('endExercise', {
      exerciseID: this.ExerciseID,
      exerciseSessionID: this.CurrentExerciseSessionID
    });
  }

  SolveOldFeedbacks() {
    this.SolveAchievements();
    this.SolveFeedback();
  }

  async SolveFeedbacks() {
    await Promise.all([
      this.SolveUnlockedTrophy(),
      this.SolveStatsFeedbacks(),
      this.SolveDetailedFeedbacks()
    ]);
  }

  ShouldSolveOldFeedbacks() {
    return this.ExerciseID === 1 || this.ExerciseID === 4;
  }

  IsPaused() {
    return this.Paused;
  }

  IsSystemFrozen() {
    return this.SystemFrozen;
  }

  IsStopped() {
    return this.State === 'stopped';
  }

  IsStarted() {
    return this.State === 'started';
  }

  IsRunning() {
    return this.IsStarted() && !this.IsPaused() && !this.IsSystemFrozen();
  }

  Skip = () => {
    const activeNodes = this.GetActiveNodes();

    // Send skip event to each bot video node if they are active
    let skippedNodes = [];
    activeNodes.forEach((node) => {
      if (node.Type === 'BotVideo' || node.Type === 'BotRandomVideo' || node.Type === 'Delay') {
        skippedNodes.push(node.GetDetailedIdentity());
        node.Skip();
      }
    });

    log.debug('DEBUG ACTION: Skipped nodes ', skippedNodes);
  };

  // Game loop
  Update = async function () {
    while (this.IsRunning) {
      // Don't update nodes if paused
      while (this.IsPaused()) await Utils.Sleep(this.FrameDuration);

      // Update nodes
      for (const node of Object.values(this.Nodes)) {
        node.Update();
      }

      // Sleep for 30ms with Utils
      await Utils.Sleep(this.FrameDuration);
    }
  };

  GetIdentity() {
    return this.Type + '_' + this.ExerciseName;
  }

  PrintNodesList() {
    log.debug(this.GetIdentity() + ' has the following ' + this.Nodes.length + ' nodes:');

    for (let i = 0; i < this.Nodes.length; i++) {
      this.Nodes[i].PrintName();
    }
  }

  GetStartNode() {
    if (this.RerunNodeID) {
      return this.Nodes[this.RerunNodeID];
    }

    let startNode = null;
    for (const node of Object.values(this.Nodes)) {
      if (node.Type !== 'ScenarioRoot') {
        continue;
      }
      startNode = node;
      break;
    }

    return startNode;
  }

  GetNodeByID(iID) {
    return this.Nodes[iID];
  }

  GetNodesByType(iType) {
    return Object.values(this.Nodes).filter((node) => node.Type === iType);
  }

  GetAllVideosToPreload(iJsonGraph) {
    const VIDEO_NAME_KEY = 'Video';
    const BOT_NAME_KEY = 'BotName';

    const videoURLs = [];

    const recursiveSearch = (obj) => {
      for (const key in obj) {
        if (key === VIDEO_NAME_KEY) {
          // extract bot name and video name from the object
          const botNameValue = obj[BOT_NAME_KEY] !== undefined ? obj[BOT_NAME_KEY] : null;
          const videoNameValue = obj[key];

          // create the video URL
          const videoUrl = window.sdk.CreateBotVideoURL(
            botNameValue,
            videoNameValue,
            window.sdk.ScenarioLanguage
          );

          videoURLs.push(videoUrl);
        }

        if (typeof obj[key] === 'object' && obj[key] !== null) {
          recursiveSearch(obj[key]);
        }
      }
    };

    recursiveSearch(iJsonGraph);
    return [...new Set(videoURLs)]; // keep only unique video URLs
  }

  GenerateRandomValue() {
    this.RandomSeed = this.RandomSeed + 1;
    return seedrandom(this.RandomSeed)();
  }

  SetBoolValue(iName, iValue) {
    this.BoolValues[iName] = iValue;
  }

  GetBoolValue(iName) {
    if (this.BoolValues[iName] === undefined) {
      return false;
    }

    return this.BoolValues[iName];
  }

  SetIntValue(iName, iValue) {
    this.IntValues[iName] = iValue;
  }

  GetIntValue(iName) {
    if (this.IntValues[iName] === undefined) {
      this.SetIntValue(iName, 0);
    }
    return this.IntValues[iName];
  }

  IncrementIntValue(iName) {
    this.IntValues[iName] = this.GetIntValue(iName) + 1;
    return this.IntValues[iName];
  }

  SetStringValue(iName, iValue) {
    this.StringValues[iName] = iValue;
  }

  GetStringValue(iName) {
    if (this.StringValues[iName] === undefined) {
      log.debug("ExerciseGraph.GetStringValue Error: String value '" + iName + "' not found!");
      return '';
    }

    return this.StringValues[iName];
  }

  GetCurrentActName() {
      return this.CurrentActNode ? this.CurrentActNode.NodeName : '';
    }

  GetCurrentSceneName() {
    return this.CurrentSceneNode.NodeName;
  }

  GetCurrentSceneNodeID() {
    return this.CurrentSceneNode.ID;
  }

  CountUserActionsByType(userActionTypes) {
    let userActionsDetected = this.History.GetUserActions();

    const filteredActions = userActionsDetected.filter((action) => {
      let userActionData = this.AvailableUserActions[action.Content.UserActionID];
      return userActionTypes === userActionData.Type;
    });

    return filteredActions.length;
  }

  CountUserActionsByID(userActionID) {
    // Return the number of UserActionID with this name triggered during the exercice session
    let userActions = this.History.GetUserActions();
    const filteredActions = userActions.filter((action) => {
      return userActionID === action.Content.UserActionID;
    });
    return filteredActions.length;
  }

  // Return the merged full data of the user action from graph AvailableUserActions and BranchingDecision AvailableUserActions
  GetFullUserActionData(iUserActionID, iBranchingDecisionNodeID, iBranchingDecisionNode = null) {
    // Retrieve the main user action data
    let userActionData = this.AvailableUserActions[iUserActionID];
    if (!userActionData) {
      throw new Error(
        `ExerciseGraph.GetFullUserActionData: No available user action found in graph root with ID ${iUserActionID}.`
      );
    }

    // Retrieve the branching decision node
    let branchingDecisionNode = iBranchingDecisionNode
      ? iBranchingDecisionNode
      : this.GetNodeByID(iBranchingDecisionNodeID);
    if (!branchingDecisionNode) {
      throw new Error(
        `ExerciseGraph.GetFullUserActionData: No branching decision node found with ID ${iBranchingDecisionNodeID} for user action ID ${iUserActionID}.`
      );
    }

    // Retrieve the user action data from the branching decision node
    let branchingDecisionUserAction = branchingDecisionNode.AvailableUserActions[iUserActionID];
    if (!branchingDecisionUserAction) {
      throw new Error(
        `ExerciseGraph.GetFullUserActionData: No user action found in the branching decision node with ID ${iBranchingDecisionNodeID} for user action ID ${iUserActionID}.`
      );
    }

    // Merge the main user action data with the branching decision user action data
    return { ...userActionData, ...branchingDecisionUserAction };
  }

  // Return the merged full data of the user action feedback from graph AvailableUserActions and BranchingDecision AvailableUserActions
  GetFullUserActionFeedbackData(
    iUserActionFeedbackID,
    iBranchingDecisionNodeID,
    iBranchingDecisionNode = null
  ) {
    // Retrieve the main user action feedback data
    let userActionFeedbackData = this.AvailableUserActionsFeedbacks[iUserActionFeedbackID];
    if (!userActionFeedbackData) {
      throw new Error(
        `ExerciseGraph.GetFullUserActionFeedbackData: No available user action feedback found in graph root with ID ${iUserActionFeedbackID}.`
      );
    }

    // Retrieve the branching decision node
    let branchingDecisionNode = iBranchingDecisionNode
      ? iBranchingDecisionNode
      : this.GetNodeByID(iBranchingDecisionNodeID);
    if (!branchingDecisionNode) {
      throw new Error(
        `ExerciseGraph.GetFullUserActionFeedbackData: No branching decision node found with ID ${iBranchingDecisionNodeID} for user action feedback ID ${iUserActionFeedbackID}.`
      );
    }

    // Retrieve the user action feedback data from the branching decision node
    let branchingDecisionUserActionFeedback =
      branchingDecisionNode.AvailableUserActionsFeedbacks[iUserActionFeedbackID];
    if (!branchingDecisionUserActionFeedback) {
      throw new Error(
        `ExerciseGraph.GetFullUserActionFeedbackData: No user action feedback found in the branching decision node with ID ${iBranchingDecisionNodeID} for user action feedback ID ${iUserActionFeedbackID}.`
      );
    }

    // Merge the main user action feedback data with the branching decision user action feedback data
    return { ...userActionFeedbackData, ...branchingDecisionUserActionFeedback };
  }

  CountNarrativeEnd(narrativeEnd) {
    // Return the number of NarrativeEnd with this name triggered during the exercice session
    let chosenNarrativeEnd = this.History.GetNarrativeEnd();
    let filteredEnd = 0;
    if (chosenNarrativeEnd && chosenNarrativeEnd.Content.Name === narrativeEnd) {
      filteredEnd = 1;
    }
    return filteredEnd;
  }

  GetAvailableAchievements() {
    return this.availableAchievements;
  }

  GetAvailablePedagogicalEnds() {
    return this.availablePedagogicalEnds;
  }

  GetAvailablePedagogicalRecommendations() {
    return this.availablePedagogicalRecommendations;
  }

  GetAvailablePedalogologicalAdditions() {
    return this.availablePedagogicalAdditions;
  }

  SolveAchievements() {
    // Solve which achievements to display with the achievements solver.
    const achievementsToDisplay = this.AchievementsSolver.GetAchievementsToDisplay();

    // Save the result in the exercise session history
    this.History.AddAchievementsDone(achievementsToDisplay);
  }

  SolveFeedback() {
    // Solve which feedback to display with the feedback solver.
    const pedagogicalEndToDisplay = this.FeedbackSolver.GetPedagogicalEndToDisplay();
    const pedagogicalRecommendationsToDisplay =
      this.FeedbackSolver.GetPedagogicalRecommendationsToDisplay();
    const pedagogicalAdditionsToDisplay = this.FeedbackSolver.GetPedagogicalAdditionsToDisplay();

    // Save the result in the exercise session history
    this.History.AddPedagogicalEnd(pedagogicalEndToDisplay);
    this.History.AddPedagogicalRecommendations(pedagogicalRecommendationsToDisplay);
    this.History.AddPedagogicalAdditions(pedagogicalAdditionsToDisplay);
  }

  async SolveUnlockedTrophy() {
    const unlockedTrophyID = await this.TrophiesSolver.SolveUnlockedTrophy();

    await this.History.AddUnlockedTrophyID(unlockedTrophyID);
  }

  async SolveStatsFeedbacks() {
    const statsFeedbacks = await this.StatsFeedbacksSolver.SolveStatsFeedbacks();

    await this.History.AddStatsFeedbacks(statsFeedbacks);
  }

  async SolveDetailedFeedbacks() {
    const detailedFeedbacks = await this.DetailedFeedbacksSolver.SolveDetailedFeedbacks();

    await this.History.AddDetailedFeedbacks(detailedFeedbacks);
  }

  SetCurrentBranchingDecision(iBranchingDecision, iDone) {
    this.LastBranchingDecisionNode = iBranchingDecision;

    window.sdk.event().emit('addDebugInfo', {
      iID: 'lastBranchingDecision',
      iValue: { ID: iBranchingDecision.ID, State: iDone ? 'done' : 'running' }
    });
  }

  IncrementBranchingDecisionsActivations() {
    // Count the number of time a branching decision was activated and update the session in database
    this.ActivatedBranchingDecisionsCount++;

    window.sdk
      .exerciseSession()
      .updateItem(this.CurrentExerciseSessionID, this.ExerciseID.toString(), {
        ActivatedBranchingDecisionsCount: this.ActivatedBranchingDecisionsCount.toString()
      });
  }

  GetActiveNodes() {
    // Check and reference all the active nodes
    let activeNodes = [];
    for (const node of Object.values(this.Nodes)) {
      if (node.IsActive && node.IsActive()) {
        activeNodes.push(node);
      }
    }
    return activeNodes;
  }

  // DEBUG Tools
  GetActiveNodesText() {
    // Check and reference all the active nodes
    const activeNodes = this.GetActiveNodes();
    let activeNodesText = [];
    activeNodes.forEach((node) => {
      activeNodesText.push('<li>' + node.GetDetailedIdentity() + '</li>');
    });

    return activeNodesText.join('');
  }

  GetCurrentlyPossibleUserActions() {
    if (!this.LastBranchingDecisionNode) {
      return [];
    }

    return this.LastBranchingDecisionNode.GetCurrentlyPossibleUserActions();
  }

  AddTaskToWaitBeforeEnd(iTaskName) {
    // Generate a unique ID for the task from date to string (no spaces) and increment
    const taskID = new Date().toISOString().replace(/[^0-9]/g, '') + '_' + this.TaskIDIncrement++;

    // Add the task to the list
    this.TasksToWait.push({ ID: taskID, Name: iTaskName });

    log.debug(
      'ExerciseGraph.AddTaskToWaitBeforeEnd: ' +
        iTaskName +
        ' with ID: ' +
        taskID +
        '. Remaining tasks: ' +
        this.TasksToWait.length
    );

    return taskID;
  }

  OnTaskEnded(iTaskID) {
    // Remove the task from the list
    this.TasksToWait = this.TasksToWait.filter((task) => task.ID !== iTaskID);

    log.debug(
      'ExerciseGraph.OnTaskEnded: ' + iTaskID + '. Remaining tasks: ' + this.TasksToWait.length
    );
  }
}
