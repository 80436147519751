import React from 'react';

export default class DbMeter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selected: window.sdk.videoconf().mediaDevices().getCurrentDeviceId(this.props.kind)
    };
    this.dbMeter = React.createRef();
    this.listeners = [];
  }

  componentDidMount() {
    this.listenVolume();
  }

  componentDidUpdate() {
    this.listenVolume();
  }

  componentWillUnmount() {
    for (const i in this.listeners) {
      this.listeners[i]();
    }
  }

  listenVolume = () => {
    let managerAudio = window.sdk.videoconf().mediaDevices().getAudioManager();
    if (!managerAudio) return;
    this.listeners.push(
      managerAudio.on('volume', (data) => {
        if (this.dbMeter.current)
          this.dbMeter.current.style.width = Math.round(5 * data * 100) + '%';
      })
    );
  };

  render() {
    return (
      <span className={`${this.props.className || ''} bg-speak/20 block h-5 w-full mask-dbmeter`}>
        <span ref={this.dbMeter} className="bg-speak block h-full"></span>
      </span>
    );
  }
}
