import React from 'react';
import Animation from '@/components/Utilities/Animation';

export default class BottomButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    let { children, variant, waiting } = this.props;
    let icon = null;
    let iconPosition = 'left';
    if (waiting) {
      icon = (
        <Animation
          classCSS="h-auto w-8 [&_svg_path]:stroke-current [&_svg_path]:fill-current [&_svg_rect]:fill-current"
          type={'waiting'}
          autoplay={true}
          loop={true}
        />
      );
      if (variant === 'white') {
        iconPosition = 'right';
      }
    } else {
      switch (variant) {
        case 'white':
          break;
        case 'hang-up':
          icon = <i className="icon-phone-alt h-auto w-8 text-current"></i>;
          break;
        case 'raising':
          icon = (
            <Animation
              classCSS="h-auto w-8 [&_svg_path]:stroke-current [&_svg_path]:fill-current [&_svg_rect]:fill-current"
              type={'raisingHand'}
              autoplay={false}
              loop={true}
              name="raisingHandBottom2"
            />
          );
          break;
        case 'speaking':
          icon = (
            <Animation
              classCSS="h-auto w-8 [&_svg_path]:stroke-current [&_svg_path]:fill-current [&_svg_rect]:fill-current"
              type={'speech'}
              autoplay={true}
              loop={true}
            />
          );
          break;
      }
    }
    return this.variant === 'raising' ? (
      <button
        className={`cta cta--speak flex items-center space-x-2`}
        onMouseEnter={() => {
          window.sdk.event().emit('playLottie', 'raisingHandBottom2');
        }}
        onMouseLeave={() => {
          window.sdk.event().emit('pauseLottie', 'raisingHandBottom2');
        }}>
        {iconPosition === 'left' && icon} <span>{children}</span> {iconPosition === 'right' && icon}
      </button>
    ) : (
      <div
        className={`cta cta--without-hover-effect text-speak flex items-center space-x-2 border`}>
        {iconPosition === 'left' && icon} <span>{children}</span> {iconPosition === 'right' && icon}
      </div>
    );
  }
}
