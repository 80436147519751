import { useEffect, useState } from 'react';
import log from 'loglevel';
import { TrophyIdType } from '@/models/TrophyModel';
import { DetailedFeedbackModel, ImprovementAxesModel } from '@/models/DetailedFeedbackModel';
import { StatsFeedbackModel } from '@/models/StatsFeedbackModel';

type ExerciseSessionEvent = {
  ExerciseSessionID: string;
  EventID: string;
  UserID: string;
  EventType: string;
  Date: Date;
  Content: any;
};

const UNLOCKED_TROPHY_EVENT_TYPE = 'UnlockedTrophy';
const DETAILED_FEEDBACKS_EVENT_TYPE = 'DetailedFeedbacks';
const STATS_FEEDBACKS_EVENT_TYPE = 'StatsFeedbacks';

export function useFeedback(exerciseSessionID: string | undefined) {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [unlockedTrophyID, setUnlockedTrophyID] = useState<TrophyIdType>();
  const [detailedFeedbacks, setDetailedFeedback] = useState<DetailedFeedbackModel[]>();
  const [improvementAxes, setImprovementAxes] = useState<ImprovementAxesModel[]>();
  const [statsFeedbacks, setStatsFeedbacks] = useState<StatsFeedbackModel>();

  useEffect(() => {
    const fetchFeedbacks = async () => {
      if (!exerciseSessionID) {
        setIsLoading(false);
        return;
      }

      try {
        const sessionHistory: ExerciseSessionEvent[] = await window.sdk
          .exerciseSession()
          .getExerciseSessionHistory(exerciseSessionID);

        const lastUnlockedTrophyEvent = getLastEventByType(
          sessionHistory,
          UNLOCKED_TROPHY_EVENT_TYPE
        );

        const lastStatsFeedbacksEvent = getLastEventByType(
          sessionHistory,
          STATS_FEEDBACKS_EVENT_TYPE
        );

        const lastDetailedFeedbacksEvent = getLastEventByType(
          sessionHistory,
          DETAILED_FEEDBACKS_EVENT_TYPE
        );

        const unlockedTrophyID = lastUnlockedTrophyEvent?.Content.UnlockedTrophyID;
        const statsFeedbacks = lastStatsFeedbacksEvent?.Content.StatsFeedbacks;
        const improvementAxes =
          lastDetailedFeedbacksEvent?.Content.DetailedFeedbacks.improvementAxes;
        const detailedFeedbacks =
          lastDetailedFeedbacksEvent?.Content.DetailedFeedbacks.solvedActsFeedbacks;

        setUnlockedTrophyID(unlockedTrophyID);
        setDetailedFeedback(detailedFeedbacks);
        setImprovementAxes(improvementAxes);
        setStatsFeedbacks(statsFeedbacks);
        setIsLoading(false);
      } catch (error: any) {
        log.error(error);
        setErrorMessage(error.message);
        setIsLoading(false);
      }
    };

    fetchFeedbacks();
  }, [exerciseSessionID]);

  return {
    unlockedTrophyID,
    detailedFeedbacks,
    improvementAxes,
    statsFeedbacks,
    isLoading,
    errorMessage
  };
}

function getLastEventByType(
  events: ExerciseSessionEvent[],
  eventType: string
): ExerciseSessionEvent | undefined {
  for (let i = events.length - 1; i >= 0; i--) {
    if (events[i].EventType === eventType) {
      return events[i];
    }
  }
  return undefined;
}
