export default class AchievementsSolver {
  constructor(iGraph) {
    this.Graph = iGraph;
  }

  GetAchievementsToDisplay() {
    let achievementsToDisplay = [];

    // Get the list of available achievements
    let availableAchievements = this.Graph.GetAvailableAchievements();

    for (const achievement of Object.values(availableAchievements)) {
      if (this.Graph.CountUserActionsByID(achievement.ID) > 0) {
        achievementsToDisplay.push(achievement);
      }
    }
    // Push the achievements done to trigger their content.
    return achievementsToDisplay;
  }
}
