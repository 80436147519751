import { JSXElementConstructor, ReactElement, ReactNode, ReactPortal } from 'react';

export const isReact = (
  node: ReactNode
): node is ReactElement<any, string | JSXElementConstructor<any>> | ReactPortal => {
  if (['boolean', 'number', 'string'].indexOf(typeof node) >= 0) {
    return false;
  }
  return true;
};
