import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class WordDetector extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Speech = new NodePort('Speech', 'input', this);
  SomeWordsDetected = new NodePort('SomeWordsDetected', 'output', this);
  NoWordDetected = new NodePort('NoWordDetected', 'output', this);

  // Parameters
  WordsToDetect = [];

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    iProperties.WordsToDetect.forEach((wordToDetect) => {
      log.debug(
        this.GetIdentity() + " constructor: Adding a word to detect '" + wordToDetect.Word + "'."
      );

      let newWordToDetect = new WordToDetect(wordToDetect.ID, wordToDetect.Word);
      this.WordsToDetect.push(newWordToDetect);

      this[newWordToDetect.GetPortName()] = new NodePort(
        newWordToDetect.GetPortName(),
        'output',
        this
      );
    });

    /*log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName 
                    + ", id = " + this.ID 
                    + ", wordToDetects count = " + this.WordsToDetect.length);*/
  }

  OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    // Retrieve speech text from speech input node
    let speech = this.GetSourceText();

    let startTime = new Date();

    // Try to detect the words
    let detectedWords = this.DetectWord(speech);

    // Log to DynamoDB
    window.sdk.AnalysisTask().createOne(
      this.Graph.LastBranchingDecisionNode.DatabaseID, // Parent Branching Decision Node
      this.ID.toString(), // Node ID
      'WordDetector', // analyzer Engine
      '1', // Analyzer Version
      'raw', // Analysis Status
      speech, // Analysis Input
      startTime, // Start Time
      (new Date().getTime() - startTime.getTime()).toString(), // Analysis duration (milliseconds)
      JSON.stringify(this.WordsToDetect), // Possible choices
      JSON.stringify(detectedWords), // Analysis Result
      this.Graph.ExerciseID.toString() // Exercise ID
    );

    log.debug(
      this.GetIdentity() +
        " has been activated by '" +
        iActivator.GetIdentity() +
        "'. Speech = '" +
        speech +
        "'. It has detected " +
        detectedWords.length +
        ' words.'
    );

    // Activate the right output depending on the detection
    this.SetActive(false);
    if (detectedWords.length > 0) {
      // Activate the general port every time at least one word is detected
      this.SomeWordsDetected.ActivateAllConnections();

      // Activate the specific first detected word port
      let wordPort = this.GetPortByName(detectedWords[0].GetPortName());
      wordPort.ActivateAllConnections();
    } else {
      // Activate the "no word is detected" port
      this.NoWordDetected.ActivateAllConnections();
    }
  }

  GetSourceText() {
    let text = '';

    // Get the speech from the first connected node
    let speechNodes = this.Speech.GetConnectedNodes();
    if (speechNodes.length > 0) {
      text = speechNodes[0].GetStringValue();
    }

    return text;
  }

  DetectWord(iSpeech) {
    let wordsDetected = [];

    this.WordsToDetect.forEach((wordToDetect) => {
      let word = wordToDetect.Word;
      // Define a regular expression to detect the word
      //let regex = new RegExp(wordToDetect.Word, 'i');
      let regex = new RegExp(word, 'i');
      let matches = iSpeech.match(regex);
      if (matches) {
        // We collect the detected word
        wordsDetected.push(wordToDetect);
      } else {
        //log.debug(this.GetIdentity() + ".DetectWord: word '" + word + "' has not been detected.");
      }
    });

    return wordsDetected;
  }

  OnDeactivated() {
    super.OnDeactivated();
  }

  PrintParameters() {}
}

class WordToDetect {
  ID = -1;
  Word = '';

  constructor(iID, iWord) {
    this.ID = iID;
    this.Word = iWord;
  }

  GetPortName() {
    return 'Word' + this.ID;
  }

  ToString() {
    return '{' + "\n  Word: '" + this.Word + "'" + "\n  Port: '" + this.GetPortName() + "'" + '\n}';
  }
}
