import log from 'loglevel';
import Utils from '../../AppClasses/Utils/Utils';
import { DEFAULT_LANGUAGE } from '../../helpers/getTolgeeInstance';

export default class ExerciseSession {
  UniqueIDIncrementer = 0;

  constructor(sdk) {
    this.sdk = sdk;
  }

  async init() {}

  // Exercise session
  async createOne(iAppVersion, iExerciseID, iExerciseVersion, iUserID, iStartTime) {
    const startTime = iStartTime.toISOString();

    let body = {
      ExerciseID: iExerciseID,
      ID: Utils.CreateObjectID(iStartTime, window.sdk.user().userID),
      AppVersion: iAppVersion,
      ExerciseVersion: iExerciseVersion,
      UserID: iUserID,
      StartTime: startTime, // Database format: 2022-10-14T00:09:48.875,
      Month: startTime.substring(0, 7), // Format: YYY-MM
      ActivatedBranchingDecisionsCount: '0'
    };

    const res = await this.sdk.fetchInternalAPI().post('/exercise-session', { body });
    window.sdk.exerciseSessionID = body.ID;
    return res;
  }

  async updateItem(iID, iExerciseID, iValuesToUpdate) {
    let body = {
      Values: iValuesToUpdate
    };

    const res = await this.sdk
      .fetchInternalAPI()
      .post(`/exercise-session/${iExerciseID}/${iID}/update`, { body });

    return res;
  }

  async getOne(exerciseSessionID) {
    const res = await this.sdk.fetchInternalAPI().get('/exercise-session/' + exerciseSessionID);
    return res;
  }

  async getOneWithExercise(exerciseID, exerciseSessionID) {
    const lang = window.sdk.getLanguage();
    if (lang !== DEFAULT_LANGUAGE) {
      exerciseSessionID += `?lang=${lang}`;
    }
    const res = await this.sdk
      .fetchInternalAPI()
      .get('/exercise-session/' + exerciseID + '/' + exerciseSessionID);

    log.debug(`DEBUG - SDK getOneWithExercise - res: ${JSON.stringify(res)}`);

    return res;
  }

  // Reviews
  async saveReviews(userID, ExerciseSessionID, reviews) {
    let body = {
      //UserID : userID, not secure to get userID from front
      ExerciseSessionID: ExerciseSessionID,
      reviews: []
    };
    let StartTime;
    log.debug(reviews);
    StartTime = new Date();
    reviews.forEach((review) => {
      StartTime.setSeconds(StartTime.getSeconds() + 1);
      body.reviews.push({
        ID: Utils.CreateObjectID(StartTime, window.sdk.user().userID),
        Name: review.ID,
        Type: review.Type,
        Date: StartTime,
        Value: review.value
      });
    });
    const res = await this.sdk
      .fetch()
      .post('/exercise-session/' + ExerciseSessionID + '/reviews', { body });
    return res;
  }

  async saveReview(ExerciseSessionID, StartTime, reviewName, reviewType, reviewValue) {
    let body = {
      //UserID : window.sdk.user().userID, => not secure to get userID from front
      ExerciseSessionID: ExerciseSessionID,
      ID: Utils.CreateObjectID(StartTime, window.sdk.user().userID),
      Name: reviewName,
      Type: reviewType,
      Date: StartTime,
      Value: reviewValue
    };
    const res = await this.sdk
      .fetch()
      .post('/exercise-session/' + ExerciseSessionID + '/review', { body });
    return res;
  }

  async getReviews(exerciseID, exerciseSessionID) {
    const res = await this.sdk
      .fetch()
      .get('/exercise-session/' + exerciseID + '/' + exerciseSessionID + '/reviews');
    let reviews = [];
    res.forEach((item) => {
      reviews.push({
        Date: item.Date ? item.Date.S : '',
        ExerciseSessionID: item.ExerciseSessionID ? item.ExerciseSessionID.S : '',
        ID: item.ID ? item.ID.S : '',
        Name: item.Name ? item.Name.S : '',
        Type: item.Type ? item.Type.S : '',
        UserID: item.UserID ? item.UserID.S : '',
        Value: item.Value ? item.Value.S : ''
      });
    });
    return reviews;
  }

  // Achievements
  async getAchievements(currentExerciseSessionID) {
    return JSON.parse(
      window.localStorage.getItem('achievements-session-' + currentExerciseSessionID)
    );
  }

  // History
  async addEventToHistory(iEvent) {
    if (!iEvent.ExerciseSessionID) return;
    let event = { ...iEvent };
    event.Content = JSON.stringify(event.Content);
    const res = await this.sdk
      .fetchInternalAPI()
      .post('/exercise-session-history/' + event.ExerciseSessionID, { body: event });
    return res;
  }

  async updateHistoryEvent(iNewEvent) {
    const res = await this.sdk
      .fetchInternalAPI()
      .post(
        `/exercise-session-history/${iNewEvent.ExerciseSessionID}/${iNewEvent.EventID}/update`,
        {
          body: { Content: JSON.stringify(iNewEvent.Content) }
        }
      );
    return res;
  }

  async getExerciseSessionHistory(iExerciseSessionID) {
    const res = await this.sdk
      .fetchInternalAPI()
      .get('/exercise-session-history/' + iExerciseSessionID);

    let exerciseSessionHistory = [];
    res.forEach((item) => {
      exerciseSessionHistory.push({
        ExerciseSessionID: item.ExerciseSessionID,
        EventID: item.EventID,
        UserID: item.UserID,
        EventType: item.EventType,
        Date: item.Date,
        Content: JSON.parse(item.Content)
      });
    });

    return exerciseSessionHistory;
  }
}
