import AbstractSolver from './AbstractSolver';
import { USER_ACTION_TAGS } from './constants';
import { uniqBy } from '../../Utils/uniqBy';

export default class StatsFeedbacksSolver extends AbstractSolver {
  /**
   * Calculates and returns statistics based on user actions for feedbacks.
   * @returns {Object} An object containing the calculated statistics.
   */
  SolveStatsFeedbacks() {
    return {
      PositiveInteractionsInPercent: this.CalculatePositiveInteractionsInPercent()
    };
  }

  /**
   * Calculates the percentage of positive interactions based on user action feedbacks.
   * @returns {number} The percentage of positive interactions.
   */
  CalculatePositiveInteractionsInPercent() {
    const userActionFeedbacksEvents = uniqBy(
      this.Graph.History.GetUserActionsFeedbacks(),
      (uafEvent) => `${uafEvent.Content.NodeID}-${uafEvent.Content.UserActionFeedbackID}`
    );

    const userActionFeedbacks = userActionFeedbacksEvents.map((event) => {
      const uaf = this.Graph.GetFullUserActionFeedbackData(
        event.Content.UserActionFeedbackID,
        event.Content.NodeID
      );

      return { ...uaf, IsMissedOpportunity: event.Content.IsMissedOpportunity };
    });

    const positiveInteractions = userActionFeedbacks.filter((uaf) => {
      return (
        (uaf.Tags.includes(USER_ACTION_TAGS.GOOD_ACTION) ||
          uaf.Tags.includes(USER_ACTION_TAGS.NEUTRAL_ACTION)) &&
        !uaf.IsMissedOpportunity
      );
    });

    return Math.round((positiveInteractions.length / userActionFeedbacksEvents.length) * 100);
  }
}
