import { AvatarName } from '@UIKit/AvatarName/AvatarName';
import React from 'react';
import Form from 'react-bootstrap/Form';
import Range from '@/components/Utilities/Range';
import { Translation } from '@/components/Utilities/Translation';

export default class Scenario1Feedback extends React.Component {
  exercice = {
    2: {
      title: (
        <Translation keyName="s1feedback.satisfied">
          Dans quelle mesure êtes-vous satisfait du déroulement de l'interaction?
        </Translation>
      ),
      moins: <Translation keyName="s1feedback.dissatisfied">Très insatisfait</Translation>,
      plus: <Translation keyName="s1feedback.very_satisfied">Très satisfait</Translation>,
      label: <Translation keyName="s1feedback.impression">Votre ressenti</Translation>,
      media_type: 'intervenants',
      media_title: (
        <Translation keyName="s1feedback.the_participants">Les intervenants</Translation>
      ),
      media_infos: [
        { name: 'Franck', pic: 'franck.png' },
        { name: 'Jeanne', pic: 'jeanne.png' },
        { name: 'Clara', pic: 'Clara.png' },
        { name: 'Jules', pic: 'jules.png' }
      ],
      placeholder: (
        <Translation keyName="s1feedback.feeling">
          Face aux différents intervenants, je me suis senti.e...
        </Translation>
      )
    },
    default: {
      title: (
        <Translation keyName="s1feedback.user_feeling">
          Comment vous êtes-vous senti face à la situation ?
        </Translation>
      ),
      moins: <Translation keyName="s1feedback.discomfort">Très mal à l’aise</Translation>,
      plus: <Translation keyName="s1feedback.comfort">Très à l’aise</Translation>,
      label: <Translation keyName="s1feedback.facing_situation">Face à la situation</Translation>,
      placeholder: (
        <Translation keyName="s1feedback.describe_situation">
          Décrivez-nous ce que vous avez ressenti face à la situation...
        </Translation>
      )
    }
  };

  state = {
    satisfaction_txt: ''
  };

  setSatisfactionText = (e) => {
    this.setState({
      satisfaction_txt: e.target.value
    });
  };

  setSatisfaction = (val) => {
    this.setState({
      satisfaction_value: val
    });
  };

  render() {
    let data = this.exercice[this.props.exerciseID];
    if (!data) {
      data = this.exercice['default'];
    }
    if (!data) return null;
    return (
      <>
        <h1 className="text-center title">{data.title}</h1>

        <Range
          default={4}
          identifier="satisafaction"
          labelLeft={data.moins}
          labelRight={data.plus}
          onChange={this.setSatisfaction}
        />

        {data.media_type === 'intervenants' && (
          <Form.Group className="mt-6">
            <Form.Label className="label">{data.media_title}</Form.Label>
            <ul className="flex-xrap flex space-x-4">
              {data.media_infos.map((intervant) => {
                let img = require('@/assets/images/feedback/' + intervant.pic);
                return (
                  <li className="relative" key={intervant.name}>
                    <img src={img} />
                    <AvatarName className="absolute bottom-0">{intervant.name}</AvatarName>
                  </li>
                );
              })}
            </ul>
          </Form.Group>
        )}
        <Form.Group className="mt-6">
          <Form.Label className="label">{data.label}</Form.Label>
          <br />
          <Form.Control
            className="block h-20 w-full resize-y"
            as="textarea"
            placeholder={data.placeholder}
            onChange={this.setSatisfactionText}
            defaultValue={this.state.satisfaction_txt}></Form.Control>
        </Form.Group>
      </>
    );
  }
}
