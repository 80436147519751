import Utils from '../../AppClasses/Utils/Utils';

export default class BranchingDecision {
  constructor(sdk) {
    this.sdk = sdk;
  }

  async init() {}

  async createOne(
    iExerciseSessionID,
    iDecisionDate,
    iDecisionStatus,
    iNodeID,
    iBranchingDecisionName,
    iSucceeded,
    iPossibleBranches
  ) {
    let body = {
      ExerciseSessionID: iExerciseSessionID,
      ID: Utils.CreateObjectID(iDecisionDate, window.sdk.user().userID),
      DecisionDate: iDecisionDate.toISOString(), // Database format: 2022-10-14T00:09:48.875
      DecisionStatus: iDecisionStatus,
      NodeID: iNodeID,
      BranchingDecisionName: iBranchingDecisionName ? iBranchingDecisionName : 'NA',
      PossibleBranches: iPossibleBranches,
      ChosenBranch: '',
      Succeeded: iSucceeded
    };
    let res = await this.sdk.fetchInternalAPI().post('/branching-decision', { body });

    return res;
  }

  async updateItem(iExerciseSessionID, iID, iValuesToUpdate) {
    let body = {
      ExerciseSessionID: iExerciseSessionID,
      ID: iID,
      Values: iValuesToUpdate
    };

    let res = await this.sdk.fetchInternalAPI().post('/branching-decision/update', { body });

    return res;
  }

  async getAllFromExerciseSessionID(iExerciseSessionID) {
    let res = await this.sdk
      .fetch()
      .get('/branching-decision/getallfromexercisesessionid/' + iExerciseSessionID);
    return res;
  }
}
