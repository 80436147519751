import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class GroupPaths extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);
  Output = new NodePort('Output', 'output', this);

  // Parameters

  // Internal values

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID);
  }

  OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(this.GetIdentity() + " has been activated by '" + iActivator.GetIdentity() + "'.");

    this.ActivateOutput();
  }

  ActivateOutput() {
    log.debug(this.GetIdentity() + "' activating output.");

    this.SetActive(false);

    this.Output.ActivateAllConnections();
  }
}
