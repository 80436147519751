import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ExerciseContextProvider } from '../Exercise/ExerciseContext';
import FeedbackContainer from './FeedbackContainer';

const OldFeedbackWrapper = () => {
  const { idExercise, idExerciseSession } = useParams();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.sdk.event().emit('ExercisePanelEntered');
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <ExerciseContextProvider idExercise={idExercise} idExerciseSession={idExerciseSession}>
      <FeedbackContainer />
    </ExerciseContextProvider>
  );
};
export default OldFeedbackWrapper;
