import AbstractSolver from './AbstractSolver';
import { STOP_TYPES, USER_ACTION_TAGS } from './constants';

const BRONZE_TROPHY_ID = 'BRONZE';
const SILVER_TROPHY_ID = 'SILVER';
const GOLD_TROPHY_ID = 'GOLD';
const PLATINUM_TROPHY_ID = 'PLATINUM';
const DIAMOND_TROPHY_ID = 'DIAMOND';
const LIMIT_CASE_TROPHY_ID = 'LIMIT_CASE';

export default class TrophiesSolver extends AbstractSolver {
  /**
   * Determines the highest unlocked trophy based on the user's performance.
   * @returns {string} The ID of the highest unlocked trophy.
   */
  SolveUnlockedTrophy() {
    const trophiesEvents = this.Graph.History.GetUnlockedTrophiesEvents();
    const userActionFeedbacksEvents = this.Graph.History.GetUserActionsFeedbacks();
    const stopEvent = this.Graph.History.GetStopEvent();

    // Define trophy checks in order of precedence,
    // declaring for each trophy:
    // the check function, the arguments to pass to the function and the trophy ID to return
    const trophyChecks = [
      {
        check: this.IsLimitCaseTrophyUnlocked,
        args: [userActionFeedbacksEvents],
        id: LIMIT_CASE_TROPHY_ID
      },
      // Diamond rank is temporary disabled
      // {
      //   check: this.IsDiamondTrophyUnlocked,
      //   args: [userActionFeedbacksEvents, stopEvent],
      //   id: DIAMOND_TROPHY_ID
      // },
      {
        check: this.IsPlatinumTrophyUnlocked,
        args: [userActionFeedbacksEvents, stopEvent],
        id: PLATINUM_TROPHY_ID
      },
      { check: this.IsGoldTrophyUnlocked, args: [stopEvent], id: GOLD_TROPHY_ID },
      { check: this.IsSilverTrophyUnlocked, args: [trophiesEvents], id: SILVER_TROPHY_ID },
      { check: this.IsBronzeTrophyUnlocked, args: [trophiesEvents], id: BRONZE_TROPHY_ID }
    ];

    // Check each trophy condition and return the first that is true
    for (const { check, args, id } of trophyChecks) {
      if (check.apply(this, args)) {
        return id;
      }
    }
  }

  /**
   * Checks if the Bronze trophy has been unlocked.
   * @param {Array} iTrophiesEvents - Array of trophy events.
   * @returns {boolean} True if Bronze trophy is unlocked, false otherwise.
   */
  IsBronzeTrophyUnlocked(iTrophiesEvents) {
    // temporary hack to always unlock the bronze trophy if all others are not unlocked
    return true;
  }

  /**
   * Checks if the Silver trophy has been unlocked.
   * @param {Array} iTrophiesEvents - Array of trophy events.
   * @returns {boolean} True if Silver trophy is unlocked, false otherwise.
   */
  IsSilverTrophyUnlocked(iTrophiesEvents) {
    return iTrophiesEvents.some((event) => event.Content.TrophyID === SILVER_TROPHY_ID);
  }

  /**
   * Checks if the Gold trophy has been unlocked.
   * @returns {boolean} True if Gold trophy is unlocked, false otherwise.
   */
  IsGoldTrophyUnlocked(iStopEvent) {
    // Gold trophy is unlocked if the exercise was completed successfully
    if (!iStopEvent || iStopEvent.Content.StopType === STOP_TYPES.FAILED) {
      return false;
    }

    return true;
  }

  /**
   * Checks if the Platinum trophy has been unlocked.
   * @param {Array} iUserActionFeedbacksEvents - Array of user action feedback events.
   * @returns {boolean} True if Platinum trophy is unlocked, false otherwise.
   */
  IsPlatinumTrophyUnlocked(iUserActionFeedbacksEvents, iStopEvent) {
    if (!iStopEvent || iStopEvent.Content.StopType === STOP_TYPES.FAILED) {
      return false;
    }

    const badUserActionFeedbacks = this.GetFeedbacksByTagFromUserActionsEvents(
      iUserActionFeedbacksEvents,
      USER_ACTION_TAGS.BAD_ACTION
    );

    const missedOpportunityEvents = iUserActionFeedbacksEvents.filter(
      (uafEvent) => uafEvent.Content.IsMissedOpportunity
    );

    // Platinum trophy is unlocked if there are no bad actions or missed opportunities
    if (missedOpportunityEvents.length || badUserActionFeedbacks.length) {
      return false;
    }

    return true;
  }

  /**
   * Checks if the Diamond trophy has been unlocked.
   * @param {Array} iUserActionFeedbackEvents - Array of user action feedback events.
   * @returns {boolean} True if Diamond trophy is unlocked, false otherwise.
   */
  IsDiamondTrophyUnlocked(iUserActionFeedbackEvents, iStopEvent) {
    if (!iStopEvent || iStopEvent.Content.StopType === STOP_TYPES.FAILED) {
      return false;
    }

    const featuredUserActionsFeedbacks = this.GetAllFeaturedUserActionFeedbacksFromGraph();
    const userFeaturedUserActionFeedbacks = this.GetFeedbacksByTagFromUserActionsEvents(
      iUserActionFeedbackEvents,
      USER_ACTION_TAGS.FEATURED_ACTION
    );

    // Diamond trophy is unlocked if all featured actions were performed
    return featuredUserActionsFeedbacks.length === userFeaturedUserActionFeedbacks.length
      ? true
      : false;
  }

  /**
   * Checks if the Limit Case trophy has been unlocked.
   * @param {Array} iUserActionFeedbackEvents - Array of user action feedback events.
   * @returns {boolean} True if Limit Case trophy is unlocked, false otherwise.
   */
  IsLimitCaseTrophyUnlocked(iUserActionFeedbackEvents) {
    const limitCaseActions = this.GetFeedbacksByTagFromUserActionsEvents(
      iUserActionFeedbackEvents,
      USER_ACTION_TAGS.LIMIT_CASE
    );

    // Limit Case trophy is unlocked if 2 limit case actions were performed
    return limitCaseActions.length >= 2 ? true : false;
  }

  /**
   * Retrieves user action feedbacks with a specific tag from the given events.
   * @param {Array} iUserActionFeedbackEvents - Array of user action feedback events.
   * @param {string} iTag - The tag to filter by.
   * @returns {Array} Array of user action feedbacks with the specified tag.
   */
  GetFeedbacksByTagFromUserActionsEvents(iUserActionFeedbackEvents, iTag) {
    const userActionFeedbacks = [];

    for (const uafEvent of iUserActionFeedbackEvents) {
      const userActionFeedback = this.Graph.GetFullUserActionFeedbackData(
        uafEvent.Content.UserActionFeedbackID,
        uafEvent.Content.NodeID
      );

      if (userActionFeedback.Tags.includes(iTag)) {
        userActionFeedbacks.push(userActionFeedback);
      }
    }

    return userActionFeedbacks;
  }

  /**
   * Retrieves all featured user action feedbacks from the graph.
   * @returns {Array} Array of all featured user action feedbacks.
   */
  GetAllFeaturedUserActionFeedbacksFromGraph() {
    const featuredUserActions = [];
    const branchingDecisionNodes = this.Graph.GetNodesByType('SmartBranchingDecision');

    for (const bdNode of branchingDecisionNodes) {
      const uasFeedback = Object.values(bdNode.AvailableUserActionsFeedbacks).filter((uaf) =>
        uaf.Tags.includes(USER_ACTION_TAGS.FEATURED_ACTION)
      );

      featuredUserActions.push(...uasFeedback);
    }

    return featuredUserActions;
  }
}
