import React from 'react';
import { Translation } from '@/components/Utilities/Translation';

const AlertBeforeLeaving = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <h1 className="mx-auto max-w-half-row text-center title">
        <Translation keyName="app.quit">Vous allez quittez Practicio.</Translation>
      </h1>
      <p className="mx-auto mt-4 max-w-half-row text-center">
        <Translation keyName="app.question">
          Si vous avez une question, cliquez sur le bouton “Aide” en bas à droite de votre écran
          pour contacter le support.
        </Translation>
      </p>
    </div>
  );
};

export default AlertBeforeLeaving;
