import log from 'loglevel';

export default class CacheManager {
  videosToPreload = [];
  totalVideosPreloaded = 0;
  totalProgressionInPercent = 0;

  getTotalProgressionInPercent() {
    return this.totalProgressionInPercent;
  }

  preload(videosToPreload, progressionCallback) {
    log.debug(`CacheManager: start preloading ${videosToPreload.length} videos...`);

    this.totalVideosPreloaded = 0;
    this.videosToPreload = videosToPreload;

    for (const video of this.videosToPreload) {
      this.fetchOne(video, progressionCallback);
    }
  }

  fetchOne(url, progressionCallback) {
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.blob(); // Wait for the entire content to be downloaded
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      })
      .then((_blob) => {
        log.debug(`CacheManager: video ${url} fetched by the browser`);
        this.incrementProgression(progressionCallback);
      })
      .catch((error) => {
        log.error(`CacheManager: Error fetching the video ${url}:`, error);
        this.incrementProgression(progressionCallback);
      });
  }

  incrementProgression(progressionCallback) {
    this.totalVideosPreloaded++;
    this.totalProgressionInPercent = this.computeTotalProgressionInPercent();
    log.debug(`CacheManager: total progression: ${this.totalProgressionInPercent}%`);

    progressionCallback(this.totalProgressionInPercent);
  }

  computeTotalProgressionInPercent() {
    return Math.round((this.totalVideosPreloaded / this.videosToPreload.length) * 100);
  }
}
