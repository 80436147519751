import { faMasksTheater } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import log from 'loglevel';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import BottomBar from '@/components/VisioExerciceBottomBar/VisioExerciceBottomBar';
import { ExerciseContext } from '../../ExerciseContext';

class BriefingUserWebcam extends React.Component {
  constructor(props) {
    super(props);
    this.listeners = [];
    this.videoRef = React.createRef();
  }

  async componentDidMount() {
    this.videoObj = this.videoRef.current;
    this.stream = window.sdk.videoconf().mediaDevices().getStream();
    this.updateVideo();

    this.listeners.push(
      window.sdk.event().on('mediaDeviceUpdated', (data) => {
        if (data.kind === 'videoinput') {
          this.removeOldTrack();
          this.stream = data.stream;
          this.updateVideo();
        }
      })
    );
  }

  updateVideo() {
    if (this.stream && this.stream.getVideoTracks().length > 0) {
      this.videoObj.srcObject = new MediaStream([this.stream.getVideoTracks()[0].clone()]);
    }
  }

  removeOldTrack() {
    if (!this.videoRef.current.srcObject) return;

    const tracks = this.videoRef.current.srcObject.getVideoTracks();
    for (let j in tracks) {
      tracks[j].stop();
    }
  }

  componentWillUnmount() {
    if (!this.videoRef.current.srcObject) return;

    const tracks = this.videoRef.current.srcObject.getVideoTracks();
    for (let j in tracks) {
      tracks[j].stop();
    }

    for (const i in this.listeners) {
      this.listeners[i]();
    }
  }

  render() {
    return (
      <div className="video_layer">
        <video playsInline autoPlay={true} ref={this.videoRef} muted></video>
      </div>
    );
  }
}

const BriefingVideoBot = ({ source }) => {
  return (
    <div className="video_layer">
      <video playsInline autoPlay={true} muted>
        <source src={source} type="video/mp4" />
      </video>
    </div>
  );
};

class BriefingVideoPresenter extends React.Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {}

  render() {
    // Set a different ID to force video player to reload the new video from URL
    // Extract video file name from source URL
    let id = this.props.source.split('/').pop();

    return (
      <div className={'video_layer_presenter'}>
        <video playsInline key={id} autoPlay={true} onEnded={this.props.onEnded}>
          <source src={this.props.source} type="video/mp4" />
        </video>
      </div>
    );
  }
}

const Briefing = () => {
  const { setStep, ExerciseGraph, jsonGraph } = useContext(ExerciseContext);
  const [currentBriefingIndex, setCurrentBriefingIndex] = useState(0);

  useEffect(() => {
    startBriefing();
  }, []);

  const startBriefing = async () => {
    log.debug('Exercise.startBriefing');
    window.sdk.event().emit('startExercise');
    //if (!jsonGraph.Briefing || jsonGraph.Briefing.length === 0 || this.props.StepLabel === 'run') {
    if (!jsonGraph.Briefing || jsonGraph.Briefing.length === 0) {
      log.debug('startScenario ici');
      setCurrentBriefingIndex(0);
      setStep('scenario');
    }
  };
  const nextBriefingSlide = () => {
    let index = currentBriefingIndex;
    log.debug('Next briefing slide: ', index, ' Briefing lenght ', jsonGraph.Briefing.length);
    index++;
    if (index >= jsonGraph.Briefing.length) {
      log.debug('Next briefing slide: Finished, starting the scenario...');
      log.debug('startScenario ici2');
      setCurrentBriefingIndex(0);
      setStep('scenario');
    } else {
      log.debug('Next briefing slide: Going next slide = ', index);
      setCurrentBriefingIndex(index);
    }
  };
  if (!jsonGraph.Briefing) return null;
  const currBrief = jsonGraph.Briefing[currentBriefingIndex];
  if (!currBrief) return null;
  return (
    <div className="new-step new-step--error">
      <div className="new-step__text">
        <div
          className={`exercise-briefing exercise__inner exercise-briefing--${currentBriefingIndex}`}>
          <div className="exercise__content">
            <div className="briefing__inner">
              {currBrief.video && (
                <Row style={{ justifyContent: 'center' }}>
                  <Col xs={'auto'}>
                    <div className="briefing__participant">
                      {currBrief.video.type === 'local' && <BriefingUserWebcam />}
                      {currBrief.video.type === 'bot' && (
                        <BriefingVideoBot source={currBrief.video.source} />
                      )}
                    </div>
                  </Col>
                </Row>
              )}

              <Row style={{ alignItems: 'center' }}>
                <Col xs={12} sm={6}>
                  <div className="briefing__speaker">
                    <div className="briefing__speaker__inner">
                      <div className="briefing__speaker__video">
                        {currBrief.presenterBot.type === 'bot' && (
                          <BriefingVideoPresenter
                            source={currBrief.presenterBot.source}
                            onEnded={nextBriefingSlide}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={6}>
                  <div className="briefing__desc">
                    <div className="briefing__icon">
                      <FontAwesomeIcon icon={faMasksTheater} />
                    </div>
                    <h3 className="briefing__title">{currBrief.title}</h3>
                    <p className="briefing__subtitle">{currBrief.subtitle}</p>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <BottomBar
          className="new-step__bottom"
          ExerciseGraph={ExerciseGraph}
          played={false}
          step={'welcome'}>
          <button id="briefing-next-button" className="cta cta--accent" onClick={nextBriefingSlide}>
            Continuer
          </button>
        </BottomBar>
      </div>
    </div>
  );
};
export default Briefing;
