import React, {
  Children,
  Dispatch,
  forwardRef,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { isReact } from '@/helpers/is-react';

type AccordionProps = {
  id: string;
  className?: string;
  openingCallback?: () => void;
};

type AccordionRef = {
  close: () => void;
};

const ANIMATION_DURATION = 300; // same value as duration-200 Tailwind CSS class + security delay

const AccordionComponent = forwardRef<AccordionRef, PropsWithChildren<AccordionProps>>(
  ({ id, className = '', ...props }, ref) => {
    const children = Children.toArray(props.children);

    const CTALabel = children.find(isCTALabel);

    if (!CTALabel) {
      throw new Error('Accordion must have a CTALabel component');
    }

    const CTALabelClassName: string = (CTALabel as ReactElement).props.className;

    const body = children.filter((child) => !isCTALabel(child));

    const [isOpened, setIsOpened]: [boolean, Dispatch<SetStateAction<boolean>>] =
      useState<boolean>(false);
    const contentRef = useRef<HTMLDivElement>(null);
    const [contentMaxHeight, setContentMaxHeight] = useState<string>();

    useEffect(() => {
      let timer: ReturnType<typeof setTimeout>;
      if (isOpened) {
        timer = setTimeout(() => setContentMaxHeight('unset'), ANIMATION_DURATION);
      } else {
        timer = setTimeout(() => setContentMaxHeight('0px'), 0);
      }
      return () => {
        clearTimeout(timer);
      };
    }, [isOpened]);

    const toggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      if (props.openingCallback && !isOpened) props.openingCallback();
      setContentMaxHeight(contentRef?.current?.scrollHeight + 'px');
      setIsOpened((current) => !current);
    };

    useImperativeHandle(ref, () => ({
      close() {
        setContentMaxHeight(contentRef?.current?.scrollHeight + 'px');
        const timer: ReturnType<typeof setTimeout> = setTimeout(
          () => setContentMaxHeight('0px'),
          0
        );
        setIsOpened(false);
        return () => clearTimeout(timer);
      }
    }));

    return (
      <article className={`${className} overflow-hidden`}>
        <button
          onClick={toggle}
          className={`${CTALabelClassName} relative w-full pr-[calc(18px+theme(spacing.4))] text-left after:absolute after:right-0 after:top-1/2 after:mt-[-5.5px] after:h-[11px] after:w-[18px] after:origin-center after:bg-current after:transition-transform after:duration-200 after:ease-in after:mask-chevron-down ${
            isOpened ? 'after:-scale-y-100' : 'scale-y-100'
          }`}
          aria-expanded={isOpened}
          aria-controls={`${id}-section`}
          id={id}>
          {CTALabel}
        </button>

        <div
          ref={contentRef}
          id={`${id}-section`}
          role="region"
          aria-labelledby={id}
          style={{
            ...(contentMaxHeight && contentMaxHeight !== '0px'
              ? { maxHeight: contentMaxHeight }
              : {})
          }}
          className={`box-border ${
            isOpened ? '' : 'not-important-max-h-0 overflow-hidden'
          } transition-[max-height] duration-200 ease-in-out [&>*:last-child]:pb-6`}>
          {body}
        </div>
      </article>
    );
  }
);

const CTALabel: React.FC<PropsWithChildren<{ className?: string }>> = ({ className, children }) => (
  <>{children}</>
);

const isCTALabel = (node: ReactNode) => isReact(node) && node.type === CTALabel;

const Accordion = AccordionComponent as typeof AccordionComponent & { CTALabel: typeof CTALabel };
Accordion.CTALabel = CTALabel;

export default Accordion;
