import React from 'react';
import '../../styles/components/Range.scss';
import Form from 'react-bootstrap/Form';

export default class CustomRange extends React.Component {
  constructor(props) {
    super(props);
    this.range = React.createRef();
    this.value = props.default || 1;
    this.min = props.min || 1;
    this.max = props.max || 7;
  }

  change = (e) => {
    this.value = e.target.value;
    this.props.onChange(e.target.value);
  };

  render() {
    return (
      <div
        className={`range my-4 flex items-center space-x-4 ${
          'range-container-' + this.props.identifier
        }`}>
        <p className="whitespace-nowrap text-sm label">{this.props.labelLeft}</p>
        <Form.Range
          className="range__input flex-1"
          value={this.value}
          min="1"
          max="7"
          id={'range-' + this.props.identifier}
          onChange={this.change}
          ref={this.range}
        />
        <p className="whitespace-nowrap text-sm label">{this.props.labelRight}</p>
      </div>
    );
  }
}
