import log from 'loglevel';
import ExerciseNode from './ExerciseNode';
import NodePort from '../NodePort';

export default class Deactivate extends ExerciseNode {
  // Ports
  Input = new NodePort('Input', 'input', this);

  // Parameters
  NodeID = -1;

  // Internal values

  constructor(iGraph, iProperties) {
    super(iGraph, iProperties);

    this.NodeID = iProperties.NodeId;

    //log.debug(this.GetIdentity() + " constructor: graph = " + this.Graph.ExerciseName + ", id = " + this.ID + ", NodeID = " + this.NodeId + ".");
  }

  OnActivated(iActivator, iInputPort, iIsRewindMode = false) {
    super.OnActivated(iActivator, iInputPort, iIsRewindMode);

    if (iIsRewindMode) {
      return;
    }

    log.debug(
      this.GetIdentity() +
        " has been activated by '" +
        iActivator.GetIdentity() +
        "' and will deactivate node this.NodeId."
    );

    // Deactivate the target node
    let targetNode = this.Graph.GetNode(this.NodeId);
    if (targetNode) {
      targetNode.OnDeactivated();
    } else {
      log.error(this.GetIdentity() + ' OnActivated: target node ' + this.NodeId + ' not found!');
    }

    this.SetActive(false);
  }

  PrintParameters() {
    //log.debug("BotName = " + this.BotName + ", DefaultLoop = " + this.DefaultLoop + ", PreferredPosition = " + this.PreferredPosition);
  }
}
